import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Connection, ConnectionService } from 'app/connections';
import { KvsService, KvsTicket } from 'app/sites/shared/kvs.service';
import { MessageService } from 'app/core/message.service';
import { NotificationService } from 'app/shared/itc/notification/notification.service';
import { SettingService } from '../../../../settings';
import { SiteService } from 'app/sites/shared/site.service';
import { PING_SITE } from 'app/sites/shared/constants';
import { Site } from 'app/sites/shared/site.model';
import { UiService } from 'app/core/ui.service';
import { DateTime } from '@grapecity/wijmo';
import { DatePipe } from '@angular/common';
import { Setting } from 'app/settings/shared/setting.model';
import * as saveAs from 'file-saver';
import { KvsCriticalColor, KvsHighColor, KvsLowColor, KvsMedColor, KvsNoneColor } from '../shared/kvs.constants';
import { MenuItem } from 'primeng/api';
import { accountDateTimePipe } from 'app/shared/accountDateTime.pipe';

const UI_FILTER_KEY: string = 'kvs_results_filter';
const VULSCAN_ISSUE_REPORT: string = 'Vulnerability Scan Report (By Issue)';
const VULSCAN_Detail_REPORT: string = 'Vulnerability Scan Report (By Device)';
const MATCHING_RULE_PNAME: string = 'vulscan-matchingrule';

@Component({
    selector: 'sds-kvs-scan-results',
    templateUrl: './kvs-scan-results.component.html',
    styleUrls: ['./kvs-scan-results.component.css'],
})
export class KvsScanResultsComponent implements OnInit {
    breadcrumbs = [
        { path: '..', text: 'VulScan' },
        { path: '..', text: 'Scan Results' },
    ];

    site: Site;
    byIssue: boolean;
    loadingComplete: boolean;
    loadingTicketInfoComplete: boolean;
    generatingReport: boolean = false;
    scanDateRange: number;
    cvssRange: number;
    scanType: string;
    quickFilter: string;
    scanResultSelection: any[];
    scanResults: any[];
    scanResultsFilteredByType: any[] = [];
    scanResultsFilteredByQuickFilter: any[] = [];
    filteredScanResults: any[];
    modalScanResult: any;
    selectedDevice: any;
    severityByPort: any[];
    vulnerabiltyStatistics: any[];
    reportType: string;
    isReadOnly: boolean = true; // If user is clientview it'll be readonly, so no "mark selected as false positive" button
    conn: Connection = new Connection();
    bulkActions: MenuItem[];
    allIssuesChecked: boolean = false;
    selectedIssues: any[] = [];
    knownExploitedVulnerabilities: boolean;
    dataViewType: string;
    sortBy: string[];

    psaTicketMap: Map<string, KvsTicket[]> = new Map<string, KvsTicket[]>();

    cvssRanges: any = {
        '-1': 'All',
        '1': 'Low (1.0+)',
        '4': 'Medium (4.0+)',
        '7': 'High (7.0+)',
        '9': 'Critical (9.0+)',
    };

    scanTypes: any = {
        ALL: 'All',
        IVS: 'Internal',
        EVS: 'External',
        AGT: 'Discovery Agent',
    };

    chartColors: any[] = [KvsNoneColor, KvsLowColor, KvsMedColor, KvsHighColor, KvsCriticalColor];

    @ViewChild('issuesModal') issuesModal: any;
    @ViewChild('addFalsePositiveModal') addFalsePositiveModal: any;
    @ViewChild('generateReportModal') generateReportModal: any;

    constructor(
        private datePipe: DatePipe,
        private settingService: SettingService,
        private siteService: SiteService,
        private connService: ConnectionService,
        private kvsService: KvsService,
        private messageService: MessageService,
        private notificationService: NotificationService,
        private uiService: UiService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private accountDateTime: accountDateTimePipe
    ) { }

    ngOnInit() {
        this.checkToClearSavedFilters();

        this.activatedRoute.data.subscribe((data) => {
            this.byIssue = data.viewType == 'issue';
            this.dataViewType = data.viewType;
        });

        this.setSavedFilterValues();

        this.activatedRoute.queryParams.subscribe((param) => {
            this.knownExploitedVulnerabilities = param['kev'] ?? this.knownExploitedVulnerabilities;
        });

        this.siteService.app_getCurrentSite().then((site) => {
            this.onSite(site);
        });
        this.messageService.broadcast(PING_SITE);

        let routedFilter = this.uiService.getControlValue(UI_FILTER_KEY);
        if (routedFilter) {
            this.quickFilter = routedFilter;
            this.uiService.setControlValue(UI_FILTER_KEY, '');
        }

        this.severityByPort = [];
        this.vulnerabiltyStatistics = [];
        this.filteredScanResults = [];
        this.scanResultSelection = [];
        this.createBulkActions();
    }

    checkToClearSavedFilters() {
        window.addEventListener('beforeunload', () => {
            this.clearSavedFilters();
        });

        this.activatedRoute.queryParams.subscribe((param) => {
            if (!(param['filter'] ?? false)) {
                this.clearSavedFilters();
            }
        });
    }

    clearSavedFilters() {
        localStorage.removeItem('issueScanDateRange');
        localStorage.removeItem('issueCvss');
        localStorage.removeItem('issueScanType');
        localStorage.removeItem('issueKnownExploitedVulnerabilities');
        localStorage.removeItem('issueQuickFilter');
        localStorage.removeItem('issueSort');

        localStorage.removeItem('deviceScanDateRange');
        localStorage.removeItem('deviceCvss');
        localStorage.removeItem('deviceScanType');
        localStorage.removeItem('deviceKnownExploitedVulnerabilities');
        localStorage.removeItem('deviceQuickFilter');
        localStorage.removeItem('deviceSort');
    }

    setSavedFilterValues() {
        this.scanDateRange =
            parseInt(localStorage.getItem(`${this.dataViewType}ScanDateRange`)) || 30;
        this.cvssRange = parseInt(localStorage.getItem(`${this.dataViewType}Cvss`)) || 1;
        this.scanType = localStorage.getItem(`${this.dataViewType}ScanType`)?.toString() || 'ALL';
        this.knownExploitedVulnerabilities =
            localStorage.getItem(`${this.dataViewType}KnownExploitedVulnerabilities`) == 'true';
        this.quickFilter = localStorage.getItem(`${this.dataViewType}QuickFilter`) || '';
        if (this.byIssue) {
            this.sortBy = localStorage.getItem(`${this.dataViewType}Sort`)?.split(',') || ['-Cvss'];
        } else {
            this.sortBy = localStorage.getItem(`${this.dataViewType}Sort`)?.split(',') || [
                'Ip',
                'Hostname',
                'MacAddress',
                '-Cvss',
            ];
        }
    }

    onSite(site: Site) {
        this.site = site;

        if (this.site) {
            this.uiService.setTitle('Scan Results', site.Name);
            this.getScanResults();
            console.log(site.UserRoles);
            this.isReadOnly = site.UserRoles == 'IND_CLIENT';
            this.connService.getConnectionBySite(this.site.Id).then((conn) => {
                this.conn = conn;
                this.getKvsTicketInfo(this.conn);
            });
        }
    }
    logObject(o: any) {
        for (var propName in o) {
            console.log(propName, o[propName]);
        }
    }

    showOidModal(oid: string) {
        for (let scanResult of this.filteredScanResults) {
            if (scanResult.Oid == oid) {
                this.modalScanResult = scanResult;
                break;
            }
        }

        this.issuesModal.showModal(this.modalScanResult, this.isReadOnly);
    }

    showOidModalByIp(oid: string, ip: string, hostname: string) {
        for (let scanResult of this.filteredScanResults) {
            if (scanResult.Oid == oid && scanResult.Ip == ip && scanResult.Hostname == hostname) {
                this.modalScanResult = scanResult;
                break;
            }
        }

        this.issuesModal.showModal(this.modalScanResult, this.isReadOnly);
    }

    showHostModal(ip: string, hostname: string, macAddress: string) {
        let device: string = '';

        if (hostname !== null && hostname !== undefined && hostname !== '') {
            if (macAddress !== null && macAddress !== undefined && macAddress !== '') {
                device = `${hostname} (${ip} / ${macAddress})`;
            } else {
                device = `${hostname} (${ip})`;
            }
        } else {
            if (macAddress !== null && macAddress !== undefined && macAddress !== '') {
                device = `${ip} (${macAddress})`;
            } else {
                device = ip;
            }
        }

        this.selectedDevice = device;
        let nowFilter = this.quickFilter;
        this.quickFilter = device;
        this.doQuickFilter();
        this.quickFilter = nowFilter;
    }

    deselectDevice() {
        this.selectedDevice = null;
        this.getScanResults();
    }

    onCvssChange() {
        this.getScanResults();
        this.saveChosenFilter('Cvss', this.cvssRange.toString());
    }

    onScanTypeChange() {
        this.scanResultsFilteredByType = this.scanTypeFilter(this.scanResults);
        this.doQuickFilter();
        this.saveChosenFilter('ScanType', this.scanType);
    }

    onDateRangeChange() {
        this.getScanResults();
        this.saveChosenFilter('ScanDateRange', this.scanDateRange.toString());
    }

    saveChosenFilter(filterName: string, filterValue: string) {
        localStorage.setItem(`${this.dataViewType}${filterName}`, filterValue);
    }

    updateVulnerabilityStatistics() {
        var portSeverity = {};
        this.severityByPort = [];

        var totalCritical = 0;
        var totalHigh = 0;
        var totalMedium = 0;
        var totalLow = 0;
        var totalNone = 0;

        if (this.filteredScanResults) {
            ///For Affected Ports
            if (this.selectedDevice) {
                for (let scanResult of this.filteredScanResults) {
                    if (scanResult && scanResult.Port) {
                        var ports = scanResult.Port.split(',');
                        for (var p in ports) {
                            if (!portSeverity[ports[p]]) {
                                portSeverity[ports[p]] = {};
                                portSeverity[ports[p]].critical = 0;
                                portSeverity[ports[p]].high = 0;
                                portSeverity[ports[p]].medium = 0;
                                portSeverity[ports[p]].low = 0;
                                portSeverity[ports[p]].none = 0;
                            }

                            if (scanResult.Cvss >= 9.0) {
                                portSeverity[ports[p]].critical++;
                            } else if (scanResult.Cvss >= 7.0) {
                                portSeverity[ports[p]].high++;
                            } else if (scanResult.Cvss >= 4.0) {
                                portSeverity[ports[p]].medium++;
                            } else if (scanResult.Cvss >= 0.1) {
                                portSeverity[ports[p]].low++;
                            } else {
                                portSeverity[ports[p]].none++;
                            }
                        }
                    }
                }
            }
            ///For By Issue and By Device
            else {
                for (let scanResult of this.filteredScanResults) {
                    if (scanResult.Cvss >= 9.0) {
                        totalCritical++;
                    } else if (scanResult.Cvss >= 7.0) {
                        totalHigh++;
                    } else if (scanResult.Cvss >= 4.0) {
                        totalMedium++;
                    } else if (scanResult.Cvss >= 0.1) {
                        totalLow++;
                    } else {
                        totalNone++;
                    }
                }
            }
        }

        if (this.selectedDevice) {
            totalCritical = 0;
            totalHigh = 0;
            totalMedium = 0;
            totalLow = 0;
            totalNone = 0;

            for (var port in portSeverity) {
                if (
                    portSeverity[port].critical > 0 ||
                    portSeverity[port].high > 0 ||
                    portSeverity[port].medium > 0 ||
                    portSeverity[port].low > 0 ||
                    portSeverity[port].none > 0
                ) {
                    totalCritical += portSeverity[port].critical;
                    totalHigh += portSeverity[port].high;
                    totalMedium += portSeverity[port].medium;
                    totalLow += portSeverity[port].low;
                    totalNone += portSeverity[port].none;

                    portSeverity[port].critical == 0
                        ? (portSeverity[port].critical = '')
                        : portSeverity[port].critical;
                    portSeverity[port].high == 0
                        ? (portSeverity[port].high = '')
                        : portSeverity[port].high;
                    portSeverity[port].medium == 0
                        ? (portSeverity[port].medium = '')
                        : portSeverity[port].medium;
                    portSeverity[port].low == 0
                        ? (portSeverity[port].low = '')
                        : portSeverity[port].low;
                    portSeverity[port].none == 0
                        ? (portSeverity[port].none = '')
                        : portSeverity[port].none;

                    this.severityByPort.push({
                        port: port,
                        critical: portSeverity[port].critical,
                        high: portSeverity[port].high,
                        med: portSeverity[port].medium,
                        low: portSeverity[port].low,
                        none: portSeverity[port].none,
                    });
                }
            }
        }

        this.vulnerabiltyStatistics = [];
        this.vulnerabiltyStatistics.push({
            Severity: 'Critical',
            Count: totalCritical,
        });
        this.vulnerabiltyStatistics.push({
            Severity: 'High',
            Count: totalHigh,
        });
        this.vulnerabiltyStatistics.push({
            Severity: 'Medium',
            Count: totalMedium,
        });
        this.vulnerabiltyStatistics.push({ Severity: 'Low', Count: totalLow });
        this.vulnerabiltyStatistics.push({
            Severity: 'None',
            Count: totalNone,
        });
    }

    getScanResults() {
        if (this.byIssue) {
            this.loadingComplete = false;
            this.kvsService
                .getKvsScanResultsByIssue(this.site.Id, this.scanDateRange, this.cvssRange)
                .then((res) => {
                    this.scanResults = [];
                    this.scanResults.push(...res);
                    //this.scanResults = this.scanTypeFilter(this.scanResults);
                    this.scanResultsFilteredByType = this.scanTypeFilter(this.scanResults);
                    this.sortColumn = 'Cvss';
                    this.sortDirection = 'desc';
                    this.doQuickFilter();
                    this.loadingComplete = true;
                });
        } else {
            this.loadingComplete = false;
            this.kvsService
                .getKvsScanResultsByDevice(this.site.Id, this.scanDateRange, this.cvssRange)
                .then((res) => {
                    this.scanResults = [];

                    for (let scanResult of res) {
                        if (this.selectedDevice) {
                            if (scanResult.Device == this.selectedDevice) {
                                this.scanResults.push(scanResult);
                            }
                        } else {
                            this.scanResults.push(scanResult);
                        }
                    }

                    //this.scanResults = this.scanTypeFilter(this.scanResults);
                    this.scanResultsFilteredByType = this.scanTypeFilter(this.scanResults);

                    this.sortColumn = 'Ip';
                    this.sortDirection = 'asc';

                    this.doQuickFilter();
                    this.loadingComplete = true;
                });
        }
    }

    toggleByIssue() {
        this.byIssue = !this.byIssue;
        this.getScanResults();
    }

    doQuickFilter(filterItem?: string) {
        if (filterItem) {
            this.quickFilter = filterItem;
        }
        if (this.quickFilter) {
            var filterResults: any[] = [];
            for (let scanResult of this.scanResultsFilteredByType) {
                var resultAdded: boolean = false;
                if (this.selectedDevice) {
                    if (
                        !resultAdded &&
                        this.quickFilter.toLowerCase().includes(scanResult.Ip.toLowerCase()) &&
                        this.quickFilter
                            .toLowerCase()
                            .includes(scanResult.Hostname.toLowerCase()) &&
                        this.quickFilter.toLowerCase().includes(scanResult.MacAddress.toLowerCase())
                    ) {
                        filterResults.push(scanResult);
                    }
                } else {
                    if (scanResult.Issue.toLowerCase().includes(this.quickFilter.toLowerCase())) {
                        filterResults.push(scanResult);
                        resultAdded = true;
                    }

                    if (!resultAdded) {
                        if (scanResult.Oid.toLowerCase().includes(this.quickFilter.toLowerCase())) {
                            filterResults.push(scanResult);
                            resultAdded = true;
                        }
                    }

                    if (!resultAdded) {
                        if (scanResult.Cvss.toString().includes(this.quickFilter.toLowerCase())) {
                            filterResults.push(scanResult);
                            resultAdded = true;
                        }
                    }

                    if (!resultAdded) {
                        if (
                            scanResult.LastDetectedFormatted.toLowerCase().includes(
                                this.quickFilter.toLowerCase()
                            )
                        ) {
                            filterResults.push(scanResult);
                            resultAdded = true;
                        }
                    }

                    if (!resultAdded) {
                        if (
                            scanResult.Cves.toLowerCase().includes(this.quickFilter.toLowerCase())
                        ) {
                            filterResults.push(scanResult);
                            resultAdded = true;
                        }
                    }

                    if (this.byIssue) {
                        if (!resultAdded) {
                            if (
                                scanResult.AffectedNodesLength.toString().includes(
                                    this.quickFilter.toLowerCase()
                                )
                            ) {
                                filterResults.push(scanResult);
                                resultAdded = true;
                            }
                        }
                    }

                    if (!this.byIssue) {
                        if (
                            !resultAdded &&
                            (scanResult.Ip.toLowerCase().includes(this.quickFilter.toLowerCase()) ||
                                scanResult.Hostname.toLowerCase().includes(
                                    this.quickFilter.toLowerCase()
                                ) ||
                                scanResult.MacAddress.toLowerCase().includes(
                                    this.quickFilter.toLowerCase()
                                ))
                        ) {
                            filterResults.push(scanResult);
                        }
                    }
                }
            }

            this.filteredScanResults = [];
            this.filteredScanResults.push(...filterResults);
            this.scanResultsFilteredByQuickFilter = filterResults;
            if (this.selectedDevice) {
                sort(this.filteredScanResults, '-Cvss');
            }
        } else {
            this.filteredScanResults = [];
            this.filteredScanResults.push(...this.scanResultsFilteredByType);

            this.selectedIssues = this.selectedIssues.filter((selected) =>
                this.filteredScanResults.find((filtered) => filtered.Oid == selected.Oid)
            );

            this.filteredScanResults.forEach((filtered) => {
                if (this.selectedIssues.find((selected) => selected.Oid == filtered.Oid)) {
                    filtered.Checked = true;
                }
            });

            sort(this.filteredScanResults, ...this.sortBy);
            this.scanResultsFilteredByQuickFilter = this.filteredScanResults;
        }

        this.scanResultSelection = [];
        for (var i = 0; i < this.filteredScanResults.length; i++) {
            this.scanResultSelection.push(false);
        }

        this.onKevFilterChecked();
    }

    onKevFilterChecked() {
        if (this.knownExploitedVulnerabilities) {
            this.filteredScanResults = this.scanResultsFilteredByQuickFilter.filter(
                (s) => s.KnownExploitedVulnerability.IsKnownExploitedVulnerability
            );
        } else {
            this.filteredScanResults = this.scanResultsFilteredByQuickFilter;
        }

        this.updateVulnerabilityStatistics();
        this.saveChosenFilter(
            'KnownExploitedVulnerabilities',
            this.knownExploitedVulnerabilities.toString()
        );
    }

    onQuickFilterChange() {
        this.saveChosenFilter('QuickFilter', this.quickFilter);
        this.doQuickFilter();
    }

    sortColumn: string;
    sortDirection: string;

    onSorted(ev?: any) {
        if (ev && ev.sortColumn != undefined) {
            let column = '';
            if (ev.sortDirection == 'desc') {
                column = `-${ev.sortColumn}`;
            } else {
                column = ev.sortColumn;
            }
            sort(this.filteredScanResults, column);
            this.saveChosenFilter('Sort', column);
        }
    }

    checkIssue(issue: any): void {
        if (this.allIssuesChecked) {
            this.allIssuesChecked = false;
        }
        issue.Checked = !issue.Checked;
        this.updateSelected();
    }

    checkAllIssues(isSelected: boolean): void {
        this.filteredScanResults.forEach((_) => {
            _.Checked = isSelected;
        });
        this.allIssuesChecked = isSelected;
        this.updateSelected();
    }

    updateSelected(): void {
        this.selectedIssues = this.filteredScanResults.filter((_) => _.Checked);
        this.createBulkActions();
    }

    createBulkActions() {
        this.bulkActions = [
            {
                label: this.selectedIssues.length + ' Selected',
                items: [
                    {
                        label: 'Mark as...',
                        disabled: !this.selectedIssues.length,
                        command: () => this.handleMarkAs(),
                    },
                ],
            },
        ];
    }

    handleMarkAs() {
        if (this.selectedIssues.length > 1) {
            this.addFalsePositiveModal.showModalWithMultipleIssues(this.selectedIssues);
        } else {
            this.addFalsePositiveModal.showModal(this.selectedIssues[0]);
        }
    }

    showAddFalsePositiveModal(scanResult: any) {
        this.addFalsePositiveModal.showModal(scanResult);
    }

    axisFormatter(engine, label) {
        // format axisX labels for detail chart
        // to stop showing ticks for float numbers in between
        if (!Number.isInteger(label.val)) {
            engine.textFill = 'red'; // red text
            label.cls = 'hideLabel'; // no default CSS
        }
        return label;
    }

    refreshResults() {
        this.getScanResults();
        this.checkAllIssues(false);
    }

    scanTypeFilter(scanResults: any[]) {
        if (!this.scanType || !scanResults) return [];

        if (this.scanType == 'ALL') return [...scanResults];

        let rValue = [];
        for (let r of scanResults) {
            let modifiedResults = Object.assign({}, r);
            switch (this.scanType) {
                case 'IVS':
                    modifiedResults.AffectedNodes = modifiedResults.AffectedNodesIvsFormatted;
                    modifiedResults.AffectedNodesAgtFormatted = [];
                    modifiedResults.AffectedNodesEvsFormatted = [];
                    modifiedResults.LastDetected = modifiedResults.IvsLastDetected ?? modifiedResults.LastDetected;

                    modifiedResults.Port = modifiedResults.IvsPorts?.join(',');
                    break;
                case 'EVS':
                    modifiedResults.AffectedNodes = modifiedResults.AffectedNodesEvsFormatted;
                    modifiedResults.AffectedNodesAgtFormatted = [];
                    modifiedResults.AffectedNodesIvsFormatted = [];
                    modifiedResults.LastDetected = modifiedResults.EvsLastDetected ?? modifiedResults.LastDetected;

                    modifiedResults.Port = modifiedResults.EvsPorts?.join(',');
                    break;
                case 'AGT':
                    modifiedResults.AffectedNodes = modifiedResults.AffectedNodesAgtFormatted;
                    modifiedResults.AffectedNodesIvsFormatted = [];
                    modifiedResults.AffectedNodesEvsFormatted = [];
                    modifiedResults.LastDetected = modifiedResults.AgtLastDetected ?? modifiedResults.LastDetected;

                    modifiedResults.Port = modifiedResults.AgtPorts?.join(',');
                    break;
            }

            modifiedResults.AffectedNodesLength = modifiedResults.AffectedNodes.length;
            if (modifiedResults.AffectedNodesLength > 0) rValue.push(modifiedResults);
        }

        return rValue;
    }

    /**
     * Get Ticket Data from database.
     * @param connection
     */
    getKvsTicketInfo(connection: Connection) {
        if (connection) {
            let intg = connection.Type;
            this.loadingTicketInfoComplete = false;

            if (!intg || intg.trim().length == 0) return;
            this.kvsService.getVulscanTicketIssuesByIntg(this.site.Id, intg).then((tickets) => {
                if (!tickets.length) {
                    this.loadingTicketInfoComplete = true;
                    return;
                }

                let _lTickets: KvsTicket[] = tickets;

                _lTickets.forEach((_ticket) => {
                    let key: string = '';
                    let value: KvsTicket = _ticket;
                    if (!value) return;

                    key = value.Issues[0].Oid;
                    if (!this.psaTicketMap.has(key)) {
                        this.psaTicketMap.set(key, [value]);
                    } else {
                        this.psaTicketMap.get(key).push(value);
                    }
                });

                this.loadingTicketInfoComplete = true;
            });
        }
    }

    showReportConfirmation(type: string) {
        this.reportType = type;
        this.generateReportModal.show();
    }

    generateVulScanReport() {
        this.generatingReport = true;
        this.generateReportModal.hide();

        switch (this.reportType.toLowerCase()) {
            case 'word':
                this.generateWordReport();
                break;
            case 'excel':
                this.generateExcelReport();
                break;
        }
    }

    generateWordReport() {
        let resultsToExport = this.filteredScanResults;
        let resultsFilter = this.getResultsFilter(true);

        let resultQuery: any = {
            MaxDaysOld: this.scanDateRange,
            SortColumn: this.sortColumn,
            SortDirection: this.sortDirection,
            SelectedDevice: this.selectedDevice,
            MinCvss: this.cvssRange,
            ScanType: this.scanTypes[this.scanType],
            QuickFilter: this.quickFilter,
            KnownExploitedVulnerabilities: this.knownExploitedVulnerabilities,
        };
        let statsToExport: any[] = this.vulnerabiltyStatistics;

        let reportName = this.byIssue ? VULSCAN_ISSUE_REPORT : VULSCAN_Detail_REPORT;

        let reportNameFormatted = reportName.split('_').join(' ');
        this.notificationService.toast.info(reportNameFormatted, 'Generating report.');

        this.kvsService
            .generateVulScanReport(
                this.site.Id,
                resultsToExport,
                resultsFilter,
                statsToExport,
                this.byIssue,
                resultQuery
            )
            .then((res) => {
                var bin = atob(res);
                var ab = this.s2ab(bin);

                //save as doc file
                saveAs(
                    new Blob([ab], {
                        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;',
                    }),
                    `${reportName}.docx`,
                    { autoBom: false }
                );
                this.notificationService.toast.success(
                    reportNameFormatted,
                    'Report ready for download.'
                );
                this.generatingReport = false;
                this.setCooperFlag();
            })
            .catch((err) => {
                this.generatingReport = false;
                this.notificationService.toast.error('Error', 'Report was not generated.');
            });
    }
    generateExcelReport() {
        this.generatingReport = true;
        let resultsToExport = this.formatLastDetectedDate(this.filteredScanResults);
        const resultsFilter = this.getResultsFilter(false);
        this.kvsService
            .generateVulScanExcelReport(
                this.site.Id,
                resultsToExport,
                resultsFilter,
                this.byIssue
            )
            .then((res) => {
                var bin = atob(res);
                var ab = this.s2ab(bin);

                let filename = `Vulnerability Scan Excel Report (By ${this.byIssue ? 'Issue' : 'Device'
                    })`;
                saveAs(
                    new Blob([ab], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
                    }),
                    `${filename}.xlsx`,
                    { autoBom: false }
                );

                this.notificationService.toast.success(filename, 'Report ready for download.');
                this.generatingReport = false;
                this.setCooperFlag();
            })
            .catch((err) => {
                this.generatingReport = false;
                this.notificationService.toast.error('Error', 'Report was not generated.');
            });
    }

    generateCsvReport() {
        this.generatingReport = true;
        let resultsToExport = this.formatLastDetectedDate(this.filteredScanResults);

        this.kvsService
            .generateVulScanCsvReport(this.site.Id, resultsToExport, this.byIssue)
            .then((res) => {
                var bin = atob(res);
                var ab = this.s2ab(bin);

                let filename = `Vulnerability Scan Report (By ${
                    this.byIssue ? 'Issue' : 'Device'
                    })`;
                saveAs(new Blob([ab], { type: 'text/csv;charset=utf-8;' }), `${filename}.csv`, {
                    autoBom: false,
                });

                this.notificationService.toast.success(filename, 'Report ready for download.');
                this.generatingReport = false;
                this.setCooperFlag();
            })
            .catch((err) => {
                this.generatingReport = false;
                this.notificationService.toast.error('Error', 'Report was not generated.');
            });
    }

    getResultsFilter(datesWithFormat: boolean) {
        let endDate: Date = new Date();
        let startDate: Date =
            this.scanDateRange < 0
                ? new Date(
                    this.filteredScanResults.reduce((prev, curr) =>
                        Date.parse(prev.LastDetected) < Date.parse(curr.LastDetected)
                            ? prev
                            : curr
                    ).LastDetected
                )
                : new Date(DateTime.addDays(endDate, -this.scanDateRange));

        let sEndDate: string = this.accountDateTime.transform(endDate, [!datesWithFormat ? 'ignoreFormat' : '']);
        let sStartDate: string = this.accountDateTime.transform(startDate, [!datesWithFormat ? 'ignoreFormat' : '']);

        let resultsFilter: any = {
            MaxDaysOld: this.scanDateRange,
            StartDate: sStartDate,
            EndDate: sEndDate,
            CVSS: this.cvssRanges[this.cvssRange],
            ScanType: this.scanTypes[this.scanType],
            KnownExploitedVulnerabilities: this.knownExploitedVulnerabilities,
        };

        return resultsFilter;
    }

    setCooperFlag() {
        let sets: Setting[] = [];
        sets.push({
            AccountId: this.site.AccountId,
            Name: 'COOPER_VSR',
            Value: 'TRUE',
        });

        this.settingService
            .updateSettings(sets)
            .then(() => { })
            .catch(() => { });
    }

    s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    redirectToReportPref() {
        this.router.navigate([
            '/site',
            this.makeURI(this.site.Name),
            'vulnerability-scanner',
            'settings',
            'report-preferences',
            'text',
        ]);
        this.generateReportModal.hide();
    }

    makeURI(component) {
        if (component) {
            return component.replace(/&/g, '%26');
        }
    }

    ///Right Click Context Menu
    contextFilterItem: string = '';
    @ViewChild('context_quickFilter') context_quickFilter: ElementRef;
    contextMenu(e: MouseEvent, item: string) {
        e.preventDefault();
        this.context_quickFilter.nativeElement.style.top = `${e.clientY}px`;
        this.context_quickFilter.nativeElement.style.left = `${e.clientX}px`;
        this.context_quickFilter.nativeElement.style.display = 'block';
        this.contextFilterItem = item;
    }

    @HostListener('document:click')
    hideContextMenu(e: any): void {
        this.context_quickFilter.nativeElement.style.display = 'none';
    }

    formatLastDetectedDate(scanResults: any) {
        let formattedScanResults = JSON.parse(JSON.stringify(scanResults));
        formattedScanResults.forEach((obj: any) => {
            if (obj.hasOwnProperty('LastDetected')) {
                obj['LastDetected'] = this.accountDateTime.transform(obj['LastDetected'], ['ignoreFormat']);
            }
            if (obj.hasOwnProperty('IvsLastDetected') && obj['IvsLastDetected']) {
                obj['IvsLastDetected'] = this.accountDateTime.transform(obj['IvsLastDetected'], ['ignoreFormat']);
            }
            if (obj.hasOwnProperty('EvsLastDetected') && obj['EvsLastDetected']) {
                obj['EvsLastDetected'] = this.accountDateTime.transform(obj['EvsLastDetected'], ['ignoreFormat']);
            }
            if (obj.hasOwnProperty('AgtLastDetected') && obj['AgtLastDetected']) {
                obj['AgtLastDetected'] = this.accountDateTime.transform(obj['AgtLastDetected'], ['ignoreFormat']);
            }
        });
        return formattedScanResults;
    }
}

type sortArg<T> = keyof T | `-${string & keyof T}`;

/**
 * Returns a comparator for objects of type T that can be used by sort
 * functions, were T objects are compared by the specified T properties.
 *
 * @param sortBy - the names of the properties to sort by, in precedence order.
 *                 Prefix any name with `-` to sort it in descending order.
 */
function byPropertiesOf<T extends object>(sortBy: Array<sortArg<T>>) {
    function compareByProperty(arg: sortArg<T>) {
        let key: keyof T;
        let sortOrder = 1;
        if (typeof arg === 'string' && arg.startsWith('-')) {
            sortOrder = -1;
            // Typescript is not yet smart enough to infer that substring is keyof T
            key = arg.substr(1) as keyof T;
        } else {
            // Likewise it is not yet smart enough to infer that arg here is keyof T
            key = arg as keyof T;
        }
        return function (a: T, b: T) {
            let aKey = a[key];
            let bKey = b[key];

            if (key == 'Cvss') {
                aKey = Number(aKey) as unknown as T[keyof T];
                bKey = Number(bKey) as unknown as T[keyof T];
            } else {
                aKey = String(aKey).toLowerCase() as unknown as T[keyof T];
                bKey = String(bKey).toLowerCase() as unknown as T[keyof T];

                if (key == 'Ip') {
                    aKey = formatIp(aKey);
                    bKey = formatIp(bKey);
                } else if (key == 'Oid') {
                    aKey = formatOid(aKey);
                    bKey = formatOid(bKey);
                } else if (key == 'AffectedNodesLength') {
                    aKey = Number(aKey) as unknown as T[keyof T];
                    bKey = Number(bKey) as unknown as T[keyof T];
                }
            }

            const result = aKey < bKey ? -1 : aKey > bKey ? 1 : 0;

            return result * sortOrder;
        };
    }

    return function (obj1: T, obj2: T) {
        let i = 0;
        let result = 0;
        const numberOfProperties = sortBy?.length;
        while (result === 0 && i < numberOfProperties) {
            result = compareByProperty(sortBy[i])(obj1, obj2);
            i++;
        }

        return result;
    };
}

/**
 * Sorts an array of T by the specified properties of T.
 *
 * @param arr - the array to be sorted, all of the same type T
 * @param sortBy - the names of the properties to sort by, in precedence order.
 *                 Prefix any name with `-` to sort it in descending order.
 */
function sort<T extends object>(arr: T[], ...sortBy: Array<sortArg<T>>) {
    arr.sort(byPropertiesOf<T>(sortBy));
}

function formatIp(ip) {
    let vArray = ip.split('.');
    if (vArray.length != 4) return ip;
    for (var i = 0; i < 4; i++) {
        vArray[i] = vArray[i].padStart(3, '0');
    }
    return vArray.join('.');
}

function formatOid(oid) {
    let vArray = oid.split('.');
    if (vArray.length == 1) return oid;
    for (var i = 0; i < vArray.length; i++) {
        vArray[i] = vArray[i].padStart(10, '0');
    }
    return vArray.join('.');
}
