import { Site } from 'app/sites';

export class Connection {
    Id: number;
    Type: string;
    AccountId: string;
    Alias: string;
    Username: string;
    Password: string;
    URL: string;
    CompanyId: string;
    TicketMetadata: any;

    Sites?: Site[];
}
