import { Component, Input, OnInit } from '@angular/core';
import { SiteDashboard } from '../../shared/site-dashboard.model';
import { Router } from '@angular/router';
import { AuthService, Roles, SiteRoles } from 'app/core/auth/auth.service';
import { SiteService } from '../../shared/site.service';
import { FormControl } from '@angular/forms';
import { accountDateTimePipe } from '../../../shared/accountDateTime.pipe';
import { SiteDashboardService } from '../../shared/site-dashboard.service';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'sds-compliance-manager-grc',
    templateUrl: './compliance-manager-grc.component.html',
    styleUrls: ['./compliance-manager-grc.component.scss'],
})
export class ComplianceManagerGrcComponent implements OnInit {
    displaySites: SiteDashboard[] = [];
    filteredSites: SiteDashboard[] = [];
    allSites: SiteDashboard[] = [];
    sortedSites: SiteDashboard[] = [];
    selectedType: string = '';
    sortColumn: string = '';
    sortDirection: string = '';
    searchKeyControl = new FormControl<string>('');
    selectedTechnicalIssue: string = '';
    selectedScanDate: string = '';
    checkStatus: boolean = false;
    scanStatusOptions: string[] = ['Any Time', 'Last 24 hours', 'Last 48 hours', 'Last 30 days'];
    technicalIssuesOption: SelectItem[] = [
        { label: 'Detected', value: 'Detected' },
        { label: 'Not detected', value: 'Not detected' },
        { label: 'Clear Selection', value: '', disabled: this.selectedTechnicalIssue === '' },
    ];
    isSiteRestricted: boolean = false;
    isCheckboxRestricted: boolean = false;
    loadingComplete: boolean = false;

    SCAN_STATUS = 'SCAN_STATUS';
    ONLY_MY_SITES = 'ONLY_MY_SITES';
    TECHNICAL_ISSUE = 'TECHNICAL_ISSUE';

    @Input() set sites(value: any) {
        this.allSites = value;
        this.filteredSites = value;
        this.displaySites = value;
        this.onSortSites();
    }

    @Input() sitesUserIds: number[];

    ngOnInit(): void {
        this.getFilters();
        this.checkIfUserIsSiteRestricted().then(() => {
            this.loadingComplete = true;
        });
    }

    constructor(
        private router: Router,
        private siteService: SiteService,
        private authService: AuthService,
        private dateWithFormat: accountDateTimePipe,
        public siteDashboardService: SiteDashboardService
    ) {}

    async checkIfUserIsSiteRestricted() {
        if (
            this.authService.userIsRole(Roles.SiteRestricted) ||
            this.authService.userIsRole(Roles.Restricted)
        ) {
            this.isCheckboxRestricted = true;
            this.checkStatus = true;
            await this.checkUserSiteRoles();
        }
    }

    async checkUserSiteRoles() {
        for (var siteItem of this.allSites) {
            await this.siteService.getSite(siteItem.Id).then((site) => {
                if (
                    site.UserRoles.indexOf(SiteRoles.EmployeePortalAdmin) > -1 ||
                    site.UserRoles.indexOf(SiteRoles.GrcReportViewer) > -1 ||
                    site.UserRoles.indexOf(SiteRoles.SME) > -1
                ) {
                    this.isSiteRestricted = true;
                }
            });

            if (this.isSiteRestricted) {
                break;
            }
        }
    }

    updateTechnicalOptions() {
        this.technicalIssuesOption[this.technicalIssuesOption.length - 1].disabled =
            this.selectedTechnicalIssue === '';
        this.technicalIssuesOption = [...this.technicalIssuesOption];
    }

    getFilters() {
        this.siteDashboardService
            .getGrcFilterState()
            .then((res) => {
                if (res.length) {
                    this.selectedScanDate = res.find((p) => p.name === this.SCAN_STATUS)?.value;
                    this.checkStatus =
                        res.find((p) => p.name === this.ONLY_MY_SITES)?.value === 'true';
                    this.selectedTechnicalIssue = res.find(
                        (p) => p.name === this.TECHNICAL_ISSUE
                    )?.value;

                    this.updateTechnicalOptions();
                    this.filterSites();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    searchSites() {
        let searchKey = this.searchKeyControl.value?.trim().toLowerCase();
        if (searchKey) {
            const key = searchKey.toLowerCase();
            this.filteredSites = this.filteredSites.filter(
                (site) =>
                    site.Name.toLowerCase().includes(key) ||
                    site.Organization.toLowerCase().includes(key)
            );
        }
    }

    filterByType() {
        if (this.selectedType === 'Compliance Manager Pro') {
            this.filteredSites = this.filteredSites.filter((site) => site.IsCSPro);
        }
    }

    onSortSites(ev: any = { sortColumn: 'Name', sortDirection: 'asc' }) {
        this.sortColumn = ev.sortColumn;
        this.sortDirection = ev.sortDirection;
        this.sortSites();
    }

    resetSearchKey() {
        this.searchKeyControl.setValue('');
    }

    sortSites() {
        this.sortedSites = this.filteredSites.sort((a, b) => {
            let cola =
                    this.sortColumn === 'ApplianceOnline'
                        ? this.getApplianceText(a)
                        : a[this.sortColumn],
                colb =
                    this.sortColumn === 'ApplianceOnline'
                        ? this.getApplianceText(b)
                        : b[this.sortColumn];

            if (typeof cola === 'string') {
                cola = cola.toLocaleLowerCase();
            }
            if (typeof colb === 'string') {
                colb = colb.toLocaleLowerCase();
            }

            if (this.sortDirection === 'desc') {
                return cola < colb ? 1 : cola === colb ? 0 : -1;
            } else {
                return cola > colb ? 1 : cola === colb ? 0 : -1;
            }
        });

        this.filteredSites = [...this.sortedSites];
        this.displaySites = [...this.sortedSites];
    }

    filterSitesByUser() {
        if (this.sitesUserIds.length) {
            this.filteredSites = this.filteredSites.filter((site) =>
                this.sitesUserIds.find((Id) => Id === site.Id)
            );
        }
    }

    getStatusScanClass(site: SiteDashboard, scanNumber: number) {
        let status = 'failed';

        if (site.ScanInProgress && scanNumber === 1) {
            status = 'loading';
        }

        if (
            (scanNumber === 1 && !site.ScanInProgress && site.Scan1Success) ||
            (scanNumber === 2 && site.Scan2Success) ||
            (scanNumber === 3 && site.Scan3Success) ||
            (scanNumber === 4 && site.Scan4Success) ||
            (scanNumber === 5 && site.Scan5Success)
        ) {
            status = 'success';
        }

        return status;
    }

    getStatusScan(site: SiteDashboard, scanNumber: number) {
        let status = this.getStatusScanClass(site, scanNumber);
        let currentStatus = '';

        if (status === 'loading') {
            currentStatus = 'Running';
        }
        if (status === 'success') {
            currentStatus = 'Completed';
        }
        if (status === 'failed') {
            currentStatus = 'Failed';
        }

        return currentStatus;
    }

    getRapidBaseLineStatusIcon(site: SiteDashboard) {
        let className = 'emptyControls';

        if (
            site.RapidBaselineCompleteCount > 0 &&
            site.RapidBaselineCompleteCount < site.RapidBaselineTotalCount
        ) {
            className = 'incompleteControls';
        }

        if (
            site.RapidBaselineCompleteCount > 0 &&
            site.RapidBaselineCompleteCount === site.RapidBaselineTotalCount
        ) {
            className = 'completeControls';
        }

        return className;
    }

    getTechnicalReviewStatusIcon(site: SiteDashboard) {
        let className = 'emptyControls';

        if (
            site.TechnicalReviewWorksheetCompleteCount > 0 &&
            site.TechnicalReviewWorksheetCompleteCount < site.TechnicalReviewWorksheetTotalCount
        ) {
            className = 'incompleteControls';
        }

        if (
            site.TechnicalReviewWorksheetTotalCount > 0 &&
            site.TechnicalReviewWorksheetCompleteCount === site.TechnicalReviewWorksheetTotalCount
        ) {
            className = 'completeControls';
        }

        return className;
    }

    getControlsStatusIcon(site: SiteDashboard) {
        let className = 'emptyControls';

        if (
            site.ControlAssessmentCompleteCount > 0 &&
            site.ControlAssessmentCompleteCount < site.ControlAssessmentTotalCount
        ) {
            className = 'incompleteControls';
        }

        if (
            site.ControlAssessmentTotalCount > 0 &&
            site.ControlAssessmentCompleteCount === site.ControlAssessmentTotalCount
        ) {
            className = 'completeControls';
        }

        return className;
    }

    getRequirementsIcon(site: SiteDashboard) {
        let className = 'emptyControls';

        if (
            site.RequirementAssessmentCompleteCount > 0 &&
            site.RequirementAssessmentCompleteCount < site.RequirementAssessmentTotalCount
        ) {
            className = 'incompleteControls';
        }

        if (
            site.RequirementAssessmentTotalCount > 0 &&
            site.RequirementAssessmentCompleteCount === site.RequirementAssessmentTotalCount
        ) {
            className = 'completeControls';
        }

        return className;
    }

    getSelectedStadard(site: SiteDashboard) {
        return site.Standards ? JSON.parse(site.Standards) : [];
    }

    getStandardToolTip(site: SiteDashboard) {
        let list = this.getSelectedStadard(site);
        return list.length ? list.slice(1).join(',\n') : '';
    }

    getTitle(site: SiteDashboard, taskNumber: number) {
        if (site.IsLicenseInactive) return 'Subscription Expired';
        let status = this.getStatusScan(site, taskNumber);
        let statusComplete = status ? status.charAt(0).toUpperCase() + status.slice(1) : '';
        return `Scan Status: ${statusComplete}`;
    }

    getScanType(site: SiteDashboard, taskNumber: number) {
        let scanType = '';
        if (taskNumber === 1) {
            scanType = site.Scan1Type;
        }
        if (taskNumber === 2) {
            scanType = site.Scan2Type;
        }
        if (taskNumber === 3) {
            scanType = site.Scan3Type;
        }
        if (taskNumber === 4) {
            scanType = site.Scan4Type;
        }
        if (taskNumber === 5) {
            scanType = site.Scan5Type;
        }

        return scanType;
    }

    getScanDate(site: SiteDashboard, taskNumber: number) {
        let scanType = '';
        if (taskNumber === 1) {
            scanType = site.Scan1StartDate?.toString();
        }
        if (taskNumber === 2) {
            scanType = site.Scan2StartDate?.toString();
        }
        if (taskNumber === 3) {
            scanType = site.Scan3StartDate?.toString();
        }
        if (taskNumber === 4) {
            scanType = site.Scan4StartDate?.toString();
        }
        if (taskNumber === 5) {
            scanType = site.Scan5StartDate?.toString();
        }
        return scanType;
    }

    getToolTip(site: SiteDashboard, taskNumber: number) {
        if (site.IsLicenseInactive) return site.Tooltip;

        let scanDate = this.getScanDate(site, taskNumber);
        let scanType = this.getScanType(site, taskNumber);
        let dateSection = '';

        if (this.getStatusScan(site, taskNumber) !== 'Running') {
            dateSection = `<span style=' width: 58px; float: right'>Scan Date: </span>
                 <span style='text-align: end' >${this.dateWithFormat.transform(scanDate)}</span>`;
        }

        return `<div style='display: flex; flex-direction: column; width: 212px'>
              <div style='display: flex; justify-content: space-between; align-items: flex-start; align-self: stretch; '>
                  <span style=' width: 58px'>Scan Type:</span>
                 <span  >${scanType}</span>
               </div>
                <div style='display: flex; justify-content: space-between; align-items: flex-start; align-self: stretch;'>
                  ${dateSection}
                </div>
             </div>`;
    }

    getScanUrl(site: SiteDashboard, taskNumber: number) {
        if (site.IsLicenseInactive) return '';

        let scanType = this.getScanType(site, taskNumber);
        let scanUrl = '';
        if (scanType.toLowerCase().includes('lan') || scanType.toLowerCase().includes('pre-scan')) {
            scanUrl = 'lan';
        }
        if (scanType.toLowerCase().includes('cloud')) {
            scanUrl = 'cloud';
        }
        if (scanType.toLowerCase().includes('computer')) {
            scanUrl = 'computer';
        }
        if (scanType.toLowerCase().includes('external')) {
            scanUrl = 'external';
        }
        if (scanType.toLowerCase().includes('internal')) {
            scanUrl = 'internal';
        }
        if (scanType.toLowerCase().includes('discovery')) {
            scanUrl = 'discovery-agents';
        }

        return `site/${site.Name}/compliance-manager-grc/data-collection/${scanUrl}`;
    }

    filterByTechnicalIssues() {
        if (this.selectedTechnicalIssue === 'Detected') {
            this.filteredSites = this.filteredSites.filter(
                (site) => site.TechnicalReviewWorksheetTotalCount > 0 || site.HasTechnicalIssues
            );
        }

        if (this.selectedTechnicalIssue === 'Not detected') {
            this.filteredSites = this.filteredSites.filter(
                (site) => site.TechnicalReviewWorksheetTotalCount === 0 && !site.HasTechnicalIssues
            );
        }
    }

    saveAndFilterSites(selectedValue: string | boolean, name: string) {
        this.updateTechnicalOptions();
        this.saveGrcFilterState(selectedValue?.toString(), name);
        this.filterSites();
    }

    filterSites() {
        this.filteredSites = this.allSites;

        if (this.searchKeyControl.value) {
            this.searchSites();
        }

        if (this.selectedTechnicalIssue) {
            this.filterByTechnicalIssues();
        }

        if (this.selectedType) {
            this.filterByType();
        }

        if (this.selectedScanDate) {
            this.filterByScanDate();
        }

        if (this.checkStatus) {
            this.filterSitesByUser();
        }

        this.sortSites();
    }

    saveGrcFilterState(value: string, name: string) {
        this.siteDashboardService
            .saveGrcFilterState(value, name)
            .then((res) => {})
            .catch((err) => {
                console.log(err);
            });
    }

    filterByScanDate() {
        if (this.selectedScanDate === 'Last 24 hours') {
            this.filteredSites = this.filteredSites.filter((site) =>
                this.getSiteScanLastByDay(site, 1)
            );
        }

        if (this.selectedScanDate === 'Last 48 hours') {
            this.filteredSites = this.filteredSites.filter((site) =>
                this.getSiteScanLastByDay(site, 2)
            );
        }

        if (this.selectedScanDate === 'Last 30 days') {
            this.filteredSites = this.filteredSites.filter((site) =>
                this.getSiteScanLastByDay(site, 30)
            );
        }
    }

    getSiteScanLastByDay(site: SiteDashboard, days: number): boolean {
        const timeNowScan1 = Date.now() - new Date(site.Scan1StartDate)?.getTime();
        const timeNowScan2 = Date.now() - new Date(site.Scan2StartDate)?.getTime();
        const timeNowScan3 = Date.now() - new Date(site.Scan3StartDate).getTime();
        const timeNowScan4 = Date.now() - new Date(site.Scan4StartDate).getTime();
        const timeNowScan5 = Date.now() - new Date(site.Scan5StartDate).getTime();

        const hourInMiliseconds = this.getMilisenconds(days);

        return (
            timeNowScan1 <= hourInMiliseconds ||
            timeNowScan2 <= hourInMiliseconds ||
            timeNowScan3 <= hourInMiliseconds ||
            timeNowScan4 <= hourInMiliseconds ||
            timeNowScan5 <= hourInMiliseconds
        );
    }

    getMilisenconds(days: number) {
        return 24 * 60 * 60 * 1000 * days;
    }

    getDynamicStyles(site: SiteDashboard) {
        let color = '#001d30';
        let backgroundColor = '#FF9E9E'; // offline
        let width = '60px';
        if (site.ApplianceOnline) {
            backgroundColor = '#9ee7a1'; // online
        } else if (!site.ApplianceOnline && !site.ApplianceInstalled && site.Type === 'CM2') {
            backgroundColor = '#515355'; // not installed
            color = '#FFFFFF';
            width = '90px';
        }

        return {
            'background-color': backgroundColor,
            'color': color,
            'width': width,
            'display': 'flex',
            'padding': '2px 10px',
            'font-weight': '500px',
            'font-size': '12px',
            'justify-content': 'center',
            'align-items': 'center',
            'gap': '8px',
        };
    }

    getApplianceText(site: SiteDashboard) {
        if (site.ApplianceOnline) {
            return 'Online';
        } else if (!site.ApplianceOnline && !site.ApplianceInstalled && site.Type === 'CM2') {
            return 'Not Installed';
        } else {
            return 'Offline';
        }
    }
}
