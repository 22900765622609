import {
    AfterViewInit,
    Component,
    ComponentRef,
    ElementRef,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { AdditionalUser } from './additionaluser.model';
import { ScanHostRow } from './scanhostrow.model';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import {
    CellRangeEventArgs,
    DataMap,
    FormatItemEventArgs,
    HitTestInfo,
    SelectionMode,
} from '@grapecity/wijmo.grid';
import { WjTreeView } from '@grapecity/wijmo.angular2.nav';
import { WjPopup } from '@grapecity/wijmo.angular2.input';
import { SiteSettingService } from '../site-settings.service';

import { CheckScanHostReq } from './checkscanhostreq.model';
import { CheckUnitrendsReq } from './checkunitrendsreq.model';
import { ApplianceInfoRequest } from './applianceinforequest.model';
import { ScanSettingsRequest } from './scansettingsrequest.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'app/core/message.service';
import { Site } from 'app/sites/shared/site.model';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { NavService } from 'app/core/nav/nav.service';
import { UiService } from 'app/core/ui.service';
import { SemanticModalComponent } from 'app/semantic-legacy/components/modal/modal';
import { NotificationService } from '../../../../shared/itc/notification/notification.service';

@Component({
    selector: 'scan-wizard',
    templateUrl: './scan-wizard.component.html',
    styleUrls: ['./scan-wizard.component.css'],
})
export class ScanWizardComponent implements OnInit, AfterViewInit, OnDestroy {
    private _pageNumber = 0;

    @Input() set pageNumber(value: number) {
        this._pageNumber = value;
        this.processDivs();
    }

    get pageNumber() {
        return this._pageNumber;
    }

    @ViewChild('wizarddiv', { static: true }) wizardDiv;
    @ViewChild('flex', { static: true }) flex: WjFlexGrid;
    @ViewChild('advancedOptionFlex', { static: true }) advancedOptionFlex: WjFlexGrid;
    @ViewChild('scanHostResultsModal', { static: true })
    scanHostModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('getApplianceInfoModal', { static: true })
    getApplianceInfoModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('getApplianceNetworkModal', { static: true })
    getApplianceNetworkModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('systemMessageModal', { static: true })
    systemMessageModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('excludeIpsModal', { static: true })
    excludeIpsModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('largeIpRangeModal', { static: true })
    largeIpRangeModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('fqdnWarningModal', { static: true })
    fqdnWarningModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('unitrendsResultsModal', { static: true })
    unitrendsModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('ipAddressRangeLimit', { static: true })
    ipAddressRangeLimitModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('timeExceedDayIpRangeModal', { static: true })
    timeExceedDayIpRangeModal: ComponentRef<SemanticModalComponent>;
    @ViewChild('ouTree', { static: true }) ouTree: WjTreeView;
    @ViewChild('textboxUsername', { static: true }) textboxUsername: ElementRef;
    @ViewChild('advancedOptionsModal', { static: true }) advancedOptionsModal: WjPopup;

    loadingComplete: boolean;
    email: string;
    additionalCredentialListBoxLabelText: string;
    mergeOption: string = 'primary';
    scanOption: string = 'activedirectory';
    specifiedDomain: string = '';
    username: string = '';
    password: string = '';
    domainController: string = '';
    gatherOption: string = 'gatherAll';
    additionalUsername: string = '';
    additionalPassword: string = '';
    additionalUsers: AdditionalUser[] = [];
    ouTreeItems: any[] = [];
    ipRanges: string[] = [];
    fileScanDays: string[] = [
        '<never>',
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    selectedFileScanDay: string;
    selectedIpRanges: string[] = [];
    modalExcludedSelectedIpRanges: string[] = [];
    excludedSelectedIpRanges: string[] = [];
    selectedExternalIpRanges: string[] = [];
    isLinux: boolean = true;
    scanhostusername: string = '';
    scanhostpassword: string = '';
    scanhostdomain: string = '';
    scanhosts: ScanHostRow[] = [];
    scanHostResponse: string = '';
    ipRangeTextBoxValue: string = '';
    excludedIpRangeTextBoxValue: string = '';
    vmwareHostnameOrIp: string;
    vmwareUsername: string;
    vmwarePassword: string;
    vmwareCredentials: any[];
    unitrendsHostnameOrIp: string;
    unitrendsUsername: string;
    unitrendsPassword: string;
    unitrendsCredentials: any[];
    unitrendsResponse: string = '';

    externalIpRangeTextBoxValue: string = '';
    communityStrings: string[] = [];
    communityStringTextBoxValue: string = '';
    snmpTimeout: string = '3';
    attemptSnmpAgainNonPingable: boolean = false;
    fileScanEphi: boolean;
    fileScanCardholder: boolean;
    fileScanPii: boolean;
    uploadScanToReporter: boolean;
    doNotScanHost: boolean;
    hasReporter: boolean;
    hasScanHost: boolean;
    isLoading: boolean = false;
    site: Site;
    siteName: string = '';
    systemMessage: string = '';
    isDev: boolean = false;

    isCompleted: boolean;
    scanType: string;
    selectedOus: any;
    applianceInstallType: string;
    subs: any[] = [];
    originalFlags: string[];
    largeIpRangeMessage: string;

    previousAlertUrl: string;

    //advancedoptions
    skipActiveDirectory: boolean;
    skipNetwork: boolean;
    skipSqlServers: boolean;
    skipEventlogs: boolean;
    skipDhcp: boolean;
    skipAdComputerQuery: boolean;
    skipLogonEvents: boolean;
    skipInternetTrace: boolean;
    skipWhois: boolean;
    skipSpeedCheck: boolean;
    skipVmware: boolean;
    skipUnitrends: boolean;
    skipIdAgent: boolean;
    skipInternetAccessibility: boolean;
    skipLoginDetails: boolean;
    skipPolicies: boolean;
    skipPortTesting: boolean;
    skipScreenSaverSettings: boolean;
    skipUrlTesting: boolean;
    skipUsbDetection: boolean;
    skipWifiData: boolean;
    testEntertainment: boolean;
    testPornography: boolean;
    testShareware: boolean;
    testSocialMedia: boolean;
    testWarez: boolean;
    testWebMail: boolean;
    customUrls: UrlCategoryRow[];
    selectedAdvancedOptionTab: number;
    advancedOptionUrlCategories: string[] = [
        'Entertainment',
        'Pornography',
        'Shareware',
        'Social Media',
        'Warez',
        'Web Mail',
    ];
    azureclientid: string;
    azuretenantid: string;
    azuresecret: string;
    installed: boolean = true;
    checkedin: boolean = true;

    breadcrumbs = [];

    constructor(
        private elRef: ElementRef,
        private siteSettingService: SiteSettingService,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private injector: Injector,
        private renderer2: Renderer2,
        private router: Router,
        private notificationService: NotificationService,
        private navService: NavService,
        private uiService: UiService
    ) {}

    ngOnInit() {
        let crumbs = [
            { path: '..', text: 'Settings' },
            { path: '.', text: 'Scan Settings' },
        ];

        if (this.router.url.includes('cyber-hawk'))
            crumbs.unshift({ path: '../..', text: 'Cyber Hawk' });
        else if (this.router.url.includes('compliance-manager'))
            crumbs.unshift({ path: '../..', text: 'Compliance Manager' });

        this.breadcrumbs = crumbs;

        this.communityStrings.push('public');

        this.selectedFileScanDay = '<never>';
        this.vmwareCredentials = [];
        this.unitrendsCredentials = [];
        this.pageNumber = 0;

        this.testEntertainment = true;
        this.testPornography = true;
        this.testShareware = true;
        this.testSocialMedia = true;
        this.testWarez = true;
        this.testWebMail = true;
        this.customUrls = [];
        this.selectedAdvancedOptionTab = 1;

        let customUrl: UrlCategoryRow = new UrlCategoryRow();
        customUrl.url = 'foobar';
        customUrl.category = 'Entertainment';
        //this.customUrls.push(customUrl);

        this.previousAlertUrl = this.navService.getReturnUrl();

        this.broadcastPageNumber();

        /*this.route.params.subscribe((params: Params) => {
        let pageId: string = params['pageid'];
        this.pageNumber = new Number(pageId).valueOf();

    });*/
        this.loadingComplete = false;
    this.subs.push(
        this.messageService.on(SITE).subscribe((site: Site) => {
            if (!site) return;
            this.site = site;
            this.siteSettingService.getApplianceInfoForReal(this.site.Id).then((appliances) => {
                if (!appliances || !appliances.length) {
                    // appliance not installed
                    this.installed = false;
                } else {
                    this.installed = true;
                    for (let i = 0; i < appliances.length; i++) {
                        let info = appliances[i];

                        let lastCheckin = new Date(info.LastCheckin);
                        if (lastCheckin.getFullYear() >= 2000) {
                            this.checkedin = true;
                        } else this.checkedin = false;
                    }
                }
            });
            this.uiService.setTitle('Scan Settings', site.Name);
            this.siteName = site.Name;

            this.loadWizardSettings();
            this.ouTree.onLoadedItems = (e) => {
                this.treeLoadedItems(e);
            };
        })
    );
    this.messageService.broadcast(PING_SITE);
    }

    ngAfterViewInit() {
        this.processDivs();
        //this.initializeScanHostGrid();

        //this.pageNumber = 3  // for testing only

        //this.loadingComplete = true;
    }

    ngOnDestroy() {
        this.pageNumber = null;
        this.broadcastPageNumber();

        for (let sub of this.subs) sub.unsubscribe();
    }

    isItemSelected(nodeHeader: string, nodePath: string, domain: string) {
        let _aSelectedOus = JSON.parse(this.selectedOus);

        let _lSelectedItems: string[] = [];

        for (let selectedOu of _aSelectedOus) {
            if (selectedOu.path) {
                var pathKey = selectedOu.path;
                if (selectedOu.domain) {
                    pathKey = selectedOu.domain + '|' + selectedOu.path;
                }
                _lSelectedItems.push(pathKey);
            } else {
                var headerKey = selectedOu.header;
                if (selectedOu.domain) {
                    headerKey = selectedOu.domain + '|' + selectedOu.header;
                }
                _lSelectedItems.push(headerKey);
            }
        }

        var headerSearchKey = nodeHeader;
        if (domain) {
            headerSearchKey = domain + '|' + nodeHeader;
        }

        let rValue = _lSelectedItems.includes(headerSearchKey);
        if (nodePath && nodePath.length > 0) {
            var nodeSearchKey = nodePath;
            if (domain) {
                nodeSearchKey = domain + '|' + nodePath;
            }
            rValue = _lSelectedItems.includes(nodeSearchKey);
        }

        return rValue;
    }

    selectNodes(nodes: any[]) {
        for (let node of nodes) {
            if (
                this.isItemSelected(node.dataItem.header, node.dataItem.path, node.dataItem.domain)
            ) {
                node.setChecked(true, false);
            }

            if (node.nodes && node.nodes.length > 0) {
                this.selectNodes(node.nodes);
            }
        }
    }

    getSelectedNodes(nodes: any[]) {
        let selectedNodes: any[] = [];
        try {
            for (let node of nodes) {
                if (node) {
                    if (node.isChecked && node.parentNode) {
                        let tmpItem: any = [];
                        tmpItem = Object.assign(node.dataItem);
                        tmpItem.items = [];
                        selectedNodes.push(tmpItem);
                    } else {
                        if (node.nodes && node.nodes.length > 0) {
                            selectedNodes.push(...this.getSelectedNodes(node.nodes));
                        }
                    }
                }
            }
        } catch (error) {}
        return selectedNodes;
    }

    treeLoadedItems(e: any) {
        if (this.selectedOus && this.selectedOus.length > 0) {
            //let _tmpCheckedItems = JSON.parse(this.selectedOus);

            var nodes = this.ouTree.nodes;
            this.selectNodes(this.ouTree.nodes);
        }
    }

    loadWizardSettings() {
        this.scanType = 'activedirectory';
        this.siteSettingService.getGdprScan(this.site.Id).then((s) => {
            this.scanhostusername = s.ScanHostUsername;
            this.scanhostpassword = s.ScanHostPassword;
            this.scanhostdomain = s.ScanHostDomain;
            this.azuretenantid = s.AzureTenant;
            this.azureclientid = s.AzureClientId;
            this.azuresecret = s.AzureSecret;
            this.scanType = s.ScanType;
            //this.selectedOus = s.SelectedOus;
            this.applianceInstallType = s.ApplianceInstallType;
            this.hasReporter = s.HasReporter;
            this.originalFlags = [];
            this.originalFlags.push(...s.Flags);

            // if this is a scan server then do not initialize scan host
            if (!this.applianceInstallType || this.applianceInstallType != 'RFT_SS') {
                if (s.ScanHostList && s.ScanHostList.length > 0) {
                    let _aScanHosts: string[] = s.ScanHostList.split(',');

                    this.scanhosts = [];
                    this.additionalUsers = [];
                    for (let _sScanHost of _aScanHosts) {
                        let row: ScanHostRow = new ScanHostRow();
                        row.scanhost = _sScanHost;
                        row.icon = "<span class='ui small circular label red'>X</span>";
                        this.scanhosts.push(row);
                    }
                }
            }
            if (s.ExternalIpRanges && s.ExternalIpRanges.length > 0) {
                this.selectedExternalIpRanges = [];
                this.selectedExternalIpRanges.push(...s.ExternalIpRanges);
            }

            this.gatherOption = 'gatherAll';

            //-eventlogs|-sql|-internet|-net|-dhcp|-mergeoption|PrimaryDomain|-credsuser|TEST1\radmin|-credsepwd|U/j+8b5o93rBsyFFdz+ZZuj5b6X5yJuVuGu/c2mQenI=|-ad|-addc|10.200.1.10|-ipranges|10.200.1.9

            let _sLastFlag: string = '';
            for (let _sFlag of s.Flags) {
                if (_sLastFlag == '-mergeoption') {
                    if (_sFlag == 'PrimaryDomain') {
                        this.mergeOption = 'primary';
                    } else if (_sFlag == 'NoMerge') {
                        this.mergeOption = 'nodomain';
                    } else if (_sFlag == 'NoDomain') {
                        this.mergeOption = 'workgroup';
                    } else if (_sFlag == 'SpecifiedDomain') {
                        this.mergeOption = 'specified';
                    }
                } else if (_sLastFlag == '-mergedomain') {
                    this.specifiedDomain = _sFlag;
                } else if (_sLastFlag == '-credsuser') {
                    this.username = _sFlag;
                } else if (_sLastFlag == '-credsepwd') {
                    this.password = _sFlag;
                } else if (_sLastFlag == '-adous') {
                    this.gatherOption = 'gatherSelected';

                    let _aTmpSelectedOus: any[] = [];

                    let _aSelectedDomains = _sFlag.split(';');

                    for (var selectedDomainOus of _aSelectedDomains) {
                        let _aSelectedOus: string[] = selectedDomainOus.split(',');
                        var _sDomain = _aSelectedOus[0];
                        for (let i = 1; i < _aSelectedOus.length; i++) {
                            let _sSelectedOu = _aSelectedOus[i];
                            let _tmpSelectedOu: any = {};
                            _tmpSelectedOu.path = _sSelectedOu;
                            _tmpSelectedOu.domain = _sDomain;
                            _aTmpSelectedOus.push(_tmpSelectedOu);
                        }
                    }
                    this.selectedOus = JSON.stringify(_aTmpSelectedOus);
                } else if (_sLastFlag == '-addc') {
                    this.domainController = _sFlag;
                } else if (_sLastFlag == '-ipranges') {
                    let _aIpRanges: string[] = _sFlag.split(',');

                    this.clearSelectedIpRanges();
                    for (let _sIpRange of _aIpRanges) {
                        this.selectedIpRanges.push(_sIpRange);
                    }
                } else if (_sLastFlag == '-excludeips') {
                    let _aIpRanges: string[] = _sFlag.split(',');

                    this.excludedSelectedIpRanges = [];
                    for (let _sIpRange of _aIpRanges) {
                        this.excludedSelectedIpRanges.push(_sIpRange);
                    }
                } else if (_sLastFlag == '-wmicred') {
                    let _iEqualsIndex = _sFlag.indexOf('=');
                    if (_iEqualsIndex >= 0) {
                        let au: AdditionalUser = new AdditionalUser();
                        au.username = _sFlag.substring(0, _iEqualsIndex);
                        au.password = _sFlag.substring(_iEqualsIndex + 1);
                        this.additionalUsers.push(au);
                    }
                } else if (_sLastFlag === '-vmware') {
                    this.vmwareCredentials = JSON.parse(_sFlag);
                    //console.log('vmware: ' + _sFlag);
                    //                        let _saVmwareCredentials: string[] = _sFlag.split(',');
                    //                        for (let _sVmwareCredential of _saVmwareCredentials) {

                    //                            let _aVmwareCredential: any = {};
                    //                            let _saTokens: string[] = _sVmwareCredential.split('@');

                    //                            _aVmwareCredential.Hostname = _saTokens[0];
                    //                            if (_saTokens.length === 4) {
                    //                                _aVmwareCredential.Username = _saTokens[1];
                    //                                _aVmwareCredential.Password = _saTokens[2];
                    //                            } else if (_saTokens.length === 5) {
                    //                                _aVmwareCredential.Username = _saTokens[1] + '@' + _saTokens[2];
                    //                                _aVmwareCredential.Password = _saTokens[3];
                    //                            }

                    //                            this.vmwareCredentials.push(_aVmwareCredential);
                    //                        }
                } else if (_sLastFlag === '-unitrends') {
                    let _saUnitrendsCredentials: string[] = _sFlag.split(',');
                    for (let _sUnitrendsCredential of _saUnitrendsCredentials) {
                        let _aUnitrendsCredential: any = {};
                        let _saTokens: string[] = _sUnitrendsCredential.split('@');

                        _aUnitrendsCredential.Hostname = _saTokens[0];
                        if (_saTokens.length === 3) {
                            _aUnitrendsCredential.Username = _saTokens[1];
                            _aUnitrendsCredential.Password = _saTokens[2];
                        } else {
                            _aUnitrendsCredential.Username = _saTokens[1];
                            for (let j = 2; j < _saTokens.length - 1; j++) {
                                _aUnitrendsCredential.Username += '@';
                                _aUnitrendsCredential.Username += _saTokens[j];
                            }
                            _aUnitrendsCredential.Password = _saTokens[_saTokens.length - 1];
                        }

                        this.unitrendsCredentials.push(_aUnitrendsCredential);
                    }
                } else if (_sLastFlag === '-snmp') {
                    this.communityStrings = [];
                    let _saSnmpCommunityStrings: string[] = _sFlag.split(',');
                    for (let _sSnmpCommunityString of _saSnmpCommunityStrings) {
                        this.communityStrings.push(_sSnmpCommunityString);
                    }
                } else if (_sLastFlag === '-snmptimeout') {
                    this.snmpTimeout = _sFlag;
                } else if (_sLastFlag === '-filescanday') {
                    this.selectedFileScanDay = _sFlag;
                } else if (_sLastFlag === '-filescantype') {
                    let _saScanTypes = _sFlag.split(',');
                    for (let _sScanType of _saScanTypes) {
                        if (_sScanType == 'hipaa') {
                            this.fileScanEphi = true;
                        }
                        if (_sScanType == 'pii') {
                            this.fileScanPii = true;
                        }
                        if (_sScanType == 'pci') {
                            this.fileScanCardholder = true;
                        }
                    }
                } else if (_sLastFlag === '-customurls') {
                    this.customUrls = [];

                    let _saCustomUrls = _sFlag.split(',');
                    for (let _sCustomUrl of _saCustomUrls) {
                        let _saCustomUrl = _sCustomUrl.split('\\');
                        if (_saCustomUrl && _saCustomUrl.length > 1) {
                            let urlCategoryRow: UrlCategoryRow = new UrlCategoryRow();
                            urlCategoryRow.url = _saCustomUrl[0];
                            urlCategoryRow.category = this.getCategory(_saCustomUrl[1]);

                            this.customUrls.push(urlCategoryRow);
                        }
                    }
                }

                if (_sFlag === '-uploadtoreporter') {
                    this.uploadScanToReporter = true;
                } else if (_sFlag === '-nonpingable') {
                    this.attemptSnmpAgainNonPingable = true;
                } else if (_sFlag === '-skipadcomputers') {
                    this.skipAdComputerQuery = true;
                } else if (_sFlag === '-skipad') {
                    this.skipActiveDirectory = true;
                } else if (_sFlag === '-skipdhcp') {
                    this.skipDhcp = true;
                } else if (_sFlag === '-skipeventlogs') {
                    this.skipEventlogs = true;
                } else if (_sFlag === '-skipidagent') {
                    this.skipIdAgent = true;
                } else if (_sFlag === '-skipinternet') {
                    this.skipInternetTrace = true;
                } else if (_sFlag === '-skiplogonevents') {
                    this.skipLogonEvents = true;
                } else if (_sFlag === '-skipnetwork') {
                    this.skipNetwork = true;
                } else if (_sFlag === '-skipspeedchecks') {
                    this.skipSpeedCheck = true;
                } else if (_sFlag === '-skipsqlservers') {
                    this.skipSqlServers = true;
                } else if (_sFlag === '-skipvmware') {
                    this.skipVmware = true;
                } else if (_sFlag === '-skipunitrends') {
                    this.skipUnitrends = true;
                } else if (_sFlag === '-skipwhois') {
                    this.skipWhois = true;
                } else if (_sFlag === '-skipsdfinternet') {
                    this.skipInternetAccessibility = true;
                } else if (_sFlag === '-skipsdfports') {
                    this.skipPortTesting = true;
                } else if (_sFlag === '-skipsdfrsop') {
                    this.skipPolicies = true;
                } else if (_sFlag === '-skipsdfscreensaver') {
                    this.skipScreenSaverSettings = true;
                } else if (_sFlag === '-skipsdfurls') {
                    this.skipUrlTesting = true;
                } else if (_sFlag === '-skipsdfusb') {
                    this.skipUsbDetection = true;
                } else if (_sFlag === '-skipsdfwifi') {
                    this.skipWifiData = true;
                } else if (_sFlag === '-skipsdfldf') {
                    this.skipLoginDetails = true;
                } else if (_sFlag === '-skipentertainment') {
                    this.testEntertainment = false;
                } else if (_sFlag === '-skipsocialmedia') {
                    this.testSocialMedia = false;
                } else if (_sFlag === '-skipporn') {
                    this.testPornography = false;
                } else if (_sFlag === '-skipwarez') {
                    this.testWarez = false;
                } else if (_sFlag === '-skipwebmail') {
                    this.testWebMail = false;
                } else if (_sFlag === '-skipshareware') {
                    this.testShareware = false;
                } else if (_sFlag == '-skipscanhost') {
                    this.doNotScanHost = true;
                }

                _sLastFlag = _sFlag;
            }
            this.loadingComplete = true;
        });
    }

    initializeScanHostGrid() {
        if (this.flex) {
            this.flex.allowAddNew = true;
            this.flex.allowDelete = true;

            this.flex.selectionMode = SelectionMode.Row;

            this.flex.autoGenerateColumns = false;
            this.flex.onFormatItem = this.formatFlexGridItem;
            let roleColumn: any = this.flex.columns[0];
            roleColumn.isContentHtml = true;
            roleColumn.dataType = 0;
            roleColumn.isReadOnly = true;
            roleColumn.allowResizing = false;
            roleColumn.width = 35;
            roleColumn.align = 'center';
            let hostColumn: any = this.flex.columns[1];
            hostColumn.width = '*';

            this.flex.addEventListener(this.flex.hostElement, 'click', (e) => {
                let ht: HitTestInfo = this.flex.hitTest(e);
                if (ht.panel == this.flex.cells) {
                    if (ht.col == 0) {
                        this.onRedXClick(ht.row);
                    }
                }
            });
        }
    }

    initializeAdvancedOptionGrid() {
        if (this.advancedOptionFlex) {
            this.advancedOptionFlex.allowAddNew = true;
            this.advancedOptionFlex.allowDelete = true;

            this.advancedOptionFlex.selectionMode = SelectionMode.Row;

            //this.advancedOptionFlex.autoGenerateColumns = false;

            let urlColumn: any = this.advancedOptionFlex.columns[0];
            urlColumn.width = 500;
            urlColumn.allowResizing = false;

            let categoryColumn: any = this.advancedOptionFlex.columns[1];
            categoryColumn.width = '*';

            categoryColumn.dataMap = new DataMap(this.advancedOptionUrlCategories);
        }
    }

    resizeFlexRows(grid) {
        setTimeout(() => {
            if (grid) {
                var row = grid.columnHeaders.rows[0];
                row.wordWrap = true;
                grid.autoSizeRow(0, true); // autosize header

                grid.autoSizeRows(); // autosize all rows
            }
        }, 250);
    }

    formatFlexGridItem(e: FormatItemEventArgs) {
        //console.log("formatItem: " + e.row + ", " + e.col);
        /*
     if (e.col == 0 && e.row >= 0) {
         console.log("e: " + e.data + ", " + e.cell.innerHTML);
         e.cell.innerHTML = "<span class='ui circular label red'>X</span>";
     }*/
    }

    processDivs() {
        let divList = this.wizardDiv.nativeElement.querySelectorAll('.wizard-div');

        //this.elRef.nativeElement.querySelectorAll('div');
        let index: number = 0;
        for (let div of divList) {
            this.processDiv(div, index);
            index++;
        }
    }

    processDiv(div: HTMLDivElement, index: number) {
        //console.log("div: " +index+ ":" + div);

        let divElement = div as HTMLDivElement;
        divElement.hidden = index != this.pageNumber;
    }

    onRedXClick(row: number) {
        //this.scanhosts.splice(row, 1);
        //this.flex.refresh(true);  // this isn't updating the screen, so must kludge

        let tmpArray: any[] = [];
        tmpArray.push(...this.scanhosts);
        tmpArray.splice(row, 1);
        this.scanhosts = [];
        this.scanhosts.push(...tmpArray);
    }

    onRowAdded(e: CellRangeEventArgs) {
        for (let scr of this.scanhosts) {
            if (scr.icon == undefined) {
                scr.icon = "<span class='ui small circular label red'>X</span>";
            }
        }
    }

    isActiveDirectory() {
        return this.scanType && this.scanType.indexOf('activedirectory') >= 0;
    }

    isWorkgroup() {
        return this.scanType && this.scanType.indexOf('workgroup') >= 0;
    }

    isAzureScanType() {
        return this.scanType && this.scanType.indexOf('azure') > 0;
    }

    isAzureAuditGuru() {
        if (!this.site?.AccountId.includes('@D3M')) {
            return false;
        }

        if (this.site && this.site.Type) {
            return this.site.Type == 'NIST';
        } else {
            return false;
        }
    }

    isAuditGuru() {
        if (this.site && this.site.Type) {
            return (
                this.site.Type == 'GDPR' ||
                this.site.Type == 'UKGDPR' ||
                this.site.Type == 'HIPAA' ||
                this.site.Type == 'CYBERINSURANCE' ||
                this.site.Type == 'NIST' ||
                this.site.Type == 'CMMC'
            );
        } else {
            return false;
        }
    }

    isCyberHawk() {
        if (this.site && this.site.Type) {
            return this.site.Type == 'standard';
        }
    }

    logTree() {
        for (let ouTreeItem of this.ouTreeItems) {
            for (var propName in ouTreeItem) {
                console.log(propName, ouTreeItem[propName]);
            }
        }
    }

    logObject(o: any) {
        for (var propName in o) {
            console.log(propName, o[propName]);
        }
    }

    azureCredentialsPage: number = 14;

    //nextPage and previousPage are becoming complicated

    nextPage() {
        //console.log('pageNumber: ' + this.pageNumber);
        if (this.isAzureAuditGuru() && this.isAzureScanType() && this.pageNumber == 0) {
            this.pageNumber = this.azureCredentialsPage;

            this.broadcastPageNumber();
            return;
        }

        if (
            this.isAzureAuditGuru() &&
            this.isAzureScanType() &&
            this.pageNumber == this.azureCredentialsPage
        ) {
            this.pageNumber = 0;
            this.initializeScanHostGrid();
        }

        this.pageNumber++;

        if (this.applianceInstallType == 'RFT_SS' && this.pageNumber == 1) {
            this.pageNumber++;
        }

        if (this.isWorkgroup() && this.pageNumber == 2) {
            this.pageNumber++;
        }

        if (this.isWorkgroup() && this.pageNumber == 4) {
            this.pageNumber++;
        }

        let isAuditGuru = this.isAuditGuru();

        if (isAuditGuru && this.pageNumber == 9) {
            this.pageNumber += 4;
        }

        if (!isAuditGuru && this.pageNumber == 8) {
            this.pageNumber++;
        }

        if (this.pageNumber == 13) {
            this.finishWizard();
        }
        this.broadcastPageNumber();
    }

    previousPage() {
        if (
            this.isAzureAuditGuru() &&
            this.isAzureScanType() &&
            this.pageNumber == this.azureCredentialsPage
        ) {
            this.pageNumber = 0;
            this.broadcastPageNumber();
            return;
        }

        this.pageNumber--;

        if (this.isWorkgroup() && this.pageNumber == 2) {
            this.pageNumber--;
        }

        if (this.applianceInstallType == 'RFT_SS' && this.pageNumber == 1) {
            this.pageNumber--;
        }

        let isAuditGuru = this.isAuditGuru();

        if (!isAuditGuru && this.pageNumber == 8) this.pageNumber--;

        if (isAuditGuru && this.pageNumber == 12) {
            this.pageNumber -= 4;
        }

        if (this.isAzureAuditGuru() && this.isAzureScanType() && this.pageNumber == 0) {
            this.pageNumber = this.azureCredentialsPage;
        }

        this.broadcastPageNumber();
    }

    previousAlert() {
        if (this.previousAlertUrl) {
            this.router.navigateByUrl(this.previousAlertUrl);
        } else {
            this.router.navigate(['../'], { relativeTo: this.route });
        }
    }

    previousPages(pageCount: number) {
        this.pageNumber -= pageCount;
        this.broadcastPageNumber();
    }

    broadcastPageNumber() {
        this.messageService.broadcast('SCAN_WIZARD_PAGE_CHANGE', this.pageNumber);
    }

    nextPageScanType() {
        this.initializeScanHostGrid();
        this.nextPage();
    }

    nextPageWorkgroup() {
        this.showApplianceNetworkModal();

        this.loadApplianceInfo().then((res) => {
            this.pageNumber++;
            this.nextPage();
        });
    }

    nextPageActiveDirectory() {
        if (this.validateFQDN()) {
            this.loadApplianceInfoModal();
        } else {
            (this.fqdnWarningModal as any).show({ closable: false });
        }
    }

    loadApplianceInfoModal() {
        this.showApplianceInfoModal();

        this.loadApplianceInfo().then((res) => {
            this.nextPage();
        });
    }

    validateFQDN() {
        let isValid: boolean = false;
        let slashIndex: number = this.username.indexOf('\\');
        if (slashIndex > 0) {
            let domain: string = this.username.substring(0, slashIndex);

            var re = /^(?!:\/\/)([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/i;
            var matches = re.exec(domain);

            isValid = re.test(domain);
        }

        return isValid;
    }

    closeFqdnWarningModalAction(isOk: boolean) {
        (this.fqdnWarningModal as any).hide();
        if (isOk) {
            this.loadApplianceInfoModal();
        } else {
            const element = this.renderer2.selectRootElement('#textboxUsername');
            setTimeout(() => element.focus(), 500);
        }
    }

    nextPageIpRanges() {
        this.siteSettingService
            .getIpCount(this.selectedIpRanges, this.excludedSelectedIpRanges)
            .then((res) => {
                let totalIps: number = res;
                let totalMinutes = Math.floor(totalIps / 6);

                if (this.isCyberHawk() && totalMinutes > 23 * 60 + 59) {
                    //if time is more than 23 hours and 59 minutes then stops configuration and presents popup (RFTDEV-10723)
                    (this.timeExceedDayIpRangeModal as any).show({ closable: false });
                } else {
                    var days = Math.floor(totalMinutes / (60 * 24));
                    totalMinutes -= days * (60 * 24);

                    var hours = Math.floor(totalMinutes / 60);
                    totalMinutes -= hours;

                    if (this.selectedIpRanges.length < 3000) {
                        // number of Kilobytes allowed
                        if (totalIps > 10) {
                            this.largeIpRangeMessage =
                                'You are currently about to scan ' +
                                totalIps.toLocaleString() +
                                ' IP addresses. Large ranges can take long periods of time to complete.<br><br>';
                            this.largeIpRangeMessage += 'Estimated time: ';
                            if (days > 0) {
                                this.largeIpRangeMessage += days + ' Days';
                            } else if (hours > 0) {
                                this.largeIpRangeMessage += hours + ' Hours';
                            } else if (totalMinutes > 0) {
                                this.largeIpRangeMessage += '1 Hour';
                            }
                            this.largeIpRangeMessage +=
                                '<br><br>Press OK to Continue or Cancel to change IP Ranges.';

                            (this.largeIpRangeModal as any).show({ closable: false });
                        } else {
                            this.nextPage();
                        }
                    } else {
                        (this.ipAddressRangeLimitModal as any).show({ closable: false });
                    }
                }
            });
    }

    nextPageExternalVuln() {
        let req = new ScanSettingsRequest();

        // external ip ranges screen
        if (this.selectedExternalIpRanges && this.selectedExternalIpRanges.length > 0) {
            req.ExternalIpRanges = [];
            req.ExternalIpRanges.push(...this.selectedExternalIpRanges);
        } else {
            alert('At least one external IP address must be specified.');
            return;
        }

        this.siteSettingService.validateExternalVulnerabilityScanSettings(req).then((res) => {
            let validationResult: string = res;

            if (validationResult.length > 0) {
                this.showSystemMessageModel(validationResult);
            } else {
                this.nextPage();
            }
        });
    }

    loadApplianceInfo(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this.username) {
                reject(false);
            }

            let air = new ApplianceInfoRequest();
            if (this.isActiveDirectory()) {
                air.Domain = this.domainController;
            }

            air.Username = this.username;
            air.Password = this.password;
            air.SiteName = this.siteName;

            if (this.scanhostusername) {
                air.ScanHostUsername = this.scanhostusername;
                air.ScanHostPassword = this.scanhostpassword;
                air.ScanHostDomain = this.scanhostdomain;
                air.ScanHostList = this.scanhosts.map((o) => o.scanhost).join(',');
            }

            this.additionalCredentialListBoxLabelText =
                this.username + ' (AD user to be used first)';

            this.siteSettingService
                .getApplianceInfo(air)
                .then((s) => {
                    if (this.isActiveDirectory()) {
                        this.ouTreeItems = s.OuNodes;
                    }

                    this.ipRanges = s.IpRanges;
                    if (this.selectedIpRanges && this.selectedIpRanges.length == 0) {
                        this.selectedIpRanges = [];
                        s.IpRanges.forEach((s) => this.selectedIpRanges.push(s));
                    }

                    if (this.isActiveDirectory()) {
                        this.closeApplianceInfoModalAction();
                        if (
                            (!this.ouTreeItems || this.ouTreeItems.length == 0) &&
                            (!this.ipRanges || this.ipRanges.length == 0)
                        ) {
                            this.systemMessage =
                                'Unable to communicate with specified AD Controller.  Please check credentials and try again.';
                            (this.systemMessageModal as any).show({ closable: false });
                            reject(false);
                        }
                    } else if (this.isWorkgroup()) {
                        this.closeApplianceNetworkModalAction();
                    }
                })
                .then((s) => {
                    resolve(true);
                })
                .catch(this.error);
        });
    }

    addAdditionalCredentials() {
        if (this.additionalUsername != null && this.additionalUsername.length > 0) {
            let au = new AdditionalUser();
            au.username = this.additionalUsername;
            au.password = this.additionalPassword;

            this.additionalUsers.push(au);
        }
    }

    addCommunityString() {
        if (this.communityStringTextBoxValue && this.communityStringTextBoxValue.length > 0) {
            this.communityStrings.push(this.communityStringTextBoxValue);
            this.communityStringTextBoxValue = '';
        }
    }

    clearCommunityStrings() {
        this.communityStrings = [];
    }

    resetCommunityStrings() {
        this.clearCommunityStrings();
        this.communityStrings.push('public');
    }

    resetSnmpTimeout() {
        this.snmpTimeout = '3';
    }

    closeApplianceInfoModalAction() {
        (this.getApplianceInfoModal as any).hide();
    }

    closeApplianceNetworkModalAction() {
        (this.getApplianceNetworkModal as any).hide();
    }

    closeScanHostModalAction() {
        (this.scanHostModal as any).hide();
    }

    closeUnitrendsModalAction() {
        (this.unitrendsModal as any).hide();
    }

    closeIpAddressRangeLimit() {
        (this.ipAddressRangeLimitModal as any).hide();
    }

    closeSystemMessageModalAction() {
        (this.systemMessageModal as any).hide();
    }

    showTestScanHostModal(response: string) {
        this.scanHostResponse = String(response);
        (this.scanHostModal as any).show({ closable: false });
    }

    showTestUnitrendsModal(response: string) {
        this.unitrendsResponse = String(response);
        (this.unitrendsModal as any).show({ closable: false });
    }

    showSystemMessageModel(message: string) {
        this.systemMessage = String(message);
        (this.systemMessageModal as any).show({ closable: false });
    }

    showApplianceInfoModal() {
        (this.getApplianceInfoModal as any).show({ closable: false });
    }

    showApplianceNetworkModal() {
        (this.getApplianceNetworkModal as any).show({ closable: false });
    }

    validateIP(ip) {
        let regex =
            /^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(-(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))?)$/;
        let match = regex.exec(ip);
        return match;
    }

    addIpRange() {
        if (this.ipRangeTextBoxValue) {
            if (!this.validateIP(this.ipRangeTextBoxValue)) {
                this.notificationService.toast.error('Error', 'Invalid IP format.');
                return false;
            }

            let existingValueIndex: number = this.selectedIpRanges.indexOf(
                this.ipRangeTextBoxValue,
                0
            );

            if (existingValueIndex < 0) {
                if (this.ipRangeTextBoxValue.length > 0) {
                    this.selectedIpRanges.push(this.ipRangeTextBoxValue);
                }
            } else {
                this.notificationService.toast.error('Error', 'IP already exists');
                return false;
            }
        }
    }

    addExcludedIpRange() {
        if (this.excludedIpRangeTextBoxValue) {
            let regex =
                /^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(-(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))?)$/;
            let match = regex.exec(this.excludedIpRangeTextBoxValue);
            if (!match) {
                this.notificationService.toast.error('Error', 'Invalid IP format.');
                return false;
            }

            let existingValueIndex: number = this.modalExcludedSelectedIpRanges.indexOf(
                this.excludedIpRangeTextBoxValue,
                0
            );

            if (existingValueIndex < 0) {
                if (this.excludedIpRangeTextBoxValue.length > 0) {
                    this.modalExcludedSelectedIpRanges.push(this.excludedIpRangeTextBoxValue);
                }
            } else {
                this.notificationService.toast.error('Error', 'IP already exists');
                return false;
            }
        }
    }

    resetSelectedIpRangesToAutoDetected() {
        this.selectedIpRanges = [];
        this.ipRanges.forEach((s) => this.selectedIpRanges.push(s));
    }

    clearSelectedIpRanges() {
        this.selectedIpRanges = [];
    }

    clearExcludedSelectedIpRanges() {
        this.modalExcludedSelectedIpRanges = [];
    }

    removeSelectedUser(selectedValue: any) {
        if (selectedValue && selectedValue.length > 0) {
            let selectedIndex: number = -1;
            for (var i = 0; i < this.additionalUsers.length; i++) {
                let au: AdditionalUser = this.additionalUsers[i];
                if (au.username == selectedValue) {
                    selectedIndex = i;
                }
            }

            if (selectedIndex >= 0) {
                this.additionalUsers.splice(selectedIndex, 1);
            }
        }
    }

    removeSelectedIpRange(selectedValue: string) {
        if (selectedValue && selectedValue.length > 0) {
            let selectedValueIndex: number = this.selectedIpRanges.indexOf(selectedValue, 0);
            if (selectedValueIndex >= 0) {
                this.selectedIpRanges.splice(selectedValueIndex, 1);
            }
        }
    }

    removeSelectedUnitrendsServer(selectedValue: any) {
        if (selectedValue && selectedValue.length > 0) {
            let selectedIndex: number = -1;
            for (var i = 0; i < this.unitrendsCredentials.length; i++) {
                let uc: any = this.unitrendsCredentials[i];
                if (uc.Hostname + ' (' + uc.Username + ')' == selectedValue) {
                    selectedIndex = i;
                }
            }
            if (selectedIndex >= 0) {
                this.unitrendsCredentials.splice(selectedIndex, 1);
            }
        }
    }

    removeExcludedSelectedIpRange(selectedValue: string) {
        if (selectedValue && selectedValue.length > 0) {
            let selectedValueIndex: number = this.modalExcludedSelectedIpRanges.indexOf(
                selectedValue,
                0
            );
            if (selectedValueIndex >= 0) {
                this.modalExcludedSelectedIpRanges.splice(selectedValueIndex, 1);
            }
        }
    }

    excludeIps() {
        this.modalExcludedSelectedIpRanges = [];
        this.modalExcludedSelectedIpRanges.push(...this.excludedSelectedIpRanges);
        (this.excludeIpsModal as any).show({ closable: false });
    }

    closeExcludeIpsModalAction(isConfirmed: boolean) {
        (this.excludeIpsModal as any).hide();
        if (isConfirmed) {
            this.excludedSelectedIpRanges = [];
            this.excludedSelectedIpRanges.push(...this.modalExcludedSelectedIpRanges);
        } else {
            this.modalExcludedSelectedIpRanges = [];
            //this.excludedSelectedIpRanges = [];
        }
    }

    closeLargIpRangeAction(isConfirmed: boolean) {
        (this.largeIpRangeModal as any).hide();
        if (isConfirmed) {
            this.nextPage();
        }
    }

    testScanHosts() {
        let req: CheckScanHostReq = new CheckScanHostReq();
        req.Username = this.scanhostusername;
        req.Password = this.scanhostpassword;
        req.Domain = this.scanhostdomain;
        //req.ApplianceId = "NDA1-03WP";  //todo make this dynamic
        //req.SiteName = "Linux Detector";
        req.SiteName = this.siteName;
        req.Hosts = [];

        if (this.scanhosts) {
            for (let scanhost of this.scanhosts) {
                req.Hosts.push(scanhost.scanhost);
            }
        }

        this.isLoading = true;
        this.showTestScanHostModal('Waiting for response from Scan Host.');

        this.siteSettingService.checkScanHosts(req).then((s) => {
            this.scanHostResponse = s;
            this.isLoading = false;
        });
    }

    testUnitrendsConnections() {
        let reqs: CheckUnitrendsReq[] = [];
        for (var i = 0; i < this.unitrendsCredentials.length; i++) {
            let req: CheckUnitrendsReq = new CheckUnitrendsReq();
            req.Username = this.unitrendsCredentials[i].Username;
            req.Password = this.unitrendsCredentials[i].Password;
            req.Server = this.unitrendsCredentials[i].Hostname;
            req.SiteName = this.siteName;
            reqs.push(req);
        }

        this.isLoading = true;
        this.showTestUnitrendsModal('Waiting for response from Unitrends.');

        this.siteSettingService.checkUnitrends(reqs).then((s) => {
            this.unitrendsResponse = s;
            this.isLoading = false;
        });
    }

    finishWizard() {
        let req = new ScanSettingsRequest();
        //req.ApplianceId = "NDA1-03WP";  //todo make this dynamic
        //req.SiteName = "Linux Detector";
        req.SiteName = this.siteName;
        req.ScanType = this.scanType;
        req.SelectedOus = '';
        if (this.scanhostusername) {
            req.ScanHostUsername = this.scanhostusername;
            req.ScanHostPassword = this.scanhostpassword;
            req.ScanHostDomain = this.scanhostdomain;
            req.ScanHostList = this.scanhosts.map((o) => o.scanhost).join(',');
        }

        if (this.isWorkgroup()) {
            this.mergeOption = 'workgroup';
        }

        req.Flags = [];
        req.Flags.push('-eventlogs');
        req.Flags.push('-sql');
        req.Flags.push('-internet');
        req.Flags.push('-net');
        req.Flags.push('-dhcp');
        req.Flags.push('-speedchecks');
        // merge option screen
        req.Flags.push('-mergeoption');
        if (this.mergeOption == 'nodomain') {
            req.Flags.push('NoMerge');
        } else if (this.mergeOption == 'specified') {
            req.Flags.push('SpecifiedDomain');
            req.Flags.push('-mergedomain');
            req.Flags.push(this.specifiedDomain);
        } else if (this.mergeOption == 'primary') {
            req.Flags.push('PrimaryDomain');
        } else if (this.mergeOption == 'workgroup') {
            req.Flags.push('NoDomain');
        }

        //active directory screen
        if (this.isActiveDirectory()) {
            req.Flags.push('-credsuser');
            req.Flags.push(this.username);

            req.Flags.push('-credsepwd');
            req.Flags.push(this.password);

            req.Flags.push('-ad');
            req.Flags.push('-addc');
            req.Flags.push(this.domainController);
        } else if (this.isWorkgroup()) {
            req.Flags.push('-credsuser');
            req.Flags.push(this.username);

            req.Flags.push('-credsepwd');
            req.Flags.push(this.password);
        }

        if (this.isAzureScanType()) {
            req.AzureTenant = this.azuretenantid;
            req.AzureClientId = this.azureclientid;
            req.AzureSecret = this.azuresecret;
        }

        if (this.additionalUsers && this.additionalUsers.length > 0) {
            for (let au of this.additionalUsers) {
                req.Flags.push('-wmicred');
                req.Flags.push(au.username + '=' + au.password);
            }
        }

        // local domains screen
        if (this.gatherOption == 'gatherSelected') {
            if (this.ouTreeItems && this.ouTreeItems.length > 0) {
                //req.SelectedOus = JSON.stringify(this.ouTree.checkedItems);

                let selectedOuNodes = this.getSelectedNodes(this.ouTree.nodes);

                req.SelectedOus = JSON.stringify(selectedOuNodes);

                let tmp: string = '';

                // let rootNode: any = this.ouTreeItems[0];
                // tmp += rootNode.header;

                var domainNodes = {};

                let hasChildren: boolean = false;
                //for (let leaf of this.ouTree.checkedItems) {
                for (let leaf of selectedOuNodes) {
                    if (!domainNodes[leaf.domain]) {
                        domainNodes[leaf.domain] = [];
                    }

                    domainNodes[leaf.domain].push(leaf);
                    //tmp += (',' + leaf.path);
                }

                for (var domainNode in domainNodes) {
                    if (tmp.length > 0) {
                        tmp += ';';
                    }

                    tmp += domainNode;

                    for (let leaf of domainNodes[domainNode]) {
                        tmp += ',' + leaf.path;
                    }
                }

                if (tmp != '') {
                    req.Flags.push('-adous');
                    req.Flags.push(tmp);
                }
            }
        }

        //ipranges screen
        if (this.selectedIpRanges && this.selectedIpRanges.length > 0) {
            req.Flags.push('-ipranges');
            req.Flags.push(this.selectedIpRanges.join(','));
        }

        // exclude ip ranges
        if (this.excludedSelectedIpRanges && this.excludedSelectedIpRanges.length > 0) {
            req.Flags.push('-excludeips');
            req.Flags.push(this.excludedSelectedIpRanges.join(','));
        }

        // snmp screen
        if (this.communityStrings) {
            req.Flags.push('-snmp');
            req.Flags.push(this.communityStrings.join(','));
        }

        if (this.snmpTimeout) {
            req.Flags.push('-snmptimeout');
            req.Flags.push(this.snmpTimeout);
        }

        if (this.attemptSnmpAgainNonPingable) {
            req.Flags.push('-nonpingable');
        }

        // external ip ranges screen
        if (this.selectedExternalIpRanges && this.selectedExternalIpRanges.length > 0) {
            req.ExternalIpRanges = [];
            req.ExternalIpRanges.push(...this.selectedExternalIpRanges);
        }

        // file scan screen
        if (this.selectedFileScanDay && this.selectedFileScanDay != '<never>') {
            req.Flags.push('-filescanday');
            req.Flags.push(this.selectedFileScanDay);
            req.Flags.push('-filescantype');

            let _saScanTypes: string[] = [];
            if (this.fileScanEphi) {
                _saScanTypes.push('hipaa');
            }
            if (this.fileScanPii) {
                _saScanTypes.push('pii');
            }
            if (this.fileScanCardholder) {
                _saScanTypes.push('pci');
            }
            req.Flags.push(_saScanTypes.join(','));
        }

        let isAuditGuru = this.isAuditGuru();

        if (!isAuditGuru) {
            // vmware screen
            if (this.vmwareCredentials && this.vmwareCredentials.length > 0) {
                //let _saVmwareCredentials: string[] = [];
                let _aaVmwareCredentials: any[] = [];
                //                for (let _aVmc of this.vmwareCredentials) {

                //let _sVmwareCredentials: string = _aVmc.Hostname + '@' + _aVmc.Username + '@' + _aVmc.Password + '@' + false;
                //_saVmwareCredentials.push(_sVmwareCredentials);

                //                    var _aVmwareCredentials = { hostname:_aVmc.Hostname, username:_aVmc.Username, pwd:_aVmc.Password };
                //                    _aaVmwareCredentials.push(_aVmwareCredentials);

                //                }
                req.Flags.push('-vmware');
                req.Flags.push(JSON.stringify(this.vmwareCredentials));
            }

            // unitrends screen
            if (this.unitrendsCredentials && this.unitrendsCredentials.length > 0) {
                let _saUnitrendsCredentials: string[] = [];
                for (let _aVmc of this.unitrendsCredentials) {
                    let _sUnitrendsCredentials: string =
                        _aVmc.Hostname + '@' + _aVmc.Username + '@' + _aVmc.Password;
                    req.Flags.push('-unitrends');
                    req.Flags.push(_aVmc.Hostname + '@' + _aVmc.Username + '@' + _aVmc.Password);
                }
                //req.Flags.push('-unitrends');
                //req.Flags.push(_saUnitrendsCredentials.join(','));
            }
        }
        if (this.uploadScanToReporter) {
            req.Flags.push('-uploadtoreporter');
        }

        if (this.doNotScanHost) {
            req.Flags.push('-skipscanhost');
        }

        if (this.skipActiveDirectory) {
            req.Flags.push('-skipad');
        }
        if (this.skipAdComputerQuery) {
            req.Flags.push('-skipadcomputers');
        }
        if (this.skipDhcp) {
            req.Flags.push('-skipdhcp');
        }
        if (this.skipEventlogs) {
            req.Flags.push('-skipeventlogs');
        }
        if (this.skipIdAgent) {
            req.Flags.push('-skipidagent');
        }
        if (this.skipInternetTrace) {
            req.Flags.push('-skipinternet');
        }
        if (this.skipLogonEvents) {
            req.Flags.push('-skiplogonevents');
        }
        if (this.skipNetwork) {
            req.Flags.push('-skipnetwork');
        }
        if (this.skipSpeedCheck) {
            req.Flags.push('-skipspeedchecks');
        }
        if (this.skipSqlServers) {
            req.Flags.push('-skipsqlservers');
        }
        if (this.skipVmware) {
            req.Flags.push('-skipvmware');
        }
        if (this.skipUnitrends) {
            req.Flags.push('-skipunitrends');
        }
        if (this.skipWhois) {
            req.Flags.push('-skipwhois');
        }
        if (this.skipInternetAccessibility) {
            req.Flags.push('-skipsdfinternet');
        }
        if (this.skipPortTesting) {
            req.Flags.push('-skipsdfports');
        }
        if (this.skipPolicies) {
            req.Flags.push('-skipsdfrsop');
        }
        if (this.skipScreenSaverSettings) {
            req.Flags.push('-skipsdfscreensaver');
        }
        if (this.skipUrlTesting) {
            req.Flags.push('-skipsdfurls');
        }
        if (this.skipUsbDetection) {
            req.Flags.push('-skipsdfusb');
        }
        if (this.skipWifiData) {
            req.Flags.push('-skipsdfwifi');
        }
        if (this.skipLoginDetails) {
            req.Flags.push('-skipsdfldf');
        }
        if (!this.testEntertainment) {
            req.Flags.push('-skipentertainment');
        }
        if (!this.testSocialMedia) {
            req.Flags.push('-skipsocialmedia');
        }
        if (!this.testPornography) {
            req.Flags.push('-skipporn');
        }
        if (!this.testWarez) {
            req.Flags.push('-skipwarez');
        }
        if (!this.testWebMail) {
            req.Flags.push('-skipwebmail');
        }
        if (!this.testShareware) {
            req.Flags.push('-skipshareware');
        }

        //-customurls|tmz.com\EN,warez-bb.org\WZ
        let _aCustomUrls: string[] = [];
        if (this.customUrls && this.customUrls.length > 0) {
            for (let customUrl of this.customUrls) {
                let _sCustomUrl: string =
                    customUrl.url + '\\' + this.getCategoryCode(customUrl.category);
                _aCustomUrls.push(_sCustomUrl);
            }
        }

        if (_aCustomUrls.length > 0) {
            req.Flags.push('-customurls');
            req.Flags.push(_aCustomUrls.join(','));
        }

        if (!isAuditGuru) {
            req.Flags.push('-detector');
        }

        // original flags
        let abdsFlagIndex: number = this.originalFlags.indexOf('-abdsday');
        if (abdsFlagIndex >= 0) {
            req.Flags.push(this.originalFlags[abdsFlagIndex]);
            req.Flags.push(this.originalFlags[abdsFlagIndex + 1]);
        }

        let level2FlagIndex: number = this.originalFlags.indexOf('-level2');
        if (level2FlagIndex >= 0) {
            req.Flags.push(this.originalFlags[level2FlagIndex]);
        }

        let skipSdfUrlsFlagIndex: number = this.originalFlags.indexOf('-skipsdfurls');
        if (skipSdfUrlsFlagIndex >= 0) {
            req.Flags.push(this.originalFlags[skipSdfUrlsFlagIndex]);
        }

        let speedcheckFlagIndex: number = this.originalFlags.indexOf('-speedchecks');
        if (speedcheckFlagIndex >= 0) {
            req.Flags.push(this.originalFlags[speedcheckFlagIndex]);
        }

        let mbsaFlagIndex: number = this.originalFlags.indexOf('-mbsa');
        if (mbsaFlagIndex >= 0) {
            req.Flags.push(this.originalFlags[mbsaFlagIndex]);
        }

        let updatesFlagIndex: number = this.originalFlags.indexOf('-updates');
        if (updatesFlagIndex >= 0) {
            req.Flags.push(this.originalFlags[updatesFlagIndex]);
        }

        //console.log(req);

        this.siteSettingService.scheduleGdprScan(req);
    }

    showProps(obj, objName) {
        var result = '';
        for (var i in obj) {
            // obj.hasOwnProperty() is used to filter out properties from the object's prototype chain
            if (obj.hasOwnProperty(i)) {
                result += objName + '.' + i + ' = ' + obj[i] + '\n';
            }
        }
        //console.log(result);
        return result;
    }

    addExternalIp() {
        if (this.externalIpRangeTextBoxValue) {
            if (this.externalIpRangeTextBoxValue.length > 0) {
                if (!this.validateIP(this.externalIpRangeTextBoxValue)) {
                    this.notificationService.toast.error('Error', 'Invalid IP format.');
                    return false;
                }
                let existingValueIndex: number = this.selectedExternalIpRanges.indexOf(
                    this.externalIpRangeTextBoxValue,
                    0
                );

                if (existingValueIndex < 0 && this.selectedExternalIpRanges.length < 64) {
                    this.selectedExternalIpRanges.push(this.externalIpRangeTextBoxValue);
                } else {
                    this.notificationService.toast.error('Error', 'IP already exists');
                    return false;
                }
            }
        }
    }

    removeSelectedExternalIp(selectedValue: string) {
        if (selectedValue && selectedValue.length > 0) {
            let selectedValueIndex: number = this.selectedExternalIpRanges.indexOf(
                selectedValue,
                0
            );
            if (selectedValueIndex >= 0) {
                this.selectedExternalIpRanges.splice(selectedValueIndex, 1);
            }
        }
    }

    removeAllExternalIps() {
        this.selectedExternalIpRanges = [];
    }

    onSelectFileScanDayChange($event) {
        this.selectedFileScanDay = $event;

        if (this.selectedFileScanDay === '<never>') {
            this.fileScanCardholder = false;
            this.fileScanEphi = false;
            this.fileScanPii = false;
        }
    }

    onAddVmwareServerClick() {
        if (!this.vmwareHostnameOrIp || !this.vmwareUsername || !this.vmwarePassword) {
            return;
        }

        if (
            this.vmwareHostnameOrIp === '' ||
            this.vmwareUsername === '' ||
            this.vmwarePassword === ''
        ) {
            return;
        }

        let vmwareCreds: any = {};
        vmwareCreds.Hostname = this.vmwareHostnameOrIp;
        vmwareCreds.Username = this.vmwareUsername;
        vmwareCreds.Password = this.vmwarePassword;

        this.vmwareCredentials.push(vmwareCreds);

        this.vmwareHostnameOrIp = '';
        this.vmwarePassword = '';
        this.vmwareUsername = '';
    }

    onClearallEntriesClick() {
        this.vmwareCredentials = [];
    }

    onAddUnitrendsServerClick() {
        if (!this.unitrendsHostnameOrIp || !this.unitrendsUsername || !this.unitrendsPassword) {
            return;
        }

        if (
            this.unitrendsHostnameOrIp === '' ||
            this.unitrendsUsername === '' ||
            this.unitrendsPassword === ''
        ) {
            return;
        }

        let unitrendsCreds: any = {};
        unitrendsCreds.Hostname = this.unitrendsHostnameOrIp;
        unitrendsCreds.Username = this.unitrendsUsername;
        unitrendsCreds.Password = this.unitrendsPassword;

        this.unitrendsCredentials.push(unitrendsCreds);

        this.unitrendsHostnameOrIp = '';
        this.unitrendsPassword = '';
        this.unitrendsUsername = '';
    }

    onAdvancedOptionsClick() {
        this.advancedOptionsModal.show();
        this.initializeAdvancedOptionGrid();
    }

    onAdvancedOptionRowAdded(event: any) {}

    onAdvancedTabClick(tabNum: number) {
        this.selectedAdvancedOptionTab = tabNum;
        if (tabNum === 3) {
            setTimeout(() => {
                this.initializeAdvancedOptionGrid();
                this.resizeFlexRows(this.advancedOptionFlex);
            }, 500);
        }
    }
    closeAdvancedOptionsModalAction() {
        this.advancedOptionsModal.hide();
    }

    getCategoryCode(category: string) {
        let rValue: string = '';

        if (category) {
            if (category === 'Entertainment') {
                rValue = 'EN';
            } else if (category === 'Pornography') {
                rValue = 'PN';
            } else if (category === 'Shareware') {
                rValue = 'SW';
            } else if (category === 'Social Media') {
                rValue = 'SM';
            } else if (category === 'Warez') {
                rValue = 'WZ';
            } else if (category === 'Web Mail') {
                rValue = 'WM';
            }
        }
        return rValue;
    }

    getCategory(code: string) {
        let rValue: string = '';
        if (code) {
            if (code === 'EN') {
                rValue = 'Entertainment';
            } else if (code === 'PN') {
                rValue = 'Pornography';
            } else if (code === 'SW') {
                rValue = 'Shareware';
            } else if (code === 'SM') {
                rValue = 'Social Media';
            } else if (code === 'WZ') {
                rValue = 'Warez';
            } else if (code === 'WM') {
                rValue = 'Web Mail';
            }
        }

        return rValue;
    }

    cellEditEnded(evt: any) {}

    error(err: any) {}
}

export class UrlCategoryRow {
    url: string;
    category: string;
}
