import { Directive, ElementRef, inject, Input, NgZone, OnInit } from '@angular/core';
import { ItcTooltipDirective } from './itc/itc-tooltip.directive';
@Directive({
    selector: '[truncateText]',
    standalone: true,
    hostDirectives: [ItcTooltipDirective],
})
export class TruncateTextDirective implements OnInit {
    @Input() truncateWidth: string = '100%';
    @Input() truncateWithIcon;
    @Input() truncateLines = 1;

    private tooltipDirective = inject(ItcTooltipDirective);
    private fullText = '';
    private interval: ReturnType<typeof setInterval>;

    constructor(private el: ElementRef) {}
    ngOnInit(): void {
        setTimeout(() => {
            this.fullText = this.el.nativeElement.innerText;
            this.el.nativeElement.style.width = this.truncateWidth;
            this.el.nativeElement.style.overflow = 'hidden';
            this.el.nativeElement.style.textOverflow = 'ellipsis';
            this.el.nativeElement.style.whiteSpace = 'nowrap';

            if (this.truncateLines > 1) {
                this.el.nativeElement.style.whiteSpace = 'normal';
                this.el.nativeElement.style.display = '-webkit-box';
                this.el.nativeElement.style.webkitLineClamp = this.truncateLines + '';
                this.el.nativeElement.style.webkitBoxOrient = 'vertical';
            }
            this.interval = setInterval(() => {
                this.checkWidth();
            }, 10);
        }, 1);
    }

    checkWidth(): void {
        if (this.el.nativeElement.getBoundingClientRect().width === 0) return;
        if (this.el.nativeElement.scrollWidth > this.el.nativeElement.clientWidth) {
            this.tooltipDirective.tooltipText = this.fullText;
            this.tooltipDirective.ttPosition = 'bottom-left';
            if (typeof this.truncateWithIcon !== 'undefined') {
                this.el.nativeElement.classList.add('truncatedWithIcon');
            }
        }
        clearInterval(this.interval);
    }
}
