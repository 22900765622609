import { Injectable } from '@angular/core';
import { AuthHttp } from '../../core/auth';
import { ItcFileUploaderService } from '../../shared/itc';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { DateService } from 'app/shared/date.service';
import { SiteService } from './site.service';
import {
    Computer,
    NdpAssessment,
    NdpReport,
    NdpUserReportSet,
    NdpIssueOverride,
    CloudTypes,
    AutomationJob,
    AwsCredentials,
    AzureCredentials,
    ExchangeScanCredentials,
    SqlCredentials,
    ReportAugmentRow,
    Issue,
    AutomationSettings,
    AssessmentScanDataIssue,
    AssessmentScanDataConfigurations,
    AssessmentScanDataContacts,
    DashboardData,
    Connector,
    InForm,
    NdpGenerateReportRequest,
    NdpComputer,
    Schedule,
} from '../site/network-detective-pro/ndpro.model';
import { Site } from './site.model';
import { Breadcrumb } from 'app/shared/breadcrumbs/breadcrumb.model';
import { HttpHeaders } from '@angular/common/http';
import { AuthenticationResult } from '@azure/msal-browser';
import { Survey } from '../site/inform/survey.model';
import { InformRequest } from '../site/inform/informrequest.model';
import { Notes } from './notes';
import { accountDateTimePipe } from 'app/shared/accountDateTime.pipe';
import { AudITReportSettings } from '../site/network-detective-pro/audIT/audIT.model';
import { SiteSettingService } from '../site/settings/site-settings.service';
import { currencyFormatterCode } from './report-preferences/report-preferences.options';

interface NdpSiteAssessments {
    assessments: NdpAssessment[];
    activeAssessment: NdpAssessment;
    archivedAssessments: NdpAssessment[];
}

type NdpState = 'empty' | 'active' | 'archive';

@Injectable({ providedIn: 'root' })
export class NdpService {
    private readonly apiUrl: string;
    private assessments$ = new BehaviorSubject<NdpSiteAssessments>(null);
    private dataExplorerType = 'indoc';
    private breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>(null);
    private state$ = new BehaviorSubject<NdpState>(null);
    private site: Site;

    private dashboardData = new BehaviorSubject<DashboardData>({
        Assessment: null,
        Automation: null,
    });
    public dashboardAssessmentState = new BehaviorSubject<string>(null);
    public dashboardAutomationState = new BehaviorSubject<string>(null);
    public currencyFormatter: Intl.NumberFormat;
    public currencyFormatterNoCents: Intl.NumberFormat;

    constructor(
        private http: AuthHttp,
        private fileUploader: ItcFileUploaderService,
        private dateService: DateService,
        private siteService: SiteService,
        private siteSettingService: SiteSettingService,
        private accountDateTime: accountDateTimePipe
    ) {
        this.apiUrl = environment.apiUrl;
    }

    getBreadcrumbs(): Observable<Breadcrumb[]> {
        return this.breadcrumbs$.asObservable();
    }

    setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
        setTimeout(() => {
            this.breadcrumbs$.next(breadcrumbs);
        }, 0);
    }

    createDataExplorerBreadcrumbs(category: string, pageLink?: string): void {
        let ndpBreadcrumbs: Breadcrumb[] = [
            { text: 'Network Detective Pro' },
            { text: 'Data Explorer' },
            { text: 'Assessment' },
            { text: category },
        ];

        if (this.dataExplorerType === 'ndp-assessment') {
            ndpBreadcrumbs[2].text = 'Assessments';
        } else if (this.dataExplorerType === 'ndp-automation') {
            ndpBreadcrumbs[2].text = 'Automation';
        }

        if (pageLink) {
            ndpBreadcrumbs[3].path = `./${this.dataExplorerType.replace('ndp-', '')}/${pageLink}`;
            ndpBreadcrumbs[3].link = true;
            ndpBreadcrumbs.push({ text: 'Detail' });
        }

        this.setBreadcrumbs(ndpBreadcrumbs);
    }

    getDataExplorerType(): string {
        return this.dataExplorerType;
    }

    setDataExplorerType(type): void {
        this.dataExplorerType = type;
    }

    getState(): Observable<NdpState> {
        return this.state$.asObservable();
    }

    setState(state: NdpState): void {
        setTimeout(() => {
            this.state$.next(state);
        }, 0);
    }

    uploadNdpScanFiles(siteId: number, formData: FormData): Observable<any> {
        return this.fileUploader.uploadFile(`NdPro/UploadNdpScanFiles/${siteId}`, formData);
    }

    getNdpScanFilesMetadata(siteId: number, assessmentId: number, skipMeta: boolean = false) {
        const url: string =
            this.apiUrl +
            `GetNdpScanFilesMetadata/${siteId}/${
                assessmentId == -1 ? 'Automation' : assessmentId
            }?skipMeta=${skipMeta}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    GetNdpScanFilesMetadataForSpecificConnectors(
        siteId: number,
        connectors: Connector[],
        minDate: Date,
        maxDate: Date
    ) {
        const url: string = this.apiUrl + `GetNdpScanFilesMetadataForSpecificConnectors/${siteId}`;
        const postBody = {
            connectors: connectors,
            minDate: minDate,
            maxDate: maxDate,
        };
        return lastValueFrom(this.http.post(url, postBody)).then((res) => res);
    }

    ImportNdpScanFilesIntoAssessment(
        siteId: number,
        assessmentId: number,
        filePaths: string[],
        VSFiles: object[]
    ) {
        const url: string =
            this.apiUrl + `ImportNdpScanFilesIntoAssessment/${siteId}/${assessmentId}`;
        const postBody = {
            filePaths: filePaths,
            VSFiles: VSFiles,
        };
        return lastValueFrom(this.http.post(url, postBody)).then((res) => res);
    }

    deleteNdpScanFile(siteId: number, assessmentId: number, fileName: string) {
        const url: string =
            this.apiUrl +
            `DeleteNdpScanFile/${siteId}/${
                assessmentId == -1 ? 'Automation' : assessmentId
            }/${window.btoa(encodeURIComponent(fileName))}/`;
        return lastValueFrom(this.http.delete(url)).then((res) => res);
    }

    getAssessments(siteId: number): Promise<NdpAssessment[]> {
        const url: string = this.apiUrl + `GetNdpAssessments/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    saveAssessment(siteId: number, assessment: NdpAssessment): Promise<NdpAssessment> {
        const url: string = this.apiUrl + `SaveNdpAssessment/${siteId}`;
        return lastValueFrom(this.http.post(url, assessment)).then((res) => res);
    }

    deleteAssessment(siteId: number, assessmentIds: number[]): Promise<any> {
        const url: string = this.apiUrl + `DeleteNdpAssessment/${siteId}`;
        const deleteOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: assessmentIds,
        };
        return lastValueFrom(this.http.delete(url, deleteOptions)).then((res) => res);
    }

    archiveReportsWorksheetsAndFiles(siteId: number): Promise<any> {
        throw new Error('not implemented');
    }

    getIsReportGenerationComplete(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetNdpIsReportGenerationComplete/${siteId}`;

        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getIsAudITReportGenerationComplete(siteId: number, reportId: string): Promise<any> {
        const url: string =
            this.apiUrl + `GetAudITIsReportGenerationComplete/${siteId}/${reportId}`;

        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    generateReports(
        siteId: number,
        selectedReports: string[],
        reportName: string,
        assessmentId: number,
        assessmentIdArchive: number,
        assessmentIdsTrend: number[],
        audITReportSettings: AudITReportSettings = null
    ): Promise<any> {
        const url: string = this.apiUrl + `NDPGenerateReports/${siteId}`;
        const postBody: NdpGenerateReportRequest = {
            ReportsToGenerate: selectedReports,
            ReportName: reportName,
            AssessmentId: assessmentId,
            AssessmentIdArchive: assessmentIdArchive,
            AssessmentIdsTrend: assessmentIdsTrend,
            AudITSettings: audITReportSettings,
        };

        return lastValueFrom(this.http.post(url, postBody)).then((res) => res);
    }

    getReports(siteId: number): Promise<NdpReport[]> {
        const url: string = this.apiUrl + `NdPro/Reports/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getGeneratedReports(siteId: number): Promise<any[]> {
        const url = this.apiUrl + `GetGeneratedReports/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getReportStatuses(siteId: number): Promise<any[]> {
        const url = this.apiUrl + `GetReportStatuses/${siteId}`;
        return lastValueFrom(this.http.get(url));
    }
    getExportStatuses(siteId: number): Promise<any[]> {
        const url = this.apiUrl + `GetExportStatuses/${siteId}`;
        return lastValueFrom(this.http.get(url));
    }

    // deleteReport(
    //     siteId: number,
    //     assessment: string,
    //     reportset: string,
    //     module: string,
    //     reportname: string
    // ): Promise<any[]> {
    //     const url =
    //         this.apiUrl +
    //         `DeleteReport/${siteId}/${encodeURIComponent(assessment)}/${encodeURIComponent(
    //             reportset
    //         )}/${encodeURIComponent(module)}/${encodeURIComponent(reportname)}`;
    //     return this.http
    //         .delete(url)
    //         .toPromise()
    //         .then((res) => res);
    // }

    deleteReportSet(
        siteId: number,
        iso: string,
        assessment: string,
        reportset: string
    ): Promise<any[]> {
        // just doing it this way (encoding variables separately) because it breaks weird with the new formatting
        iso = encodeURIComponent(iso);
        assessment = encodeURIComponent(window.btoa(assessment));
        reportset = encodeURIComponent(window.btoa(reportset));
        const url = this.apiUrl + `DeleteReport/${siteId}/${iso}/${assessment}/${reportset}`;
        return lastValueFrom(this.http.delete(url)).then((res) => res);
    }
    deleteAutomationReportSet(
        siteId: number,
        iso: string,
        guid: string,
        version: string
    ): Promise<any[]> {
        // just doing it this way (encoding variables separately) because it breaks weird with the new formatting
        iso = encodeURIComponent(iso);
        guid = encodeURIComponent(guid);
        version = encodeURIComponent(version);
        const url = this.apiUrl + `DeleteAutomationReport/${siteId}/${iso}/${guid}/${version}`;
        return lastValueFrom(this.http.delete(url)).then((res) => res);
    }

    cancelReportSet(
        siteId: number,
        assessment: string,
        reportset: string,
        scheduledReportId: string
    ): Promise<any[]> {
        // just doing it this way (encoding variables separately) because it breaks weird with the new formatting
        assessment = encodeURIComponent(window.btoa(assessment));
        reportset = encodeURIComponent(window.btoa(reportset));
        const url =
            this.apiUrl + `CancelReport/${siteId}/${assessment}/${reportset}/${scheduledReportId}`;
        return lastValueFrom(this.http.delete(url)).then((res) => res);
    }

    /// To download directory as ZIP, enter directory as path with '/' at the end (e.g. en-us/Kraken Project/Assessment-2023231-Reports/NetworkAssessment)
    /// To download a single file, enter file path (e.g. en-us/Kraken Project/Assessment-2023231-Reports/NetworkAssessment/Full Detail Report.docx)
    downloadReport(siteId: number, path: string): Promise<any> {
        const url = this.apiUrl + `DownloadReports/${siteId}`;
        const postBody = {
            path: path,
        };
        return lastValueFrom(this.http.post(url, postBody)).then((res) => res);
    }

    getNdpScanRegistry(siteId: number, assessmentId: number): Promise<NdpComputer[]> {
        const url: string = this.apiUrl + `GetNdpScanRegistry/${siteId}/${assessmentId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getUserReportSets(siteId: number): Promise<NdpUserReportSet[]> {
        const url: string = this.apiUrl + `NdPro/UserReportSets/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    saveUserReportSets(siteId: number, reportSet: NdpUserReportSet): Promise<NdpUserReportSet> {
        const url: string = this.apiUrl + `NdPro/UserReportSets/${siteId}`;
        const postBody = {
            set: reportSet,
        };
        return lastValueFrom(this.http.post(url, postBody)).then((res) => res);
    }

    deleteUserReportSet(siteId: number, reportSetId: number) {
        const url: string = this.apiUrl + `NdPro/UserReportSets/${siteId}/${reportSetId}`;
        return lastValueFrom(this.http.delete(url)).then((res) => res);
    }

    startExternalVulnerabilityScan(siteId: number, assessmentId: number) {
        const url: string =
            this.apiUrl + `NdPro/StartExternalVulnerabilityScan/${siteId}/${assessmentId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getExternalVulnerabilityScans(siteId: number, assessmentId: number) {
        const url: string =
            this.apiUrl + `NdPro/ExternalVulnerabilityScans/${siteId}/${assessmentId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getSiteAssessments(site: Site): Observable<NdpSiteAssessments> {
        this.site = site;
        if (this.assessments$.getValue() !== null) {
            return this.assessments$.asObservable();
        }

        this.getAssessments(this.site.Id).then((assessments) => {
            let assessmentObject: NdpSiteAssessments = {
                assessments: assessments,
                activeAssessment: assessments.find((p) => p.IsActive),
                archivedAssessments: assessments.filter((p) => !p.IsActive),
            };
            this.assessments$.next(assessmentObject);
        });
        return this.assessments$.asObservable();
    }

    refreshSiteAssessments(): void {
        this.assessments$.next(null);
        this.getSiteAssessments(this.site);
    }

    addAssessment(assessment: NdpAssessment): void {
        // this.assessments$.next([...this.assessments$.getValue(), assessment]);
        // this.activeAssessment$.next(
        //     this.assessments$.value.find((p) => p.IsActive) || ({} as NdpAssessment)
        // );
    }

    // setScanData(scanData): void {
    //     this.scanData$.next(scanData);
    // }
    // getScanData(): Observable<NdpScanFile[]> {
    //     return this.scanData$.asObservable();
    // }

    generateComponentName(): string {
        const date = new Date();
        return 'Assessment-' + this.dateService.getyyyyMMdd(date);
    }

    startDarkWebIDScan(siteId: number, assessmentId: number) {
        const url: string = this.apiUrl + `NdPro/StartDarkWebIDScan/${siteId}/${assessmentId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getDarkWebIDScan(siteId: number, assessmentId: number) {
        const url: string =
            this.apiUrl +
            `NdPro/GetDarkWebIDScan/${siteId}/${assessmentId == -1 ? 'Automation' : assessmentId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    resetNDPState() {
        this.assessments$ = new BehaviorSubject<NdpSiteAssessments>(null);
        this.dataExplorerType = 'indoc';
        this.breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>(null);
        this.state$ = new BehaviorSubject<NdpState>(null);
        this.site = null;
    }
    getIssues(siteId: number): Promise<Issue[]> {
        const url: string = this.apiUrl + `NdPro/Issues/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }
    getIssueOverrides(siteId: number): Promise<NdpIssueOverride[]> {
        const url: string = this.apiUrl + `NdPro/IssueOverrides/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }
    getGlobalIssueOverrides(siteId: number): Promise<NdpIssueOverride[]> {
        const url: string = this.apiUrl + `NdPro/GlobalIssueOverrides/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }
    saveIssueOverrides(siteId: number, overrides: NdpIssueOverride[]): Promise<NdpIssueOverride[]> {
        const url: string = this.apiUrl + `NdPro/IssueOverrides/${siteId}`;
        const postBody = {
            mode: 'bulk',
            overrides: overrides,
        };
        return lastValueFrom(this.http.post(url, postBody)).then((res) => res);
    }
    saveIssueOverride(siteId: number, override: NdpIssueOverride): Promise<NdpIssueOverride[]> {
        const url: string = this.apiUrl + `NdPro/IssueOverrides/${siteId}`;
        const postBody = {
            mode: 'singular',
            override: override,
        };
        return lastValueFrom(this.http.post(url, postBody)).then((res) => res);
    }

    saveAWSCloudConfig(
        siteId: number,
        assessmentId: number,
        configBody: AwsCredentials,
        testOnly: boolean = false
    ) {
        const url: string =
            this.apiUrl +
            `NdPro/CloudScanConfig/AWS/${siteId}/${assessmentId}` +
            (testOnly ? '?testOnly=true' : '');
        return lastValueFrom(this.http.post(url, configBody)).then((res) => res);
    }

    verifySqlScanCredentials(siteId: number, credentials: SqlCredentials, rdc: string) {
        const url: string = this.apiUrl + `NdPro/VerifySqlScanConnection/${siteId}`;
        const postBody = {
            credentials: credentials,
            rdc: rdc,
        };
        return lastValueFrom(this.http.post(url, postBody)).then((res) => res);
    }

    saveMSCloudConfig(
        siteId: number,
        assessmentId: number,
        configBody: AzureCredentials,
        testOnly: boolean = false
    ) {
        const url: string =
            this.apiUrl +
            `NdPro/CloudScanConfig/MS/${siteId}/${assessmentId}` +
            (testOnly ? '?testOnly=true' : '');
        return lastValueFrom(this.http.post(url, configBody)).then((res) => res);
    }

    deleteCloudConfig(siteId: number, assessmentId: number, cloudType: CloudTypes) {
        const url: string =
            this.apiUrl +
            `NdPro/CloudScanConfig/${cloudType.toUpperCase()}/${siteId}/${assessmentId}`;
        return lastValueFrom(this.http.delete(url)).then((res) => res);
    }

    hasCloudConfig(siteId: number, assessmentId: number, cloudType: CloudTypes): Promise<boolean> {
        const url: string =
            this.apiUrl +
            `NdPro/CloudScanConfig/${cloudType.toUpperCase()}/${siteId}/${assessmentId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    startCloudScan(
        siteId: number,
        assessmentId: number,
        cloudType: CloudTypes,
        token: AuthenticationResult = null
    ) {
        const url: string =
            this.apiUrl +
            `NdPro/StartCloudScan/${cloudType.toUpperCase()}/${siteId}/${assessmentId}`;
        const postBody = {
            token: token,
        };
        return lastValueFrom(this.http.post(url, postBody)).then((res) => res);
    }

    cancelCloudScan(siteId: number, assessmentId: number, cloudType: CloudTypes) {
        const url: string =
            this.apiUrl +
            `NdPro/CancelCloudScan/${cloudType.toUpperCase()}/${siteId}/${assessmentId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getCloudScan(siteId: number, assessmentId: number, cloudType: CloudTypes) {
        const url: string =
            this.apiUrl + `NdPro/GetCloudScan/${cloudType.toUpperCase()}/${siteId}/${assessmentId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    GetMsCloudScanItemsCount(siteId: number, assessmentId: number) {
        const url: string =
            this.apiUrl + `NdPro/GetMsCloudScanItemsCount/${siteId}/${assessmentId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    async getAssessmentIdForDataExplorer(site: Site, dataExplorerType: string): Promise<number> {
        return await this.getAssessmentIdForDataExplorerAndSiteId(site.Id, dataExplorerType);
    }

    async getAssessmentIdForDataExplorerAndSiteId(
        siteId: number,
        dataExplorerType: string
    ): Promise<number> {
        let assessmentId = null;
        if (dataExplorerType === 'ndp-assessment') {
            await this.getAssessments(siteId).then((assessments) => {
                assessmentId = assessments.find((p) => p.IsActive)?.Id;
            });
        } else if (this.dataExplorerType === 'ndp-automation') {
            assessmentId = -1;
        }
        return assessmentId;
    }

    getRDCs(siteId: number) {
        const url: string = this.apiUrl + `NdPro/getRDCs/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getAutomationJobs(
        siteId: number,
        type: 'SCAN' | 'REPORT' | 'EXPORT'
    ): Promise<AutomationJob[]> {
        const url: string = this.apiUrl + `NdPro/AutomationJobs/${siteId}?type=${type}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }
    getAutomationJob(siteId: number, id: number): Promise<AutomationJob> {
        const url: string = this.apiUrl + `NdPro/AutomationJobs/${siteId}?id=${id}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }
    upsertAutomationJob(siteId: number, automationJob: AutomationJob): Promise<AutomationJob> {
        const url: string = this.apiUrl + `NdPro/AutomationJobs/${siteId}`;
        return lastValueFrom(this.http.post(url, automationJob)).then((res) => res);
    }
    deleteAutomationJob(siteId: number, id: number) {
        const url: string = this.apiUrl + `NdPro/AutomationJobs/${siteId}?id=${id}`;
        return lastValueFrom(this.http.delete(url)).then((res) => res);
    }
    runAutomationJobNow(siteId: number, id: number): Promise<AutomationJob> {
        const url: string = this.apiUrl + `NdPro/AutomationJobs/RunNow/${siteId}/${id}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    checkExchangeScanCredentials(exchangeScanCredentials: ExchangeScanCredentials) {
        const url: string = `${this.apiUrl}NdPro/ExchangeScan/Credentials/Validation`;
        return lastValueFrom(this.http.post(url, exchangeScanCredentials)).then((res) => res);
    }

    getIssueExceptions(siteId: number, assessmentId: number): Promise<Survey> {
        const url: string = this.apiUrl + `NdPro/IssueExceptions/${siteId}/${assessmentId}`;
        return lastValueFrom<Survey>(this.http.get(url)).then((res) => res as Survey);
    }

    setIssueExceptions(siteId: number, req: InformRequest): Promise<any> {
        const url: string = this.apiUrl + `NdPro/IssueExceptions/${siteId}`;
        return this.http
            .post(url, req, { observe: 'response' })
            .toPromise()
            .then((res) => res.status == 200);
    }

    getReportAugments(): Promise<ReportAugmentRow[]> {
        const url: string = this.apiUrl + 'NdPro/AugmentReports';
        return lastValueFrom(this.http.get(url)).then((res) => res as ReportAugmentRow[]);
    }

    addReportAugment(augment: ReportAugmentRow) {
        const url: string = this.apiUrl + 'NdPro/AugmentReports';
        return lastValueFrom(this.http.post(url, augment)).then((res) => res);
    }

    deleteReportAugment(augment: ReportAugmentRow) {
        const url: string =
            this.apiUrl +
            `NdPro/AugmentReports?serviceName=${augment.ServiceName}&productName=${augment.ProductName}`;
        return lastValueFrom(this.http.delete(url)).then((res) => res);
    }

    modifyReportAugment(
        augment: ReportAugmentRow,
        originalProduct: string,
        originalService: string
    ) {
        const url: string =
            this.apiUrl +
            `NdPro/AugmentReports?serviceName=${originalService}&productName=${originalProduct}`;
        return lastValueFrom(this.http.put(url, augment)).then((res) => res);
    }

    getAutomationSettings(siteId: number): Promise<AutomationSettings> {
        const url: string = this.apiUrl + `NdPro/AutomationSettings/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    setAutomationSettings(siteId: number, settings: AutomationSettings) {
        const url: string = this.apiUrl + `NdPro/AutomationSettings/${siteId}`;
        return lastValueFrom(this.http.post(url, settings)).then((res) => res);
    }

    getAssessmentScanDataIssues(
        siteId: number,
        assessmentId: number
    ): Promise<AssessmentScanDataIssue[]> {
        const url: string =
            this.apiUrl + `NdPro/Site/${siteId}/Assessment/ScanData/${assessmentId}/Issues`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getAssessmentScanDataConfigurations(
        siteId: number,
        assessmentId: number
    ): Promise<AssessmentScanDataConfigurations[]> {
        const url: string =
            this.apiUrl + `NdPro/Site/${siteId}/Assessment/ScanData/${assessmentId}/Configurations`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getAssessmentScanDataContacts(
        siteId: number,
        assessmentId: number
    ): Promise<AssessmentScanDataContacts[]> {
        const url: string =
            this.apiUrl + `NdPro/Site/${siteId}/Assessment/ScanData/${assessmentId}/Contacts`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    assessmentRegisterPsaExportQueue(
        siteId: number,
        assessmentId: number,
        exportType: string,
        jsonData: object
    ): Promise<AssessmentScanDataContacts[]> {
        const url: string =
            this.apiUrl +
            `NdPro/Site/${siteId}/Assessment/${assessmentId}/${exportType}/PsaQueueRegistration`;
        return lastValueFrom(this.http.post(url, jsonData)).then((res) => res);
    }

    setDashboardData(dbtype: string, obj: string, data: unknown) {
        let dbInfo = { ...this.dashboardData.getValue() };
        if (dbInfo[dbtype] == null) dbInfo[dbtype] = {};
        dbInfo[dbtype][obj] = data;

        this.dashboardData.next(dbInfo);
    }

    getDashboardData(): Observable<DashboardData> {
        return this.dashboardData.asObservable();
    }

    setDashboardState(dbtype: string, state: string) {
        let dbInfo = { ...this.dashboardData.getValue() };
        if (dbInfo[dbtype] == null) {
            dbInfo[dbtype] = {};
        }
        dbInfo[dbtype].state = state;
        this.dashboardData.next(dbInfo);
    }

    resetDashboard(): void {
        this.dashboardData.next({
            Assessment: null,
            Automation: null,
        });
    }

    getInformTemplates(): Promise<InForm[]> {
        const url: string = this.apiUrl + `NDPro/GetInformTemplates`;
        return lastValueFrom(this.http.get(url)).then((res) =>
            res.map((r) => ({
                id: r.Id,
                name: r.Title,
                instruction: r.Description,
                category: r.Tags,
                lastModified: r.LastModified,
                form: r.Form,
            }))
        );
    }

    getDattoContinuityScan(siteId: number, assessmentId: number) {
        const url: string =
            this.apiUrl +
            `NdPro/DattoContinuityScan/${siteId}/${
                assessmentId == -1 ? 'Automation' : assessmentId
            }`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    startDattoContinuityIDScan(siteId: number, organizationName: string, assessmentId: number) {
        const url: string =
            this.apiUrl + `NdPro/StartDattoContinuityScan/${siteId}/${assessmentId}`;
        return lastValueFrom(this.http.post(url, { organizationName: organizationName })).then(
            (res) => res
        );
    }

    correctScheduleHHMMToCron(schedule: Schedule, task: AutomationJob): string {
        let rValue = schedule.TaskRepeatTimeHHMM;
        if (
            !schedule.TaskRepeatTimeHHMM &&
            task.ScheduleCron &&
            task.NextRun &&
            !(
                !schedule.TaskRepeatInterval ||
                schedule.TaskRepeatInterval.length == 0 ||
                schedule.TaskRepeatInterval == 'Once'
            )
        ) {
            let nextRun = new Date(
                this.accountDateTime.transform(task.NextRun, ['ignoreFormat', 'addTime'])
            );
            let hours = nextRun.getHours();
            let minutes = nextRun.getMinutes();
            rValue =
                (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
        }
        return rValue;
    }

    setCurrencyFormatter(site: Site) {
        this.siteSettingService.getReportPreferences(site.Id).then((params) => {
            const currency: string =
                params.find((pref) => pref.Name === 'T_CURRENCY')?.Value || 'USD';
            const language: string =
                params.find((pref) => pref.Name === 'T_LANGUAGE')?.Value || 'en-US';
            const currencyFormat = currencyFormatterCode[currency];
            this.currencyFormatter = new Intl.NumberFormat(language, {
                style: 'currency',
                currency: currencyFormat,
            });
            this.currencyFormatterNoCents = new Intl.NumberFormat(language, {
                style: 'currency',
                currency: currencyFormat,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });
        });
    }
}
