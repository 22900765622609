import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'siteDecode',
})
export class SiteDecodePipe implements PipeTransform {
    transform(value: string): string {
        let regex = /%26/g;

        return value.replace(regex, '&');
    }
}
