import { inject, Pipe, type PipeTransform } from '@angular/core';
import { DateService } from './date.service';

@Pipe({
    name: 'relativeTime',
    standalone: true,
})
export class RelativeTimePipe implements PipeTransform {
    private dateService = inject(DateService);

    transform(date: Date | string, ...args: unknown[]): string {
        date = typeof date === 'string' ? new Date(date) : date;

        return this.dateService.relativeTime(date) + ' ago';
    }
}
