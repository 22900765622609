import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { Notification, NotificationType } from '../notification/notification.model';
import { ANIMATE_IN_DURATION, ANIMATE_OUT_DURATION } from './banner.config';

type BannerType = 'error' | 'info' | 'warning' | 'success' | 'download';

@Component({
    selector: 'itc-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    animations: [
        trigger('bannerState', [
            state('visible', style({ opacity: 1 })),
            state('hidden', style({ opacity: 0, display: 'none' })),
            transition('void => visible', animate(ANIMATE_IN_DURATION)),
            transition('visible => hidden', animate(ANIMATE_OUT_DURATION)),
        ]),
    ],
})
export class ItcBannerComponent implements OnInit {
    @Input() className: string;
    @Input() title: string;
    @Input() type: BannerType = 'info'; // error, info, warning, success, download
    @Input() closable = false;

    // we pass in notification from the notification service to be able to remove it, normally don't use this.
    @Input() set notification(notification: Notification) {
        this.title = notification.title;
        switch (notification.type) {
            case NotificationType.Success:
                this.type = 'success';
                break;
            case NotificationType.Error:
                this.type = 'error';
                break;
            case NotificationType.Info:
                this.type = 'info';
                break;
            case NotificationType.Warning:
                this.type = 'warning';
                break;
            case NotificationType.Download:
                this.type = 'download';
                break;
        }
        this.emitVal = notification;
    }

    @Output() onClose = new EventEmitter();

    state: string;
    emitVal: any = 'Banner Closed';

    constructor() {}

    ngOnInit() {
        this.state = 'visible';
    }

    ngOnChanges() {
        // if we change one of the inputs let's display it again just in case we need this.
        this.state = 'visible';
    }

    closeBanner() {
        this.state = 'hidden';
        console.log('emitting Banner Close event');
        this.onClose.emit(this.emitVal);
    }
}
