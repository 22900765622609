<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<div #wizarddiv>
    <!-- Scan type, page 0 -->
    <sds-loader [complete]="loadingComplete">
        <div class="wizard-div">
            <h2 class="sectionTitle dashed">Scan Type</h2>

            <itc-banner type="warning" *ngIf="!installed || !checkedin">
                The appliance is not installed or has not been checked in.
            </itc-banner>

            <div class="smallestContainer">
                <div class="html ui attached segment siteSettingContainer">
                    <div class="ui top attached label">
                        What best describes this type of network? If the network is a hybrid
                        environment of Active Directory and standalone computers or workgroups,
                        choose Active Directory domain.
                    </div>
                    <div class="ui form" style="margin-top: 2.5rem !important">
                        <div class="field">
                            <div>
                                <itc-radio
                                    name="radioActiveDirectory"
                                    value="activedirectory"
                                    [(ngModel)]="scanType"
                                    [disabled]="!installed || !checkedin">
                                    Active Directory domain
                                </itc-radio>
                            </div>
                        </div>
                        <div class="inline field">
                            <div>
                                <itc-radio
                                    name="radioActiveDirectory"
                                    value="workgroup"
                                    [(ngModel)]="scanType"
                                    [disabled]="!installed || !checkedin">
                                    Workgroup
                                </itc-radio>
                                <br />
                            </div>
                        </div>
                        <div class="inline field" *ngIf="isAzureAuditGuru()">
                            <div>
                                <itc-radio
                                    name="radioActiveDirectory"
                                    id="radioMergeOption-workgroup-azure"
                                    value="workgroup-azure"
                                    [(ngModel)]="scanType"
                                    [disabled]="!installed || !checkedin"
                                    label="Cloud-based Azure Active Directory (AD) domain with on premise computer network"></itc-radio>
                                <br />
                            </div>
                        </div>
                        <div class="field" *ngIf="isAzureAuditGuru()">
                            <div>
                                <itc-radio
                                    name="radioActiveDirectory"
                                    id="radioMergeOption-domain-azure"
                                    value="activedirectory-azure"
                                    [(ngModel)]="scanType"
                                    [disabled]="!installed || !checkedin">
                                    Cloud-based Azure Active Directory (AD) domain with on premise
                                    Domain Controller
                                </itc-radio>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buttonContainer">
                    <itc-button
                        label="Next Page"
                        icon="fa-arrow-right"
                        color="primary"
                        [disabled]="!scanType || !installed || !checkedin"
                        (onclick)="nextPageScanType()"></itc-button>
                </div>
            </div>
        </div>
    </sds-loader>
    <!-- End Scan Type -->
    <div #wizarddiv>
        <!-- Scan Hosts page 1 -->
        <div class="wizard-div">
            <h2 class="sectionTitle dashed">Scan Hosts</h2>

            <div class="smallestContainer">
                <div class="html ui attached segment siteSettingContainer">
                    <div class="ui top attached label">
                        The values on this page will affect all tasks that require scan hosts for
                        this Appliance
                    </div>

                    <div class="ui form" style="margin-bottom: 20px">
                        <table class="ui table alignment">
                            <tr>
                                <td class="collapsing">
                                    <label for="textboxScanhostUsername">Username:</label>
                                </td>
                                <td>
                                    <div class="field">
                                        <input
                                            type="text"
                                            id="textboxScanHostUsername"
                                            name="textboxScanhostUsername"
                                            placeholder="scanhost username"
                                            [(ngModel)]="scanhostusername"
                                            autocomplete="off" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="collapsing">
                                    <label for="textboxScanhostPassword">Password:</label>
                                </td>
                                <td>
                                    <div class="inline field" style="display: block">
                                        <input
                                            type="password"
                                            id="textboxScanhostPassword"
                                            name="textboxScanhostPassword"
                                            placeholder="scanhost password"
                                            [(ngModel)]="scanhostpassword"
                                            autocomplete="off" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="collapsing">
                                    <label for="textboxScanhostDomain">Domain:</label>
                                </td>
                                <td>
                                    <div class="inline field" style="display: block">
                                        <input
                                            type="text"
                                            id="textboxScanhostDomain"
                                            name="textboxScanhostDomain"
                                            placeholder="scanhost domain"
                                            [(ngModel)]="scanhostdomain"
                                            autocomplete="off" />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <wj-flex-grid
                        #flex
                        [itemsSource]="scanhosts"
                        style="width: 100%"
                        (rowAdded)="onRowAdded($event)">
                        <wj-flex-grid-column
                            [header]="' '"
                            [binding]="'icon'"></wj-flex-grid-column>
                        <wj-flex-grid-column
                            [header]="'Scan Host'"
                            [binding]="'scanhost'"></wj-flex-grid-column>
                    </wj-flex-grid>
                    <br />
                    <br />
                    <itc-button
                        label="Test Scan Hosts"
                        type="color"
                        color="grey"
                        (onclick)="testScanHosts()"></itc-button>
                </div>
                <div class="buttonContainer">
                    <itc-button
                        label="Previous Page"
                        type="secondary"
                        icon="fa-arrow-left"
                        (onclick)="previousPage()"></itc-button>
                    <itc-button
                        label="Next Page"
                        type="primary"
                        icon="fa-arrow-right"
                        (onclick)="nextPage()"></itc-button>
                </div>
            </div>
        </div>
        <!-- END Scan Hosts -->
        <!-- Merge Options page 2 -->
        <div class="wizard-div">
            <h2 class="sectionTitle dashed">Merge Option</h2>

            <div class="smallestContainer">
                <div class="html ui attached segment siteSettingContainer">
                    <div class="ui top attached label">
                        How do you want to treat computers that are not associated with active
                        directory?
                    </div>
                    <div class="ui form">
                        <div class="field">
                            <div>
                                <itc-radio
                                    name="radioMergeOption"
                                    id="radioMergeOption-primary"
                                    value="primary"
                                    [(ngModel)]="mergeOption">
                                    Treat them as part of the primary domain
                                </itc-radio>
                            </div>
                        </div>
                        <div class="inline field">
                            <div>
                                <itc-radio
                                    name="radioMergeOption"
                                    id="radioMergeOption-specified"
                                    value="specified"
                                    [(ngModel)]="mergeOption">
                                    Treat them as part of the specified workgroup
                                </itc-radio>
                                <input
                                    type="text"
                                    class="ui input"
                                    name="textboxSpecifiedDomain"
                                    placeholder="Workgroup"
                                    [(ngModel)]="specifiedDomain"
                                    style="display: inline-block" />
                                <br />
                            </div>
                        </div>
                        <div class="inline field" *ngIf="site?.Type == 'standard'">
                            <div>
                                <itc-radio
                                    name="radioMergeOption"
                                    id="radioMergeOption-specified"
                                    value="nondomain"
                                    [(ngModel)]="mergeOption">
                                    Don't treat them as part of a domain
                                </itc-radio>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right floated">
                    <itc-button
                        label="Previous Page"
                        type="secondary"
                        icon="fa-arrow-left"
                        (onclick)="previousPage()"></itc-button>
                    <itc-button
                        label="Next Page"
                        type="primary"
                        icon="fa-arrow-right"
                        (onclick)="nextPage()"></itc-button>
                </div>
            </div>
        </div>
        <!-- END Merge Options -->
        <!-- Active Directory page 3 -->
        <div class="wizard-div">
            <h2 class="sectionTitle dashed">Scan Credentials</h2>

            <div class="smallestContainer">
                <div class="html ui attached segment siteSettingContainer">
                    <div *ngIf="isActiveDirectory()" class="ui top attached label">
                        Please enter a username and password with administrative rights to connect
                        to the local Domain Controller and Active Directory
                        <br />
                        <br />
                    </div>
                    <div *ngIf="isWorkgroup()" class="ui top attached label">
                        Please enter a username and password with administrative rights to connect
                        to the local computers. Additional users and passwords can be added in the
                        Additional Credentials screen.
                        <br />
                        <br />
                    </div>
                    <br />
                    <br />
                    <br />

                    <div *ngIf="isActiveDirectory()">
                        Please enter the Fully Qualified Domain Name (i.e., corp.myco.com instead of
                        the shortened name - MYCO)
                        <br />
                        <br />
                    </div>

                    <div class="ui form">
                        <table class="ui table alignment">
                            <tr>
                                <td class="collapsing">
                                    <label for="textboxUsername">
                                        Username
                                        <span *ngIf="isActiveDirectory()">(domain\user)</span>
                                        :
                                    </label>
                                </td>
                                <td>
                                    <input
                                        #textboxUsername
                                        type="text"
                                        name="textboxUsername"
                                        id="textboxUsername"
                                        placeholder="username"
                                        [(ngModel)]="username" />
                                </td>
                            </tr>
                            <tr>
                                <td class="collapsing">
                                    <label for="textboxPassword">Password:</label>
                                </td>
                                <td>
                                    <input
                                        type="password"
                                        name="textboxPassword"
                                        placeholder="password"
                                        id="textboxPassword"
                                        [(ngModel)]="password" />
                                </td>
                            </tr>
                            <tr *ngIf="isActiveDirectory()">
                                <td class="collapsing">
                                    <label for="textboxDomainController">Domain Controller:</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="textboxDomainController"
                                        id="textboxDomainController"
                                        placeholder="domain controller"
                                        [(ngModel)]="domainController" />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="right floated">
                    <itc-button
                        label="Previous Page"
                        type="secondary"
                        icon="fa-arrow-left"
                        (onclick)="previousPage()"></itc-button>
                    <itc-button
                        *ngIf="isActiveDirectory()"
                        label="Next Page"
                        type="primary"
                        icon="fa-arrow-right"
                        [disabled]="!domainController"
                        (onclick)="nextPageActiveDirectory()"></itc-button>
                    <itc-button
                        *ngIf="isWorkgroup()"
                        label="Next Page"
                        type="primary"
                        icon="fa-arrow-right"
                        (onclick)="nextPage()"></itc-button>
                </div>
            </div>
        </div>
        <!-- END Active Directory -->
        <!-- Local Domains page 4 -->
        <div class="wizard-div">
            <h2 class="sectionTitle dashed">Local Domains</h2>

            <div class="smallestContainer">
                <div class="html ui attached segment siteSettingContainer">
                    <div class="ui top attached label">
                        Below is a list of the detected domains in the current forest of Active
                        Directory
                    </div>
                    <div class="ui form">
                        <div class="field">
                            <div>
                                <itc-radio
                                    name="radioGatherOption"
                                    id="radioGatherOption-gatherAll"
                                    value="gatherAll"
                                    [(ngModel)]="gatherOption">
                                    Gather Information for ALL the domains detected.
                                </itc-radio>
                            </div>
                        </div>
                        <div class="field">
                            <div>
                                <itc-radio
                                    name="radioGatherOption"
                                    id="radioGatherOption-gatherSelected"
                                    value="gatherSelected"
                                    [(ngModel)]="gatherOption">
                                    Gather Information for only the Domains and OUs selected below.
                                </itc-radio>
                            </div>
                        </div>
                    </div>

                    <wj-tree-view
                        #ouTree
                        [itemsSource]="ouTreeItems"
                        [showCheckboxes]="true"
                        [isDisabled]="gatherOption == 'gatherAll'"></wj-tree-view>
                </div>
                <div class="right floated">
                    <itc-button
                        label="Previous Page"
                        type="secondary"
                        icon="fa-arrow-left"
                        (onclick)="previousPage()"></itc-button>
                    <itc-button
                        label="Next Page"
                        type="primary"
                        icon="fa-arrow-right"
                        (onclick)="nextPage()"></itc-button>
                </div>
            </div>
        </div>
        <!-- END Local Domains -->
        <!-- Additional Credentials page 5 -->
        <div class="wizard-div">
            <h2 class="sectionTitle dashed">Additional Credentials</h2>

            <div class="smallestContainer">
                <div class="html ui attached segment siteSettingContainer">
                    <div class="ui top attached label">
                        Network scan credentials are required to perform remote Windows data
                        collection via WMI and Remote Registry. Use this screen to optionally add
                        additional credentials to be used during the scan. Calls using the default
                        credentials will always be attempted first.
                        <br />
                    </div>
                    <br />
                    <br />
                    <br />
                    <div class="ui form">
                        Network Scan Credentials
                        <br />
                        <table class="ui table alignment">
                            <tr>
                                <td class="collapsing">
                                    <label for="textboxAdditionalUsername">Username:</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="textboxAdditionalUsername"
                                        id="textboxAdditionalUsername"
                                        placeholder="username"
                                        [(ngModel)]="additionalUsername" />
                                </td>
                            </tr>
                            <tr>
                                <td class="collapsing">
                                    <label for="textboxAdditionalPassword">Password:</label>
                                </td>
                                <td>
                                    <input
                                        type="password"
                                        name="textboxAdditionalPassword"
                                        id="textboxAdditionalPassword"
                                        placeholder="password"
                                        [(ngModel)]="additionalPassword" />
                                </td>
                            </tr>
                        </table>
                        <itc-button
                            label="Add"
                            type="primary"
                            icon="fa-plus"
                            (onclick)="addAdditionalCredentials()"></itc-button>
                        <itc-button
                            label="Remove Selected Entry"
                            type="color"
                            color="grey"
                            (onclick)="
                                removeSelectedUser(selectAdditionalUsers.value)
                            "></itc-button>
                        <br />
                        <br />
                        <label>{{ additionalCredentialListBoxLabelText }}</label>
                        <br />
                        <select #selectAdditionalUsers size="6" style="width: 100%">
                            <option *ngFor="let au of additionalUsers">{{ au.username }}</option>
                        </select>
                    </div>
                </div>
                <div class="right floated">
                    <itc-button
                        *ngIf="isActiveDirectory()"
                        label="Previous Page"
                        type="secondary"
                        icon="fa-arrow-left"
                        (onclick)="previousPage()"></itc-button>
                    <itc-button
                        *ngIf="isWorkgroup()"
                        label="Previous Page"
                        type="secondary"
                        icon="fa-arrow-left"
                        (onclick)="previousPages(2)"></itc-button>
                    <itc-button
                        label="Next Page"
                        type="primary"
                        icon="fa-arrow-right"
                        (onclick)="nextPage()"></itc-button>
                </div>
            </div>
        </div>
        <!-- END Additional Credentials -->
        <!-- IP Ranges page 6 -->
        <div class="wizard-div">
            <h2 class="sectionTitle dashed">IP Ranges</h2>

            <div class="smallestContainer">
                <div class="html ui attached segment siteSettingContainer">
                    <div class="ui top attached label">
                        Auto-Detected IP Ranges on Remote Appliance
                    </div>
                    <select id="selectDetectedRemoteIps" size="6" style="width: 100%">
                        <option *ngFor="let ipRange of ipRanges">{{ ipRange }}</option>
                    </select>
                </div>
                <div class="html ui attached segment" style="height: auto; overflow: hidden">
                    <div class="ui top attached label">IP Ranges to Scan</div>
                    <br />
                    <br />
                    Example IP Range Format: 192.168.0.0-192.168.0.255
                    <br />
                    <div class="ui action input" style="margin: 10px 0; width: 376px">
                        <input
                            type="text"
                            id="textboxSingleIpOrRange"
                            [(ngModel)]="ipRangeTextBoxValue"
                            placeholder="Single IP or IP Range" />
                        <itc-button icon="fa-plus" type="primary" (onclick)="addIpRange()">
                            Add
                        </itc-button>
                    </div>
                    <div style="width: 100%">
                        <div style="float: left; margin-right: 16px">
                            <select #selectIpRangesToScan size="8" style="width: 360px">
                                <option *ngFor="let ipRange of selectedIpRanges">
                                    {{ ipRange }}
                                </option>
                            </select>
                        </div>
                        <div>
                            <itc-button
                                label="Exclude IPs"
                                type="color"
                                color="grey"
                                style="display: block; margin-bottom: 8px; margin-left: 0px"
                                (onclick)="excludeIps()"></itc-button>
                            <itc-button
                                label="Reset to Auto-Detected"
                                type="color"
                                color="grey"
                                style="display: block; margin-bottom: 8px; margin-left: 0px"
                                (onclick)="resetSelectedIpRangesToAutoDetected()"></itc-button>
                            <itc-button
                                label="Remove Selected Entry"
                                type="color"
                                color="grey"
                                style="display: block; margin-bottom: 8px; margin-left: 0px"
                                (onclick)="
                                    removeSelectedIpRange(selectIpRangesToScan.value)
                                "></itc-button>
                            <itc-button
                                label="Clear All Entries"
                                type="color"
                                color="grey"
                                style="display: block; margin-bottom: 8px; margin-left: 0px"
                                (onclick)="clearSelectedIpRanges()"></itc-button>
                        </div>
                    </div>
                    <div *ngIf="excludedSelectedIpRanges.length > 0">
                        Excluded IP Ranges: {{ excludedSelectedIpRanges.length }}
                    </div>
                </div>
                <br />
                <div class="right floated">
                    <itc-button
                        label="Previous Page"
                        type="secondary"
                        icon="fa-arrow-left"
                        (onclick)="previousPage()"></itc-button>
                    <itc-button
                        label="Next Page"
                        type="primary"
                        icon="fa-arrow-right"
                        (onclick)="nextPageIpRanges()"></itc-button>
                </div>
            </div>
        </div>
        <!-- END IP Ranges -->
        <!-- SNMP Information page 7 -->
        <div class="wizard-div">
            <h2 class="sectionTitle dashed">SNMP Information</h2>

            <div class="smallestContainer">
                <div class="html ui attached segment siteSettingContainer">
                    <div class="ui top attached label">
                        SNMP community strings are used to try to determine information about
                        devices detected during the IP Range scan. Enter any additional community
                        strings used on this network.
                    </div>
                    <br />
                    <br />
                    <div class="ui action input" style="margin: 10px 0; width: 470px">
                        <input
                            type="text"
                            id="textboxReadCommunityString"
                            [(ngModel)]="communityStringTextBoxValue"
                            placeholder="Community String" />
                        <itc-button
                            label="Add"
                            type="primary"
                            icon="fa-plus"
                            (onclick)="addCommunityString()"></itc-button>
                    </div>
                    <br />
                    <select
                        id="selectCommunityStrings"
                        size="6"
                        style="width: 100%; margin-bottom: 8px">
                        <option *ngFor="let communityString of communityStrings">
                            {{ communityString }}
                        </option>
                    </select>
                    <br />
                    <itc-button
                        label="Reset to Default"
                        type="color"
                        color="grey"
                        (onclick)="resetCommunityStrings()"></itc-button>
                    <itc-button
                        label="Clear All Entries"
                        type="color"
                        color="grey"
                        (onclick)="clearCommunityStrings()"></itc-button>
                    <br />
                    <br />
                    <strong>Advanced SNMP Options</strong>
                    <br />
                    SNMP Timeout (seconds):
                    <div class="ui action input" style="width: 280px">
                        <input
                            type="text"
                            id="textboxSnmpTimeoutSeconds"
                            [(ngModel)]="snmpTimeout" />
                        <itc-button
                            label="Use Default"
                            type="color"
                            color="grey"
                            (onclick)="resetSnmpTimeout()"
                            style="min-width: 120px"></itc-button>
                    </div>
                    <div>
                        <itc-checkbox
                            id="attemptSnmpAgainNonPingable"
                            [(ngModel)]="attemptSnmpAgainNonPingable">
                            Attempt SNMP against non-pingable devices (slower but more accurate)
                        </itc-checkbox>
                    </div>
                </div>
                <div class="right floated">
                    <itc-button
                        label="Previous Page"
                        icon="fa-arrow-left"
                        type="secondary"
                        (onclick)="previousPage()"></itc-button>
                    <itc-button
                        label="Next Page"
                        icon="fa-arrow-right"
                        type="primary"
                        (onclick)="nextPage()"></itc-button>
                </div>
            </div>
        </div>
        <!-- END SNMP Information -->
        <!-- External Vulnerability Scan page 8 -->
        <div class="wizard-div">
            <h2 class="sectionTitle dashed">External Vulnerability Scan</h2>

            <div class="smallestContainer">
                <div
                    class="html ui attached segment siteSettingContainer"
                    style="height: auto; overflow: hidden">
                    <div class="ui top attached label">You may add up to 64 addresses.</div>
                    <br />
                    <br />
                    <div class="ui action input" style="width: 400px">
                        <input
                            type="text"
                            id="textboxExternalSingleIpOrRange"
                            [(ngModel)]="externalIpRangeTextBoxValue"
                            placeholder="Single IP" />
                        <itc-button icon="fa-plus" type="primary" (onclick)="addExternalIp()">
                            Add
                        </itc-button>
                    </div>
                    <br />
                    <br />
                    <div style="width: 100%">
                        <div style="float: left">
                            <select
                                #selectExternalIpRangesToScan
                                size="8"
                                style="width: 400px; padding: 5px">
                                <option
                                    *ngFor="let ipRange of selectedExternalIpRanges"
                                    [value]="ipRange">
                                    {{ ipRange }}
                                </option>
                            </select>
                        </div>
                        <div style="float: right">
                            <itc-button
                                label="Remove Selected"
                                type="color"
                                color="grey"
                                style="display: block; margin-bottom: 8px"
                                (onclick)="
                                    removeSelectedExternalIp(selectExternalIpRangesToScan.value)
                                "></itc-button>
                            <itc-button
                                label="Remove All"
                                type="color"
                                color="grey"
                                style="display: block; margin-bottom: 8px"
                                (onclick)="removeAllExternalIps()"></itc-button>
                        </div>
                    </div>
                </div>
                <div class="right floated">
                    <itc-button
                        label="Previous Page"
                        icon="fa-arrow-left"
                        type="secondary"
                        (onclick)="previousPage()"></itc-button>
                    <itc-button
                        label="Next Page"
                        icon="fa-arrow-right"
                        type="primary"
                        (onclick)="nextPageExternalVuln()"></itc-button>
                </div>
            </div>
        </div>
        <!-- END External Vulnerability Scan -->
        <!-- File Scanner page 9 -->
        <div class="wizard-div">
            <h2 class="sectionTitle dashed">File Scanner</h2>

            <div class="smallestContainer">
                <div
                    class="html ui attached segment siteSettingContainer"
                    style="height: auto; overflow: hidden; padding-bottom: 10px">
                    <div>
                        Perform file scan on:
                        <select
                            id="selectFileScanDay"
                            size="1"
                            style="width: 100%"
                            [ngModel]="selectedFileScanDay"
                            (ngModelChange)="onSelectFileScanDayChange($event)">
                            <option *ngFor="let fileScanDay of fileScanDays" [value]="fileScanDay">
                                {{ fileScanDay }}
                            </option>
                        </select>
                    </div>
                    <div>
                        NOTE: File scanning can cause a temporary increase in resource utilization.
                    </div>
                    <br />
                    <div>
                        Scan Types:
                        <br />
                        <div>
                            <itc-checkbox
                                id="fileScanEphi"
                                [(ngModel)]="fileScanEphi"
                                [disabled]="selectedFileScanDay == '<never>'">
                                ePHI (HIPAA)
                            </itc-checkbox>
                        </div>
                        <br />
                        <div>
                            <itc-checkbox
                                id="fileScanCardholder"
                                [(ngModel)]="fileScanCardholder"
                                [disabled]="selectedFileScanDay == '<never>'">
                                Cardholder Data (PCI)
                            </itc-checkbox>
                        </div>
                        <br />
                        <div>
                            <itc-checkbox
                                id="fileScanPii"
                                [(ngModel)]="fileScanPii"
                                [disabled]="selectedFileScanDay == '<never>'">
                                Personally Identifiable Information (PII)
                            </itc-checkbox>
                        </div>
                        <br />
                    </div>
                </div>
                <div class="right floated">
                    <itc-button
                        label="Previous Page"
                        icon="fa-arrow-left"
                        type="secondary"
                        (onclick)="previousPage()"></itc-button>
                    <itc-button
                        label="Next Page"
                        icon="fa-arrow-right"
                        type="primary"
                        (onclick)="nextPage()"></itc-button>
                </div>
            </div>
        </div>
        <!-- END File Scanner -->
        <!-- VMWare page 10 -->
        <div class="wizard-div">
            <h2 class="sectionTitle dashed">VMware (Optional)</h2>

            <div class="smallestContainer">
                <div
                    class="html ui attached segment siteSettingContainer"
                    style="height: auto; overflow: hidden; padding-bottom: 10px">
                    <div class="ui top attached label">
                        VMware credentials are required for discovery of VMware hosts. Enter the
                        VMware host server DNS name or IP address along with VMware login
                        credentials. If the server uses a non-standard administrative port, specify
                        the port in the hostname filed in the format "hostname:port".
                    </div>
                    <table
                        style="border-collapse: collapse; width: 100%; margin-top: 60px !important">
                        <tr style="width: 50%">
                            <td>
                                <div class="ui input">
                                    <input
                                        type="text"
                                        id="textboxVmwareHostname"
                                        [(ngModel)]="vmwareHostnameOrIp"
                                        placeholder="Hostname or IP Address" />
                                </div>
                            </td>
                            <td>
                                <div class="ui input">
                                    <input
                                        type="text"
                                        id="textboxVmwareUsername"
                                        [(ngModel)]="vmwareUsername"
                                        placeholder="Username" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td></td>

                            <td>
                                <div class="ui input">
                                    <input
                                        type="password"
                                        id="textboxVmwarePassword"
                                        [(ngModel)]="vmwarePassword"
                                        placeholder="Password" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td></td>

                            <td>
                                <itc-button
                                    label="Add VMware Server"
                                    type="primary"
                                    style="display: block; margin-bottom: 8px; margin-top: 5px"
                                    (onclick)="onAddVmwareServerClick()"></itc-button>
                            </td>
                        </tr>
                    </table>
                    <br />
                    <select id="selectVmwareCredentials" size="6" style="width: 100%">
                        <option *ngFor="let vmwareCredential of vmwareCredentials">
                            {{ vmwareCredential.Hostname }} ({{ vmwareCredential.Username }})
                        </option>
                    </select>
                    <br />
                    <itc-button
                        label="Clear All Entries"
                        type="color"
                        color="grey"
                        style="display: block; margin-bottom: 8px"
                        (onclick)="onClearallEntriesClick()"></itc-button>
                </div>
                <div class="right floated">
                    <itc-button
                        label="Previous Page"
                        icon="fa-arrow-left"
                        type="secondary"
                        (onclick)="previousPage()"></itc-button>
                    <itc-button
                        label="Next Page"
                        icon="fa-arrow-right"
                        type="primary"
                        (onclick)="nextPage()"></itc-button>
                </div>
            </div>
        </div>
        <!-- END VMWare -->
        <!-- Unitrends Backup page 11 -->
        <div class="wizard-div">
            <h2 class="sectionTitle dashed">Unitrends Backup</h2>

            <div class="smallestContainer">
                <div
                    class="html ui attached segment siteSettingContainer"
                    style="height: auto; overflow: hidden; padding-bottom: 10px">
                    <div class="ui top attached label">
                        Enter Server and credential information for Unitrends Backup Servers.
                    </div>
                    <br />
                    <div class="ui form">
                        <table class="ui table alignment">
                            <tr>
                                <td class="collapsing">
                                    <label for="textboxUnitrendsServer">Server:</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="textboxUnitrendsServer"
                                        id="textboxUnitrendsServer"
                                        placeholder="Server"
                                        [(ngModel)]="unitrendsHostnameOrIp" />
                                </td>
                            </tr>
                            <tr>
                                <td class="collapsing">
                                    <label for="textboxUnitrendsUsername">Username:</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="textboxUnitrendsUsername"
                                        id="textboxUnitrendsUsername"
                                        placeholder="Username"
                                        [(ngModel)]="unitrendsUsername" />
                                </td>
                            </tr>
                            <tr>
                                <td class="collapsing">
                                    <label for="textboxUnitrendsPassword">Password:</label>
                                </td>
                                <td>
                                    <input
                                        type="password"
                                        name="textboxUnitrendsPassword"
                                        id="textboxUnitrendsPassword"
                                        placeholder="Password"
                                        [(ngModel)]="unitrendsPassword" />
                                </td>
                            </tr>
                        </table>
                        <itc-button
                            label="Add"
                            icon="fa-plus"
                            type="primary"
                            (onclick)="onAddUnitrendsServerClick()"></itc-button>
                        <itc-button
                            label="Remove Selected Entry"
                            type="color"
                            color="Grey"
                            (onclick)="
                                removeSelectedUnitrendsServer(selectUnitrendsCredentials.value)
                            "></itc-button>
                        <itc-button
                            label="Test Connections"
                            type="color"
                            color="grey"
                            (onclick)="testUnitrendsConnections()"></itc-button>
                        <br />
                        <br />
                        <select #selectUnitrendsCredentials size="6" style="width: 100%">
                            <option *ngFor="let unitrendsCredential of unitrendsCredentials">
                                {{ unitrendsCredential.Hostname }} ({{
                                    unitrendsCredential.Username
                                }})
                            </option>
                        </select>
                        <br />
                    </div>
                </div>
                <div class="right floated">
                    <itc-button
                        label="Previous Page"
                        icon="fa-arrow-left"
                        type="secondary"
                        (onclick)="previousPage()"></itc-button>
                    <itc-button
                        label="Next Page"
                        icon="fa-arrow-right"
                        type="primary"
                        (onclick)="nextPage()"></itc-button>
                </div>
            </div>
        </div>
        <!-- END Unitrends Backup -->
        <!-- Upload finished scan to reporter page 12 -->
        <div class="wizard-div">
            <h2 class="sectionTitle dashed">Advanced Options</h2>

            <div class="smallestContainer">
                <div
                    class="html ui attached segment siteSettingContainer"
                    style="height: auto; overflow: hidden; padding-bottom: 10px">
                    <itc-button
                        label="Advanced Options"
                        type="color"
                        color="grey"
                        style="display: block; margin-bottom: 8px"
                        (onclick)="onAdvancedOptionsClick()"></itc-button>
                    <br />
                    <br />

                    Requires a Reporter bound to this site
                    <br />
                    <div>
                        <itc-checkbox
                            id="uploadScanToReporter"
                            [(ngModel)]="uploadScanToReporter"
                            [disabled]="!hasReporter">
                            Upload finished scan to Reporter
                        </itc-checkbox>
                    </div>
                    <br />
                    Server Setting
                    <br />
                    <div>
                        <itc-checkbox
                            id="doNotScanHost"
                            [(ngModel)]="doNotScanHost"
                            [disabled]="applianceInstallType == 'RFT_SS'">
                            Do not scan the host computer
                        </itc-checkbox>
                    </div>
                    <br />
                </div>
                <div class="right floated">
                    <itc-button
                        label="Previous Page"
                        icon="fa-arrow-left"
                        type="secondary"
                        (onclick)="previousPage()"></itc-button>
                    <itc-button
                        label="Next Page"
                        icon="fa-arrow-right"
                        type="primary"
                        (onclick)="nextPage()"></itc-button>
                </div>
            </div>
        </div>
        <!-- END Upload finished scan to reporter -->
        <!-- Confirm Container page 13 -->
        <div class="wizard-div">
            <div class="smallestContainer">
                <div [ngSwitch]="isCompleted">
                    <div *ngSwitchDefault>
                        <h2 class="sectionTitle dashed">Scan Settings Complete</h2>
                    </div>
                    <div class="html ui attached segment siteSettingContainer">
                        <div>
                            Scan settings setup complete. Automated scans can now be scheduled and
                            run.
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                        <div *ngSwitchCase="true">
                            <h4>Thank you! You have completed all the steps.</h4>
                        </div>
                    </div>
                </div>
                <div class="right floated">
                    <itc-button
                        label="Previous Page"
                        icon="fa-arrow-left"
                        type="secondary"
                        (onclick)="previousPage()"></itc-button>
                    <itc-button
                        *ngIf="previousAlertUrl"
                        label="Go back to todo"
                        icon="arrow up"
                        type="primary"
                        (onclick)="previousAlert()"></itc-button>
                </div>
            </div>
        </div>
        <!-- END Confirm Container -->
        <!-- Azure Credentials Container page 14 -->
        <div #wizarddiv>
            <!-- Scan Hosts page 1 -->
            <div class="wizard-div">
                <h2 class="sectionTitle dashed">Enterprise Application Credentials</h2>

                <div class="smallestContainer">
                    <div class="html ui attached segment siteSettingContainer">
                        <div class="ui top attached label" style="min-height: 50px">
                            Enter the Tenant ID, Client ID, and Client Secret Value associated with
                            the Enterprise Application set up to scan your Azure AD Tenant.
                            <a href="#">Click here</a>
                            for documentation on how to setup the required Enterprise Application.
                        </div>
                        <br />
                        <div class="ui form" style="margin-bottom: 20px; margin-top: 30px">
                            <table class="ui table alignment">
                                <tr>
                                    <td class="collapsing">
                                        <label for="textboxAzureTenantId">Tenant ID:</label>
                                    </td>
                                    <td>
                                        <div class="field">
                                            <input
                                                type="text"
                                                id="textboxAzureTenantId"
                                                name="textboxAzureTenantId"
                                                placeholder="Tenant ID"
                                                [(ngModel)]="azuretenantid"
                                                autocomplete="off" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="collapsing">
                                        <label for="textboxAzureClientID">Client ID:</label>
                                    </td>
                                    <td>
                                        <div class="inline field" style="display: block">
                                            <input
                                                type="password"
                                                id="textboxAzureClientID"
                                                name="textboxAzureClientID"
                                                placeholder="Client ID"
                                                [(ngModel)]="azureclientid"
                                                autocomplete="off" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="collapsing">
                                        <label for="textboxAzureSecret">Client Secret Value:</label>
                                    </td>
                                    <td>
                                        <div class="inline field" style="display: block">
                                            <input
                                                type="password"
                                                id="textboxAzureSecret"
                                                name="textboxAzureSecret"
                                                placeholder="Secret"
                                                [(ngModel)]="azuresecret"
                                                autocomplete="off" />
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="buttonContainer">
                        <itc-button
                            label="Previous Page"
                            type="secondary"
                            icon="fa-arrow-left"
                            (onclick)="previousPage()"></itc-button>
                        <itc-button
                            label="Next Page"
                            type="primary"
                            icon="fa-arrow-right"
                            (onclick)="nextPage()"></itc-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Azure Credentials Container -->

        <sm-modal title="Scan Host Response" class="sm" #scanHostResultsModal>
            <modal-content>
                <img *ngIf="isLoading" src="/assets/img/ajax-loader.gif" class="loadingAnim" />
                <label>{{ scanHostResponse }}</label>
            </modal-content>
            <modal-actions>
                <itc-button type="primary" (onclick)="closeScanHostModalAction()">OK</itc-button>
            </modal-actions>
        </sm-modal>

        <sm-modal
            title="Retrieving OUs"
            class="sm"
            [overrideOverflow]="true"
            #getApplianceInfoModal>
            <modal-content>
                <div
                    style="
                        display: flex;
                        min-height: 35px;
                        margin-bottom: 10px;
                        align-items: center;
                        gap: 8px;
                    ">
                    <itc-spinner size="large"></itc-spinner>
                    <label style="margin-bottom: 0">
                        Please wait while the appliance is queried for OU information.
                    </label>
                </div>
            </modal-content>
        </sm-modal>

        <sm-modal title="Retrieving Network Information" class="sm" #getApplianceNetworkModal>
            <modal-content>
                <itc-spinner size="large"></itc-spinner>
                <label>Please wait while the appliance is queried for Network information.</label>
            </modal-content>
        </sm-modal>

        <sm-modal title="System Message" class="sm" #systemMessageModal>
            <modal-content>
                <label>{{ systemMessage }}</label>
            </modal-content>
            <modal-actions>
                <itc-button type="primary" (onclick)="closeSystemMessageModalAction()">
                    OK
                </itc-button>
            </modal-actions>
        </sm-modal>

        <sm-modal title="Confirm" class="sm" #largeIpRangeModal>
            <modal-content>
                <p [innerHTML]="largeIpRangeMessage"></p>
            </modal-content>
            <modal-actions>
                <itc-button type="secondary" (onclick)="closeLargIpRangeAction(false)">
                    Cancel
                </itc-button>
                <itc-button type="primary" (onclick)="closeLargIpRangeAction(true)">OK</itc-button>
            </modal-actions>
        </sm-modal>

        <sm-modal
            title="Estimated Scan Time Exceeds 24 Hours"
            class="sm"
            #timeExceedDayIpRangeModal>
            <modal-content>
                <p>Cyber Hawk Level 1 (daily) Scans must be performed in less than 24 hours.</p>
                <p>
                    The estimated scan time associated with the IP Address range you have set
                    exceeds the 24-hour period.
                </p>
                <p>
                    To proceed, reduce the network IP Address range to a range that can be scanned
                    within 24 hours.
                </p>
            </modal-content>
            <modal-actions>
                <itc-button type="primary" class="cancel">OK</itc-button>
            </modal-actions>
        </sm-modal>

        <sm-modal title="Exclude IPs" class="sm" #excludeIpsModal>
            <modal-content>
                Please add any IPs that should be excluded from the internal scan.
                <br />
                <br />
                Example IP Range Format: 192.168.0.0-192.168.0.255
                <br />
                <div>
                    <div class="ui action input" style="margin: 10px 0; width: 343px">
                        <input
                            type="text"
                            id="textboxExcludedSingleIpOrRange"
                            [(ngModel)]="excludedIpRangeTextBoxValue"
                            placeholder="Single IP or IP Range" />
                        <itc-button
                            label="Add"
                            icon="fa-plus"
                            type="primary"
                            (onclick)="addExcludedIpRange()"></itc-button>
                    </div>
                    <div style="width: 100%">
                        <div style="float: left; margin-right: 16px">
                            <select #selectExcludedIpRangesToScan size="8" style="width: 327px">
                                <option *ngFor="let ipRange of modalExcludedSelectedIpRanges">
                                    {{ ipRange }}
                                </option>
                            </select>
                        </div>
                        <div>
                            <itc-button
                                label="Remove Selected Entry"
                                type="color"
                                color="grey"
                                style="display: block; margin-bottom: 8px"
                                (onclick)="
                                    removeExcludedSelectedIpRange(
                                        selectExcludedIpRangesToScan.value
                                    )
                                "></itc-button>
                            <itc-button
                                label="Clear All Entries"
                                type="color"
                                color="grey"
                                style="display: block; margin-bottom: 8px; margin-left: 0"
                                (onclick)="clearExcludedSelectedIpRanges()"></itc-button>
                        </div>
                    </div>
                </div>
            </modal-content>
            <modal-actions>
                <itc-button
                    label="Cancel"
                    class="cancel"
                    type="secondary"
                    (onclick)="closeExcludeIpsModalAction(false)"></itc-button>
                <itc-button
                    label="OK"
                    type="primary"
                    (onclick)="closeExcludeIpsModalAction(true)"></itc-button>
            </modal-actions>
        </sm-modal>

        <sm-modal title="Warning" class="sm" #fqdnWarningModal>
            <modal-content>
                Remote data collection using the non-FQDN may yield incomplete results. We recommend
                using a format such as corp.myco.com\ instead of the short domain prefix of MYCO\ in
                front of the user name for Active Directory domains.

                <br />
                <br />
                Continue anyway and use the specified domain and user name?
                <br />
            </modal-content>
            <modal-actions>
                <itc-button
                    class="cancel"
                    type="secondary"
                    (onclick)="closeFqdnWarningModalAction(false)">
                    Cancel
                </itc-button>
                <itc-button type="primary" (onclick)="closeFqdnWarningModalAction(true)">
                    OK
                </itc-button>
            </modal-actions>
        </sm-modal>

        <sm-modal title="Unitrends Test Results" class="sm" #unitrendsResultsModal>
            <modal-content>
                <img *ngIf="isLoading" src="/assets/img/ajax-loader.gif" class="loadingAnim" />
                <label [innerHTML]="unitrendsResponse"></label>
            </modal-content>
            <modal-actions>
                <itc-button type="primary" (onclick)="closeUnitrendsModalAction()">OK</itc-button>
            </modal-actions>
        </sm-modal>

        <sm-modal title="IP Address Range Limit Exceeded" class="sm" #ipAddressRangeLimit>
            <modal-content>
                <label>Reduce the number of IP addresses in order to proceed.</label>
            </modal-content>
            <modal-actions>
                <itc-button type="primary" (onclick)="closeIpAddressRangeLimit()">OK</itc-button>
            </modal-actions>
        </sm-modal>

        <!--  Used WjPopup instead of SMModel because Wijmo grid would close the modal when a drop down was selected.  -->

        <wj-popup
            title="Advanced Options"
            style="padding: 10px; width: 800px; height: 600px"
            #advancedOptionsModal>
            <a
                href="javascript:void(0)"
                (click)="closeAdvancedOptionsModalAction()"
                style="
                    position: absolute;
                    top: 5px;
                    right: 15px;
                    color: #000;
                    font-weight: bold;
                    font-size: 1.1em;
                ">
                X
            </a>
            <div style="padding: 10px">
                <div>
                    <span
                        class="advancedTab"
                        (click)="onAdvancedTabClick(1)"
                        [ngClass]="
                            selectedAdvancedOptionTab == 1 ? 'selected-advanced-tab-label' : ''
                        ">
                        Network Data Collection Options
                    </span>
                    <span
                        class="advancedTab"
                        (click)="onAdvancedTabClick(2)"
                        [ngClass]="
                            selectedAdvancedOptionTab == 2 ? 'selected-advanced-tab-label' : ''
                        ">
                        Security Data Collection Options
                    </span>
                    <span
                        class="advancedTab"
                        (click)="onAdvancedTabClick(3)"
                        [ngClass]="
                            selectedAdvancedOptionTab == 3 ? 'selected-advanced-tab-label' : ''
                        ">
                        User Control Tests
                    </span>
                </div>
                <div
                    [ngClass]="
                        selectedAdvancedOptionTab == 1
                            ? 'selected-advanced-tab'
                            : 'unselected-advanced-tab'
                    ">
                    <p>
                        These options are used for advanced troubleshooting. The are usually only
                        used when working with a RapidFire support engineer.
                    </p>
                    <div class="field">
                        <div>
                            <itc-checkbox
                                id="skipActiveDirectory"
                                [(ngModel)]="skipActiveDirectory">
                                Skip Active Directory
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipNetwork" [(ngModel)]="skipNetwork">
                                Skip Network
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipSqlServers" [(ngModel)]="skipSqlServers">
                                Skip SQL Servers
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipEventlogs" [(ngModel)]="skipEventlogs">
                                Skip Eventlogs
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipDhcp" [(ngModel)]="skipDhcp">
                                Skip DHCP
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox
                                id="skipAdComputerQuery"
                                [(ngModel)]="skipAdComputerQuery">
                                Skip Active Directory Computer Query
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipLogonEvents" [(ngModel)]="skipLogonEvents">
                                Skip Logon Events
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipInternetTrace" [(ngModel)]="skipInternetTrace">
                                Skip Internet Trace
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipWhois" [(ngModel)]="skipWhois">
                                Skip WHOIS
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipSpeedCheck" [(ngModel)]="skipSpeedCheck">
                                Skip Internet Speed Checks
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipVmware" [(ngModel)]="skipVmware">
                                Skip VMware
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipUnitrends" [(ngModel)]="skipUnitrends">
                                Skip Unitrends
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipIdAgent" [(ngModel)]="skipIdAgent">
                                Skip ID Agent
                            </itc-checkbox>
                        </div>
                    </div>
                </div>

                <div
                    [ngClass]="
                        selectedAdvancedOptionTab == 2
                            ? 'selected-advanced-tab'
                            : 'unselected-advanced-tab'
                    ">
                    <p>
                        Below are options to disable various parts of the Security Data Collection.
                    </p>
                    <div class="field">
                        <div>
                            <itc-checkbox
                                id="skipInternetAccessibility"
                                [(ngModel)]="skipInternetAccessibility">
                                Skip Internet Accessibility
                            </itc-checkbox>
                        </div>
                    </div>
                    <div>
                        <div>
                            <itc-checkbox id="skipLoginDetails" [(ngModel)]="skipLoginDetails">
                                Skip Login Details (LDF)
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipPolicies" [(ngModel)]="skipPolicies">
                                Skip Policies (RSOP)
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipPortTesting" [(ngModel)]="skipPortTesting">
                                Skip Port Testing
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox
                                id="skipScreenSaverSettings"
                                [(ngModel)]="skipScreenSaverSettings">
                                Skip Screen Saving Settings
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipUrlTesting" [(ngModel)]="skipUrlTesting">
                                Skip URL Testing
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipUsbDetection" [(ngModel)]="skipUsbDetection">
                                Skip USB Detection
                            </itc-checkbox>
                        </div>
                    </div>
                    <div class="inline field">
                        <div>
                            <itc-checkbox id="skipWifiData" [(ngModel)]="skipWifiData">
                                Skip Wifi Data
                            </itc-checkbox>
                        </div>
                    </div>
                </div>

                <div
                    [ngClass]="
                        selectedAdvancedOptionTab == 3
                            ? 'selected-advanced-tab'
                            : 'unselected-advanced-tab'
                    ">
                    <p>
                        The Security Assessment User Control tests will attempt to access sites in
                        various categories from this computer. If you do not wish to include
                        BUILT-IN URLs from a particular category in your assessment, please uncheck
                        it. Additionally, you may add individual URL to the category testing. Any
                        customer URLs will ALWAYS be checked.
                        <br />
                        <br />
                        Build-In URL Categories
                    </p>
                    <table>
                        <tr>
                            <td>
                                <div>
                                    <itc-checkbox
                                        id="testEntertainment"
                                        [(ngModel)]="testEntertainment">
                                        Entertainment
                                    </itc-checkbox>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <itc-checkbox
                                        id="testPornography"
                                        [(ngModel)]="testPornography">
                                        Pornography
                                    </itc-checkbox>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <itc-checkbox id="testShareware" [(ngModel)]="testShareware">
                                        Shareware
                                    </itc-checkbox>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <itc-checkbox
                                        id="testSocialMedia"
                                        [(ngModel)]="testSocialMedia">
                                        Social Media
                                    </itc-checkbox>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <itc-checkbox id="testWarez" [(ngModel)]="testWarez">
                                        Warez
                                    </itc-checkbox>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <itc-checkbox id="testWebMail" [(ngModel)]="testWebMail">
                                        Web Mail
                                    </itc-checkbox>
                                </div>
                            </td>
                        </tr>
                    </table>

                    <wj-flex-grid
                        #advancedOptionFlex
                        [itemsSource]="customUrls"
                        style="width: 100%"
                        (cellEditEnded)="cellEditEnded($event)">
                        <wj-flex-grid-column
                            [header]="'URL'"
                            [binding]="'url'"></wj-flex-grid-column>
                        <wj-flex-grid-column
                            [header]="'Category'"
                            [binding]="'category'"></wj-flex-grid-column>
                    </wj-flex-grid>
                </div>
            </div>

            <itc-button type="primary" (onclick)="closeAdvancedOptionsModalAction()">OK</itc-button>
        </wj-popup>
    </div>
</div>
