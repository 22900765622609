import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, Roles } from '../auth';
import { Breadcrumb } from 'app/shared/breadcrumbs/breadcrumb.model';
import { MessageService } from '../message.service';
import { NavService, NavMenuItem, ALL_SITES } from './nav.service';
import { Setting, SettingService } from 'app/settings';
import { Site } from 'app/sites/shared/site.model';
import { SiteService } from 'app/sites/shared/site.service';
import { SiteUsersService } from 'app/sites/shared/site-users.service';
import { UiService } from 'app/core/ui.service';
import { DEFAULTS, SETTINGS, SDSP_SETTINGS, K1_DISABLE } from 'app/settings/shared/constants';
import { Subject, takeUntil } from 'rxjs';
import { NotificationService } from '../../shared/itc/notification/notification.service';
import { DateService } from 'app/shared/date.service';
import { SemanticModalComponent } from 'app/semantic-legacy/semantic-legacy.module';
import { RFT_URL } from '../constants';
import { isDevMode } from '@angular/core';
import { WalkMeService } from '../walk-me.service';

declare var jQuery: any;

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, AfterViewInit {
    @ViewChild('helpMenu') helpMenu: ElementRef;
    @ViewChild('userMenu') userMenu: ElementRef;
    @ViewChild('settingsMenu') settingsMenu: ElementRef;
    @ViewChild('k1Menu') k1Menu: ElementRef;
    @ViewChild('sitesMenu') sitesMenu: any;

    @ViewChild('prefsComponent', { static: true }) prefsComponent: any;
    @ViewChild('releaseNotesModal', { static: true }) releaseNotesModal: any;
    @ViewChild('noticesModal', { static: true }) noticesModal: SemanticModalComponent;
    @ViewChild('whatsNewModal') whatsNewModal: any;
    @ViewChild('itcompleteDeleteModal', { static: true }) itcompleteDeleteModal: any;

    loadingComplete: boolean;

    menuItems: Array<NavMenuItem>;
    user: any;
    sites: Site[];
    siteName: string = ALL_SITES;
    _all: string = ALL_SITES;

    releaseNotesLoaded: boolean = false;
    releaseNotes: string = '';
    whatsNewLoaded = false;
    whatsNew = '';
    hasUpdatedVersion: boolean;
    products: any = {};
    cachedSiteId: string = '';
    settings: any;
    selectControl: UntypedFormControl = new UntypedFormControl();
    resizeTimeout: any;
    version: string;
    isSiteRestricted: boolean;
    k1Products = [];
    showLauncher = false;
    canChangeKaseyaOneSetting = false;
    isKaseya: boolean = false;
    isSMEorClientView: boolean;
    breadcrumbs: Breadcrumb[];
    hasNewWhatsNew = false;
    latestWhatsNewPost: string;
    whatsNewFieldName = 'USER_LATESTWHATSNEW';
    helpMenuOpen = false;
    userMenuOpen = false;
    settingsMenuOpen = false;
    k1MenuOpen = false;
    hasCMGRCSites = false;
    hasCMSites = false;
    hasCHSites = false;
    hasVSSites = false;
    hasNDProSites = false;
    ngUnsubscribe$: Subject<any> = new Subject();
    rft_url = RFT_URL;
    allowSettings = false;
    kaseyaOneV2Enabled = false;
    allowWalkMe = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private messageService: MessageService,
        private navService: NavService,
        private settingService: SettingService,
        private siteService: SiteService,
        private siteUsersService: SiteUsersService,
        private uiService: UiService,
        private dateService: DateService,
        private notificationService: NotificationService,
        private walkMeService: WalkMeService
    ) {}

    ngOnInit() {
        this.loadingComplete = false;

        this.user = this.authService.getIdentity();

        this.settings = this.getSettings();
        /* save accountTimeInfo, i need to do it through uiService as we don't really have a good data store, and we need it if
       user is in incognito mode since we can't get localstorage,
       but sometimes it takes too long to get settings (particularly on POAM when you click refresh)  so send
       this to have an option if the user doesn't have this info set in time. */
        if (this.settings.DateFormat && this.settings.TZINFO) {
            const accountTimeInfo = {
                DateFormat: this.settings.DateFormat,
                TZINFO: this.settings.TZINFO,
            };
            this.uiService.setControlValue('accountTimeInfo', JSON.stringify(accountTimeInfo));
        }

        this.uiService
            .getVersion()
            .then((version) => {
                this.version = version.replace(/Dev-|Beta-/, '');
            })
            .catch((err) => {});

        if (this.authService.isAuth()) {
            this.allowWalkMe = this.walkMeService.addSnippet();
        }

        if (this.user) {
            // for ITC KDS we display the username in menu without "@domain.com"
            this.user.email = this.user.username.split('@');
            this.user.truncatedEmail = `<span class='truncatedText'>${this.user.email[0]}</span>@${this.user.email[1]}`;

            /* On Dec 8th 2021 Kaseya Marketing decided to use another site, so our call
      won't work anymore, so comment out but leave it until we figure out how
      to get the same information from the new website. */
            // // check what's new
            // Promise.all([this.navService.getLatestWhatsNewDate(), this.settingService.getGlobalUserSetting(this.whatsNewFieldName)]).then(v => {
            //     // just get date in format 2021-10-17
            //     this.latestWhatsNewPost = v[0];
            //     let lastWhatsNewRead = v[1];
            //     // make date objects to ensure comparing of dates
            //     let latestWhatsNewPostDate = new Date(this.latestWhatsNewPost);
            //     let lastWhatsNewReadDate = new Date(lastWhatsNewRead);
            //     console.log("Latest post:", latestWhatsNewPostDate);
            //     console.log("last view post:", lastWhatsNewReadDate);
            //     if (!lastWhatsNewRead || latestWhatsNewPostDate > lastWhatsNewReadDate) {
            //         console.log("has new 'whats new'");
            //         this.hasNewWhatsNew = true;
            //     } else {
            //         console.log("no new what's new");
            //         this.hasNewWhatsNew = false;
            //     }
            // });

            this.isKaseya = this.authService.userIsKaseya();
            this.canChangeKaseyaOneSetting =
                this.user.roleId == Roles.Admin || this.user.roleId == Roles.Master;

            this.allowSettings =
                this.user.roleId == Roles.Admin || this.user.roleId == Roles.Master;

            this.isSiteRestricted = parseInt(this.user.roleId, 10) == 33;
            this.products = this.authService.getProducts();

            this.siteUsersService.getMyUser().then((res) => {
                this.user.userFullName = res.FullName;
                this.user.displayname = res.FirstName
                    ? res.FirstName
                    : this.user.username.split('@')[0];
                if (res.Products !== null && res.Products !== '') {
                    console.log('products', JSON.parse(res.Products));
                    this.k1Products = JSON.parse(res.Products);
                    this.showLauncher = true;
                } else {
                    this.showLauncher = false;
                }
            });

            this.menuItems = this.navService.getNavItems(this.getSelectedSite());

            this.settingService.getSettings().then((settings) => this.onSettings(settings));

            this.siteService.getSites().then((sites) => {
                sites?.forEach((s) => {
                    if (this.siteService.isComplianceManagerGRC(s)) {
                        this.hasCMGRCSites = true;
                    }
                    if (this.siteService.isComplianceManager(s)) {
                        this.hasCMSites = true;
                    }
                    if (this.siteService.isCyberHawk(s)) {
                        this.hasCHSites = true;
                    }
                    if (this.siteService.isKVS(s)) {
                        this.hasVSSites = true;
                    }
                    if (this.siteService.isNDPro(s)) {
                        this.hasNDProSites = true;
                    }
                });
                // save that we have the CMSites so we don't have to keep doing this.
                this.siteService.hasCMSites = this.hasCMSites;
            });
        } else {
            this.settingService.getBranding().then((settings) => this.onSettings(settings));
        }

        // Set the current site display on demand
        this.messageService.on('NAV_SET_SITE').subscribe((s) => {
            if (typeof s == typeof new Site()) {
                //this.selectControl.setValue((s as Site).Id);
                //this.setDropdownSelected(s);

                this.menuItems = this.navService.getNavItems(s);

                this.siteName = (s as Site).Name;
            } else if (s == ALL_SITES) {
                //this.selectControl.setValue(s);
                //this.setDropdownAllSites();

                this.menuItems = this.navService.getNavItems(this.getSelectedSite());

                this.siteName = ALL_SITES;
            }
        });

        // Return site list when requested
        this.messageService
            .on('NAV_GET_SITE_LIST')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.messageService.broadcast('NAV_SITE_LIST', this.sites);
            });

        this.messageService
            .on(SETTINGS)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((settings: any) => {
                //if (!Object.keys(settings).length)
                //settings = DEFAULTS;
                console.log('nav on settings');
                let sets = [];
                for (let s in settings) {
                    sets.push({
                        Name: s,
                        Value: settings[s],
                    });
                }

                this.onSettings(sets);
            });

        this.settingService
            .getCustomBrandingChangeObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((customBranding: boolean) => {
                this.settings.EnableCustomBranding = customBranding;
            });

        this.settingService
            .getNavHeaderTextChangeObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((coName: string) => {
                this.settings.CompanyName = coName;
            });
        this.settingService
            .getKaseyaLogoChangeObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((hideLogo: boolean) => {
                this.settings.HideKaseya = hideLogo;
            });

        this.settingService.isK1SsoV2Enabled().then((res) => {
            this.kaseyaOneV2Enabled = res;
            this.loadingComplete = true;
        });
    }

    ngAfterViewInit() {
        this.selectControl.setValue(ALL_SITES);
        jQuery(this.helpMenu.nativeElement).dropdown({
            name: 'helpMenu',
            namespace: 'helpMenu',
            onShow: () => {
                jQuery(this.helpMenu.nativeElement).find('a.active').removeClass('active selected');
                this.helpMenuOpen = true;
            }, // don't show 'active' menu items in this
            onHide: () => {
                this.helpMenuOpen = false;
            },
            duration: 0,
        });

        if (this.user) {
            jQuery(this.userMenu.nativeElement).dropdown({
                name: 'userMenu',
                namespace: 'userMenu',
                silent: isDevMode() ? false : true,
                onShow: () => {
                    jQuery(this.userMenu.nativeElement)
                        .find('a.active')
                        .removeClass('active selected');
                    this.userMenuOpen = true;
                }, // don't show 'active' menu items in this
                onHide: () => {
                    this.userMenuOpen = false;
                },
                duration: 0,
            });

            if (this.allowSettings) {
                jQuery(this.settingsMenu.nativeElement).dropdown({
                    name: 'settingsMenu',
                    namespace: 'settingsMenu',
                    onShow: () => {
                        jQuery(this.settingsMenu.nativeElement)
                            .find('a.active')
                            .removeClass('active selected');
                        this.settingsMenuOpen = true;
                    }, // don't show 'active' menu items in this
                    onHide: () => {
                        this.settingsMenuOpen = false;
                    },
                    duration: 0,
                });
            }

            jQuery(this.k1Menu.nativeElement).dropdown({
                name: 'k1Menu',
                namespace: 'k1Menu',
                onShow: () => {
                    jQuery(this.k1Menu.nativeElement)
                        .find('a.active')
                        .removeClass('active selected');
                    this.k1MenuOpen = true;
                }, // don't show 'active' menu items in this
                onHide: () => {
                    this.k1MenuOpen = false;
                },
                duration: 0,
            });
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
    }

    showPrefsModal() {
        this.prefsComponent.setIsMfaRequired();
        this.prefsComponent.showModal();
    }

    // logout() {
    //     if (this.isK1 && !environment.production)  {
    //         this.authService.logoutK1();
    //     } else {
    //         this.authService.logout();
    //     }
    // }

    onSites(sites: Site[]) {
        this.sites = sites.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));

        this.messageService.broadcast('NAV_SITE_LIST', this.sites);

        //if (this.selectControl.value == ALL_SITES)
        //  this.setDropdownAllSites();
        //else {
        //  let site = this.getSiteById(this.selectControl.value);
        //  this.setDropdownSelected(site);
        //}
    }

    checkForMappingSuccess(params: any) {
        if (params['mapsuccess']) {
            let _nMapSuccess: string = params['mapsuccess'];
            if (_nMapSuccess === '1') {
                this.notificationService.toast.success(
                    'Mapping Success',
                    'User mapping to IT Complete was successful.'
                );
            }
        } else if (params['mapfail']) {
            let _sReason: string = '';
            if (params['code']) {
                if (params['code'] === '2') {
                    _sReason = 'IT Complete User cannot be mapped to more than one RFT User.';
                } else {
                    _sReason = 'IT Complete User Mapping Failed.';
                }
                this.notificationService.toast.error('Mapping Failure', _sReason);
            }
        }
    }

    getSettings(): any {
        let sets: any = JSON.parse(localStorage.getItem(SDSP_SETTINGS));
        if (sets) return sets;
        else return DEFAULTS;
    }

    onSettings(settings: Setting[]) {
        for (let setting of settings) {
            this.settings[setting.Name] = setting.Value;
        }

        this.settings.HideKaseya = this.settings.HideKaseya + '' === 'true' ? true : false;
        // save accountTimeInfo, i need to do it through uiService as we don't really have a good data store, and we need it if
        // user is in incognito mode since we can't get localstorage
        const accountTimeInfo = {
            DateFormat: this.settings.DateFormat as string,
            TZINFO: this.settings.TZINFO as string,
        };
        this.dateService.setDateFormat(accountTimeInfo.DateFormat);
        this.dateService.setTimeZone(accountTimeInfo.TZINFO);
        this.uiService.setControlValue('accountTimeInfo', JSON.stringify(accountTimeInfo));

        //localStorage.setItem(SDSP_SETTINGS, JSON.stringify(this.settings));
    }

    changeSite() {
        let next: string[] = [];

        //let match = /(site\/(.+)\/)?([a-zA-Z]+)/.exec(this.router.url);
        let match = /\/?(site\/([^\/]+)\/)?(.+)/.exec(this.router.url); // newer regex to match deeper nested pages
        let isSite = match[1] !== void 0,
            siteName = match[2],
            page = match[3];

        // selected site is ALL
        if (this.selectControl.value == ALL_SITES) {
            // if coming from an individual site (otherwise nothing to do)
            if (isSite) {
                //if (['sites', 'overview/dashboard'].indexOf(page) > -1)
                //  next.push('sites');
                //else
                next = next.concat(page.split('/'));
            }
        }

        // selected site is individual
        else {
            let site = this.sites.find((s) => s.Id == this.selectControl.value);

            next.push('site', site.Name);

            if (!(['sites'].indexOf(page) > -1))
                // left an array in case more pages need to go in here
                next = next.concat(page.split('/'));
        }

        if (next.length > 0) this.router.navigate(next);

        this.menuItems = this.navService.getNavItems(this.getSelectedSite());

        this.cachedSiteId = this.selectControl.value;
    }

    /*siteChanged(s: Site) {
    let text = '';

    this.selectControl.setValue( s.Name;
    this.menuItems = this.navService.getNavItems(this.getSelectedSite());

    if (s == ALL_SITES)
      text = 'All Sites';

    }

    let sm = this.sitesMenu;
    window.setTimeout(function () {
      sm.setText(s);
    }, 0);
  }*/

    isSiteDisabled(): boolean {
        return !this.navService.getSiteEnabled();
    }

    getSiteById(siteId: number | string): Site {
        if (siteId == ALL_SITES) {
            let s = new Site();
            s.Name = 'All Sites';
            return s;
        }
        return this.sites ? this.sites.find((s) => s.Id == siteId) : null;
    }

    //siteEvent(event?) {
    //  if (event.type == 'keyup') {
    //    if (event.keyCode == 27) {
    //      this.selectControl.setValue(this.cachedSiteId);
    //      this.setDropdownSelected(this.getSiteById(this.cachedSiteId));
    //    }
    //    else if (event.keyCode == 13)
    //      this.changeSite();
    //  }
    //  else if (event.type == 'click') {
    //    if (this.selectControl.value != this.cachedSiteId)
    //      this.changeSite();
    //  }
    //  else if (event.type == 'focusout') {
    //    //if (event.target.className.indexOf('dropdown') > -1 || event.target.parentElement.className.indexOf('dropdown') > -1) {
    //    if (event.relatedTarget == null || event.relatedTarget.className.indexOf('item') > -1) {
    //      if (this.cachedSiteId == ALL_SITES)
    //        this.setAllSites();
    //      else {
    //        this.selectControl.setValue(this.cachedSiteId);
    //        this.setDropdownSelected(this.getSiteById(this.cachedSiteId));
    //      }
    //    }
    //  }
    //}

    //setDropdownAllSites() {
    //  let sm = this.sitesMenu;

    //  window.setTimeout(function () {
    //    jQuery('.siteDropdown').find('.item').removeClass('active selected');
    //    jQuery('.siteDropdown').find(`[data-value="${ALL_SITES}"]`).addClass('active selected');
    //    if (sm !== undefined) { sm.setText('All Sites'); } else { console.warn(`var sm is undefined, cant setText('All Sites')`); }
    //  }, 0);
    //}

    //setDropdownSelected(site: Site) {
    //  let sm = this.sitesMenu;

    //  if (typeof site == typeof new Site()) {
    //    window.setTimeout(function () {
    //      jQuery('.siteDropdown').find('.item').removeClass('active selected');
    //      jQuery('.siteDropdown').find(`[data-value="${site.Id}"]`).addClass('active selected');
    //      if (sm !== undefined) { sm.setText(site.Name); } else { console.warn(`var sm is undefined, cant setText('${site.Name}')`); }
    //    }, 0);
    //  }
    //  else {

    //  }

    //}

    setAllSites() {
        //this.selectControl.setValue(ALL_SITES);
        //this.setDropdownAllSites();
        this.changeSite();
    }

    goHome() {
        //this.selectControl.setValue(ALL_SITES);
        //this.setDropdownAllSites();
        this.router.navigateByUrl('/sites');
    }

    getSelectedSite(): Site {
        let siteId = this.selectControl.value;

        if (!siteId || siteId == ALL_SITES) {
            let s = new Site();
            s.Name = ALL_SITES;
            return s;
        } else {
            let site = this.sites.find((s) => s.Id == siteId);

            if (this.sites && this.sites.length) return this.sites.find((s) => s.Name == site.Name);
            else {
                let s = new Site();
                s.Name = site.Name;
                return s;
            }
        }
    }

    siteFocus(event) {
        this.cachedSiteId = this.selectControl.value;
    }

    showReleaseNotes() {
        this.releaseNotesLoaded = false;
        this.releaseNotesModal.show();
        jQuery
            .get(`${this.rft_url}/data/portal-releasenotes.html`, (notes) => {
                this.releaseNotes = notes;
                this.releaseNotesLoaded = true;
            })
            .fail(function () {
                console.log('Failed to load portal release notes');
            });
    }

    showWhatsNew() {
        /* Logic for this changed by Jordan Dec 08 2021
    No longer opening in app instead opening in a new
    tab, and I'm not saving any user variables now
    until we figure out how to know when there's a
    new post becaue of other change above */
        window.open('https://www.rapidfiretools.com/products/whats-new');
    }

    disableItComplete() {
        this.itcompleteDeleteModal.hide();

        this.settingService.removeK1Mapping().then((res) => {
            document.cookie =
                'rft-itc-success=; Domain=' +
                location.host +
                '; Path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;';
        });
    }

    onEnableKaseyaOneClick() {
        if (this.loadingComplete) {
            this.settingService.k1Ssov2Registration();
        }
    }

    openK1SSO() {
        this.router.navigate(['/', 'launcher']);
    }

    openWalkMe(view: string) {
        this.walkMeService.openWalkMeDrawer(view);
    }
}
