export class ScanSettingsRequest {
    //ApplianceId: string;
    ApplianceInstallType: string;
    Flags: string[];
    ExternalIpRanges: string[];
    HasReporter: boolean;
    ScanHostUsername: string;
    ScanHostPassword: string;
    ScanHostDomain: string;
    ScanHostList: string;
    SiteName: string;
    ScanType: string;
    SelectedOus: string;
    AzureTenant: string;
    AzureClientId: string;
    AzureSecret: string;
}
