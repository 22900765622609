import { Injectable } from '@angular/core';
import { PrimeNGDateFormattingPipe } from './primeNG/date-formatting.pipe';
import { BehaviorSubject, Observable } from 'rxjs';
import { UiService } from 'app/core/ui.service';
import { DatePipe } from '@angular/common';
import { TZINFOS } from 'app/settings/general/tzinfos';

declare var ago: any;
export interface DateTimeObject {
    full: string;
    short: string;
}
export interface TimeDropdown {
    value: string;
    label: string;
    disabled?: boolean;
}
export interface TimeZone {
    Id: string;
    DisplayName: string;
    StandardName: string;
    DaylightName: string;
    BaseUtcOffset: string;
    AdjustmentRules: AdjustmentRule[];
    SupportsDaylightSavingTime: boolean;
}

interface AdjustmentRule {
    BaseUtcOffsetDelta: string;
    DateStart: string;
    DateEnd: string;
    DaylightDelta: string;
    DaylightTransitionStart: AdjustmentInfo;
    DaylightTransitionEnd: AdjustmentInfo;
}

interface AdjustmentInfo {
    TimeOfDay: string;
    Month: number;
    Week: number;
    Day: number;
    DayOfWeek: number;
    IsFixedDateRule: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class DateService {
    private dateFormat: BehaviorSubject<string> = new BehaviorSubject<string>('MM/dd/yyy');
    private timeZone: BehaviorSubject<string> = new BehaviorSubject<string>('');

    private timePickerOptions: TimeDropdown[] = [
        { value: '00:00', label: '12:00 AM' },
        { value: '00:30', label: '12:30 AM' },
        { value: '01:00', label: '01:00 AM' },
        { value: '01:30', label: '01:30 AM' },
        { value: '02:00', label: '02:00 AM' },
        { value: '02:30', label: '02:30 AM' },
        { value: '03:00', label: '03:00 AM' },
        { value: '03:30', label: '03:30 AM' },
        { value: '04:00', label: '04:00 AM' },
        { value: '04:30', label: '04:30 AM' },
        { value: '05:00', label: '05:00 AM' },
        { value: '05:30', label: '05:30 AM' },
        { value: '06:00', label: '06:00 AM' },
        { value: '06:30', label: '06:30 AM' },
        { value: '07:00', label: '07:00 AM' },
        { value: '07:30', label: '07:30 AM' },
        { value: '08:00', label: '08:00 AM' },
        { value: '08:30', label: '08:30 AM' },
        { value: '09:00', label: '09:00 AM' },
        { value: '09:30', label: '09:30 AM' },
        { value: '10:00', label: '10:00 AM' },
        { value: '10:30', label: '10:30 AM' },
        { value: '11:00', label: '11:00 AM' },
        { value: '11:30', label: '11:30 AM' },
        { value: '12:00', label: '12:00 PM' },
        { value: '12:30', label: '12:30 PM' },
        { value: '13:00', label: '01:00 PM' },
        { value: '13:30', label: '01:30 PM' },
        { value: '14:00', label: '02:00 PM' },
        { value: '14:30', label: '02:30 PM' },
        { value: '15:00', label: '03:00 PM' },
        { value: '15:30', label: '03:30 PM' },
        { value: '16:00', label: '04:00 PM' },
        { value: '16:30', label: '04:30 PM' },
        { value: '17:00', label: '05:00 PM' },
        { value: '17:30', label: '05:30 PM' },
        { value: '18:00', label: '06:00 PM' },
        { value: '18:30', label: '06:30 PM' },
        { value: '19:00', label: '07:00 PM' },
        { value: '19:30', label: '07:30 PM' },
        { value: '20:00', label: '08:00 PM' },
        { value: '20:30', label: '08:30 PM' },
        { value: '21:00', label: '09:00 PM' },
        { value: '21:30', label: '09:30 PM' },
        { value: '22:00', label: '10:00 PM' },
        { value: '22:30', label: '10:30 PM' },
        { value: '23:00', label: '11:00 PM' },
        { value: '23:30', label: '11:30 PM' },
    ];

    private weekDays: DateTimeObject[] = [
        { full: 'Sunday', short: 'Sun' },
        { full: 'Monday', short: 'Mon' },
        { full: 'Tuesday', short: 'Tue' },
        { full: 'Wednesday', short: 'Wed' },
        { full: 'Thursday', short: 'Thu' },
        { full: 'Friday', short: 'Fri' },
        { full: 'Saturday', short: 'Sat' },
    ];
    private months: DateTimeObject[] = [
        { full: 'January', short: 'Jan' },
        { full: 'February', short: 'Feb' },
        { full: 'March', short: 'Mar' },
        { full: 'April', short: 'Apr' },
        { full: 'May', short: 'May' },
        { full: 'June', short: 'Jun' },
        { full: 'July', short: 'Jul' },
        { full: 'August', short: 'Aug' },
        { full: 'September', short: 'Sep' },
        { full: 'October', short: 'Oct' },
        { full: 'November', short: 'Nov' },
        { full: 'December', short: 'Dec' },
    ];
    private quarters: string[] = ['January', 'April', 'July', 'October'];

    constructor(
        public primeNGDatePipe: PrimeNGDateFormattingPipe,
        private uiService: UiService,
        private datePipe: DatePipe
    ) {}

    setDateFormat(dateFormat: string) {
        this.dateFormat.next(dateFormat);
    }
    getDateFormat(): Observable<string> {
        return this.dateFormat.asObservable();
    }

    convertToAccountFormat(date: Date): string {
        let portalSettings = JSON.parse(this.uiService.getControlValue('accountTimeInfo'));
        let format = portalSettings.DateFormat;

        return new DatePipe('en-us').transform(date, format, '');
    }
    setTimeZone(timeZone: string) {
        this.timeZone.next(timeZone);
    }
    getTimeZone(): Observable<string> {
        return this.timeZone.asObservable();
    }

    getLocalTimeZone(): string {
        //Begin attempting to get the Id from the DaylightName from the Date object.
        let intlTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let opts: Intl.DateTimeFormatOptions = {
            hour: 'numeric',
            minute: '2-digit',
            timeZone: intlTz,
            timeZoneName: 'long'
        };
        let dateString: string = new Date().toLocaleString('en', opts);
        //expected format 12:00 AM Japan Standard Time
        let dateSplitSpace: string[] = dateString.split(' ');
        dateSplitSpace.splice(0, 2);
        //expected format ["Japan","Standard","Time"]
        //Translate to standard time format (eg from Eastern Daylight Time or British Summer Time)
        let finalDateString: string = dateSplitSpace.join(' ').replace("Summer", "Standard").replace("Daylight", "Standard");
        let localTimeZone: string = TZINFOS.find((zone) => { return zone.StandardName == finalDateString || zone.AlternateNames?.indexOf(finalDateString) > -1 })?.Id;
        if (!localTimeZone) {
            console.log(finalDateString); //this is stripped for production, helps us find what went wrong in dev/alpha
            //to resolve this, find matching timezone in TZINFOS and add what's printed here to the AlternateNames optional field
        }

        return localTimeZone;
    }

    relativeTime(date: Date): string {
        let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        let offset = date.getTimezoneOffset() / 60;
        let hours = date.getHours();

        newDate.setHours(hours + offset);

        return ago(newDate.getTime(), true);
    }

    getyyyyMMdd(date: Date): string {
        return date.toISOString().substring(0, 10).split('-').join('');
    }

    getWeekDays(type: 'full' | 'short' | 'object' = 'full'): string[] | DateTimeObject[] {
        switch (type) {
            case 'short':
                return this.weekDays.map((day) => day.short) as string[];
            case 'object':
                return this.weekDays as DateTimeObject[];
            case 'full':
            default:
                return this.weekDays.map((day) => day.full) as string[];
        }
    }
    getMonths(type: 'full' | 'short' | 'object' = 'full'): string[] | DateTimeObject[] {
        switch (type) {
            case 'short':
                return this.months.map((month) => month.short) as string[];
            case 'object':
                return this.months as DateTimeObject[];
            case 'full':
            default:
                return this.months.map((month) => month.full) as string[];
        }
    }
    getQuarters(): string[] {
        return this.quarters;
    }
    getHours(): TimeDropdown[] {
        return this.timePickerOptions;
    }

    getFormattedOffset(date: Date, userTZ: string, ignoreDst: boolean = false): string {
        // get utcoffset for selected TZINFO
        const userTZOffset = TZINFOS.find((tz) => tz.Id == userTZ).BaseUtcOffset;
        // the BaseUtcOffset is in format '05:00:00', and we only need the first 2 parts
        let formattedOffset: string = userTZOffset.split(':').slice(0, 2).join(':');

        // check to see if SupportsDaylightSavingTime is supported
        let supportsDst = TZINFOS.find((tz) => tz.Id == userTZ).SupportsDaylightSavingTime;
        if (supportsDst) {
            // this is so that existing code where Daylight Saving Time is handled elsewhere will be unaffected.
            if (!ignoreDst) {
                let adjustmentRule = this.getAdjustmentRule(userTZ, date);
                // the timezone can support Daylight Saving, but not support it for certain dates, so check if there is a rule.
                if (adjustmentRule) {
                    // check to see if the date is within the timeframe for Daylight Saving Time
                    let isDst: boolean = this.isDaylightSavingTime(adjustmentRule, date);
                    if (isDst) {
                        let daylightOffsetDelta = adjustmentRule.DaylightDelta;
                        let formattedDelta = daylightOffsetDelta.split(':').slice(0, 2).join(':');
                        let offsetHours: number = Number(formattedDelta.split(':')[0]);
                        let offsetMin: number = Number(formattedDelta.split(':')[1]);

                        let adjustedHours: number =
                            Number(formattedOffset.split(':')[0]) + offsetHours;
                        let sAdjustedHours =
                            adjustedHours < 10 && adjustedHours >= 0
                                ? `0${adjustedHours}`
                                : adjustedHours < 0 && adjustedHours > -10
                                ? `-0${Math.abs(adjustedHours)}`
                                : `${adjustedHours}`;
                        let adjustedMin: number = Number(formattedOffset.split(':')[1]) + offsetMin;
                        let sAdjustedMin =
                            adjustedMin < 10 && adjustedMin >= 0
                                ? `0${adjustedMin}`
                                : adjustedMin < 0 && adjustedMin > -10
                                ? `-0${Math.abs(adjustedMin)}`
                                : `${adjustedMin}`;
                        formattedOffset = `${sAdjustedHours}:${sAdjustedMin}`;
                    }
                }
            }
        }
        // the BaseUtcOffset has a negative for negative, but no + for positive so add that.
        if (formattedOffset.charAt(0) != '-') {
            formattedOffset = `+${formattedOffset}`;
        }
        return formattedOffset;
    }

    /**
     * Get the daylight saving time adjustment rule of the timezone for the provided date.
     * This checks to see if the year is within the start and end years of the adjustment rule
     * and returns that rule.
     * @param tzId
     * @param date
     * @returns
     */
    getAdjustmentRule(tzId: any, date: Date | string) {
        let refenceDateTime: Date;

        if (typeof date === 'string') {
            date = new Date(date);
        }

        if (!date) return;

        refenceDateTime = date;

        let tzinfo = TZINFOS.find((tz) => tz.Id == tzId);

        if (!tzinfo.SupportsDaylightSavingTime) return;

        let adjustmentRule = tzinfo.AdjustmentRules.find((rule) => {
            if (
                new Date(rule.DateStart).getFullYear() <= refenceDateTime.getFullYear() &&
                new Date(rule.DateEnd).getFullYear() >= refenceDateTime.getFullYear()
            ) {
                return rule;
            }
        });

        return adjustmentRule;
    }

    /**
     * Get the Date for the start or end of the transition for Daylight Saving Time based on the adjustment rules.
     * @param year
     * @param dayOfWeek
     * @param month
     * @param week Corresponds to the nth DayOfTheWeek of the month (e.g. The second Sunday of the Month)
     * @returns Date of the transition.
     */
    getTransitionDate(year: number, dayOfWeek: number, month: number, week: number): Date {
        // To get the first occurrence of the day of the week, first get the first of the month.
        let firstOfMonth = new Date(year, month - 1, 1);
        // get the offset of the day of the week. If it is negative, (e.g. Sunday(0) - Wednesday(3) = -3) add 7.
        let diff = dayOfWeek - firstOfMonth.getDay();
        diff = diff < 0 ? diff + 7 : diff;
        // the first occurence is the first of the month + diff. Add (7 * (week-1)) to move to the next occurrence.
        let nthDayOfWeek = new Date(
            year,
            month - 1,
            firstOfMonth.getDate() + diff + 7 * (week - 1)
        );

        return nthDayOfWeek;
    }

    /**
     * Test a date to see if it falls within the starting and ending dates for daylight saving time for the provided timezone adjustment rule.
     * @param tzRule The adjustment rule for a timezone's daylight saving time.
     * @param date The date to be tested
     * @returns True if the date falls within the daylight saving time period.
     */
    isDaylightSavingTime(tzRule: any, date: Date): boolean {
        //DayOfWeek = 0; Month = 3; Week = 2; means the Second Sunday of March
        let startDayOfWeek = tzRule.DaylightTransitionStart.DayOfWeek;
        let startMonth = tzRule.DaylightTransitionStart.Month;
        let startWeek = tzRule.DaylightTransitionStart.Week;

        //DayOfWeek = 0; Month = 11; Week = 1; means the First Sunday of November
        let endDayOfWeek = tzRule.DaylightTransitionEnd.DayOfWeek;
        let endMonth = tzRule.DaylightTransitionEnd.Month;
        let endWeek = tzRule.DaylightTransitionEnd.Week;

        let startDate = this.getTransitionDate(
            date.getFullYear(),
            startDayOfWeek,
            startMonth,
            startWeek
        );
        let endDate = this.getTransitionDate(date.getFullYear(), endDayOfWeek, endMonth, endWeek);
        return date <= endDate && date >= startDate;
    }

    /**
     * Return the current date/time at passed in timezone
     * @param date The date to be adjusted
     * @param tz The timezone to be adjusted to (option, if not sent we use account timezone)
     * @returns New date object for current time in selected tz
     */
    getAdjustedDateObject(date: Date, tz?: string): Date {
        tz = tz ? tz : this.timeZone.value;
        let utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        let offset = this.getFormattedOffset(date, tz);
        let [offsetHours, offsetMinutes] = offset.split(':').map(Number);
        let offsetTime = offsetHours * 60 * 60 * 1000 + offsetMinutes * 60 * 1000;
        return new Date(utcDate.getTime() + offsetTime);
    }

    /**
     * Return the passed in date (only, no time) formatted to account format
     * @param date
     * @returns string; formatted date
     */
    formatDate(date: Date): string {
        return this.datePipe.transform(date, this.dateFormat.getValue());
    }
}
