<div
    class="login_page itc-kds"
    [class.light]="theme == 'light'"
    [class.kvs]="isKVSPortal"
    [class.customBranded]="customBranding">
    <a
        class="kaseya_logo ir"
        href="https://www.kaseya.com/"
        *ngIf="!allowCustomBranding || !customBranding">
        Kaseya
    </a>
    <div class="login_outer_container">
        <div class="login_container" *ngIf="!showResetPasswordForm">
            <div
                class="logoContainer"
                [hidden]="
                    resetView ||
                    collectOemIdView ||
                    vsaUserDoesNotExistView ||
                    vsaActivationFailureView ||
                    vsaActivationSuccessView ||
                    twoFactorLogin ||
                    collectPasswordView ||
                    mapItcUserView ||
                    resetSuccess ||
                    accountDisabledView
                ">
                <div class="company_logo ir" *ngIf="!(customBranding && logoImage) && !isKVSPortal">
                    RapidFire Tools
                </div>
                <div class="company_logo ir kvs" *ngIf="isKVSPortal">Vulnerability Scanner</div>

                <div *ngIf="customBranding && logoImage" class="login_logo">
                    <img [src]="logoImage" width="240" />
                </div>
            </div>

            <itc-notification-banner></itc-notification-banner>

            <form
                class=""
                (ngSubmit)="submit()"
                name="loginForm"
                novalidate
                [hidden]="
                    resetView ||
                    collectOemIdView ||
                    vsaUserDoesNotExistView ||
                    vsaActivationFailureView ||
                    vsaActivationSuccessView ||
                    twoFactorLogin ||
                    collectPasswordView ||
                    mapItcUserView ||
                    resetSuccess ||
                    accountDisabledView
                ">
                <div>
                    <label for="username">Username</label>
                    <div class="" [ngClass]="{ error: username.invalid && !username.pristine }">
                        <input
                            type="text"
                            name="username"
                            id="username"
                            [(ngModel)]="info.username"
                            required
                            #username="ngModel"
                            tabindex="1" />
                    </div>
                    <div style="display: flex; justify-content: space-between">
                        <label for="password">Password</label>
                        <a class="forgotPW" href="login#reset-password">Forgot Password?</a>
                    </div>
                    <div class="" [ngClass]="{ error: password.invalid && !password.pristine }">
                        <input
                            type="password"
                            name="password"
                            id="password"
                            [(ngModel)]="info.password"
                            required
                            #password="ngModel"
                            tabindex="2" />
                    </div>

                    <label class="rememberMe">
                        <input
                            type="checkbox"
                            class="itc checkbox"
                            [formControl]="rememberCtrl"
                            tabindex="3" />
                        <span>Remember me</span>
                    </label>

                    <button
                        class="itc fluid button large"
                        name="loginButton"
                        [ngClass]="{ loading: loading }"
                        type="submit"
                        tabindex="4">
                        Log in
                    </button>

                    <div class="itc_login" *ngIf="itcEnabled">
                        <div class="separator">&nbsp;&nbsp;Or&nbsp;&nbsp;</div>
                        <a href="/launcher" tabindex="5" style="font-weight: 500">
                            <svg
                                width="24px"
                                height="18px"
                                viewBox="0 0 24 18"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                style="margin-right: 16px">
                                <title>KaseyaOne</title>
                                <g
                                    id="Unified-Products"
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd">
                                    <g
                                        id="Log-In-with-K1-Screen"
                                        transform="translate(-626.000000, -689.000000)"
                                        fill="#4F64EE">
                                        <g
                                            id="Group-48"
                                            transform="translate(626.000000, 689.000000)">
                                            <polygon
                                                id="Fill-1"
                                                points="5.62932696 0.5 0 5.05245451 0 5.97714541 5.62932696 5.97714541 5.62932696 17.5 11 17.5 11 12.7582121 8.0982705 9.52545659 11 5.88839537 11 0.5"></polygon>
                                            <polygon
                                                id="Fill-2"
                                                points="24 0 17.2878116 0 10 9.28803989 13.5567937 13.316848 17.2192037 17.5 24 17.4068167 16.5328399 8.70326409"></polygon>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            Log in with KaseyaOne
                        </a>
                    </div>
                    <div id="learnMore">
                        <a
                            target="_blank"
                            href="https://help.one.kaseya.com/help/Default_CSH.htm#1015">
                            Learn More about KaseyaOne
                        </a>
                    </div>
                </div>
            </form>

            <form
                class=""
                name="mapItcUserForm"
                novalidate
                [hidden]="!mapItcUserView"
                (submit)="mapItcUser()">
                <div>
                    <div class="section_header">Enable IT Complete</div>
                    <div class="section_header_text">
                        <p>
                            Enter your username and password for this portal to enable login with IT
                            Complete
                        </p>

                        <itc-banner type="warning">
                            Your IT Complete login is not linked with an existing account
                        </itc-banner>
                    </div>
                    <label for="mapItcUserView-username">Username</label>
                    <div class="" [ngClass]="{ error: username.invalid && !username.pristine }">
                        <input
                            type="text"
                            name="username"
                            id="mapItcUserView-username"
                            [(ngModel)]="info.username"
                            required
                            #username="ngModel" />
                    </div>

                    <label for="mapItcUserView-password">Password</label>
                    <div class="" [ngClass]="{ error: password.invalid && !password.pristine }">
                        <input
                            type="password"
                            name="password"
                            id="mapItcUserView-password"
                            [(ngModel)]="info.password"
                            required
                            #password="ngModel" />
                    </div>
                    <div>
                        <button
                            class="itc fluid button large"
                            name="loginButton"
                            [ngClass]="{ loading: loading }"
                            type="submit">
                            Enable
                        </button>
                    </div>
                </div>
            </form>

            <form
                class=""
                name="resetPasswordForm"
                [hidden]="!resetView || resetSuccess"
                (submit)="resetPassword()">
                <div>
                    <div class="section_header">Forgot Password</div>

                    <div class="section_header_text">
                        Please enter your username below. An email with a new temporary password
                        will be sent to the email address you provided when you registered.
                    </div>

                    <div
                        class=""
                        [ngClass]="{ error: rp_username.invalid && !rp_username.pristine }">
                        <label for="resetView-username">Username</label>
                        <div>
                            <input
                                type="text"
                                name="username"
                                id="resetView-username"
                                [(ngModel)]="rpUsername"
                                required
                                #rp_username="ngModel" />
                        </div>
                    </div>

                    <button
                        class="itc fluid button large"
                        name="resetButton"
                        [ngClass]="{ loading: loading }"
                        type="submit"
                        *ngIf="!resetSuccess">
                        Submit
                    </button>

                    <div style="margin-top: 16px; text-align: center">
                        <a href="login#">Back to Login</a>
                    </div>
                </div>
            </form>

            <form *ngIf="resetSuccess">
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        style="width: 100px; display: block; margin: 0 auto 16px">
                        <style>
                            path {
                                fill: #f48121;
                            }
                        </style>
                        <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
                        <path
                            d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" />
                    </svg>
                    <div class="section_header">Email Sent</div>

                    <div class="section_header_text">
                        An email with a password reset link was sent to the email address associated
                        with the username you entered. The email will come from
                        ‘notify@rapidfiretools.com’. If you did not receive an email, please verify
                        that you entered the correct username.
                    </div>

                    <a
                        routerLink="/login"
                        class="itc large fluid button"
                        style="display: inline-block; text-align: center"
                        (click)="resetSuccess = false">
                        Back to Login
                    </a>
                </div>
            </form>

            <form
                class="accountDisabledContainer"
                name="accountDisabledForm"
                [hidden]="!accountDisabledView">
                <div>
                    <div class="accountDisabledItem">
                        <fa-icon class="userLocked" icon="user-lock"></fa-icon>
                    </div>

                    <div class="disabledTitle">Account Disabled</div>

                    <div class="disabledText" [textContent]="disabledMessage"></div>

                    <div class="accountDisabledItem">
                        <fa-icon icon="question-circle"></fa-icon>
                        <a
                            class="hyperlink"
                            target="_blank"
                            href="https://help.one.kaseya.com/help/Default_CSH.htm#1077">
                            Why am I seeing this?
                        </a>
                    </div>

                    <a
                        routerLink="/login"
                        class="itc large fluid button"
                        style="display: inline-block; text-align: center"
                        (click)="accountDisabledView = false">
                        Back to Login
                    </a>
                </div>
            </form>

            <form
                class=""
                name="twoFactorForm"
                [hidden]="!twoFactorLogin"
                (submit)="submitTwoFactor()">
                <div>
                    <div class="section_header">Verification</div>
                    <div class="section_header_text">
                        Enter the current authentication code.
                    </div>

                    <div class="" [ngClass]="{ error: totp.invalid && !totp.pristine }">
                        <label for="totp">Authentication Code</label>
                        <div class="itc input" style="width: 100%">
                            <input
                                type="text"
                                name="totp"
                                id="totp"
                                [(ngModel)]="totpCode"
                                required
                                #totp="ngModel"
                                #2FAinput />
                        </div>
                    </div>

                    <button
                        class="itc fluid button large"
                        name="resetButton"
                        [ngClass]="{ loading: loading }"
                        type="primary">
                        Verify
                    </button>
                </div>
            </form>

            <form
                class=""
                name="collectOemIdForm"
                [hidden]="!collectOemIdView"
                (submit)="submitOemId()">
                <div>
                    <div class="section_header">Activate Kaseya Compliance Manager</div>

                    <div class="section_header_text">
                        Enter your External Account ID below. If you do not have an External Account
                        ID, contact your sales representative.
                    </div>

                    <div class="">
                        <label for="externalAccountId">External Account ID</label>
                        <input
                            type="text"
                            name="externalAccountId"
                            id="externalAccountId"
                            [(ngModel)]="externalAccountId"
                            required />
                    </div>

                    <button
                        class="itc fluid button large"
                        name="activateButton"
                        [ngClass]="{ loading: loading }"
                        type="submit">
                        Activate
                    </button>
                </div>
            </form>

            <form
                class=""
                name="collectOemIdForm"
                [hidden]="!vsaUserDoesNotExistView"
                (submit)="useStandardCredentials()">
                <div>
                    <div class="section_header">Single-Sign On (SSO) Failure</div>

                    <div class="section_header_text">
                        Your account is not allowed direct access to the Kaseya Compliance Manager.
                    </div>

                    <button
                        class="itc fluid button large"
                        name="activateButton"
                        [ngClass]="{ loading: loading }"
                        type="submit">
                        Attempt to Login using RapidFire Tools Credentials
                    </button>
                </div>
            </form>

            <form
                class=""
                name="vsaActivationFailureForm"
                [hidden]="!vsaActivationFailureView"
                (submit)="retryActivation()">
                <div>
                    <div class="section_header">Unable to Activate Kaseya Compliance Manager</div>
                    <div class="section_header_text">
                        An error occurred while attempting to activate the Kaseya Compliance
                        Manager. Please contact Kaseya support and report the error:
                        {{ activationFailureMessage }}
                    </div>

                    <button
                        class="itc fluid button large"
                        name="activationFailureContinueButton"
                        [ngClass]="{ loading: loading }"
                        type="submit">
                        Continue
                    </button>
                </div>
            </form>

            <form
                class=""
                name="vsaActivationSuccessForm"
                [hidden]="!vsaActivationSuccessView"
                (submit)="continueSuccessfulActivation()">
                <div>
                    <div class="section_header">Kaseya Compliance Manager Activated</div>
                    <div class="section_header_text">
                        Congratulations! Kaseya Compliance Manager has been successfully activated
                        and is ready for use.
                    </div>

                    <button
                        class="itc fluid button large"
                        name="activationSuccessContinueButton"
                        [ngClass]="{ loading: loading }"
                        type="submit">
                        Continue
                    </button>
                </div>
            </form>

            <form
                class=""
                name="collectPasswordForm"
                [hidden]="!collectPasswordView"
                (submit)="submitNewOemPassword()">
                <div>
                    <div class="section_header">Create Password</div>
                    <div>
                        <label for="newOemPassword">New Password</label>
                        <input
                            type="password"
                            name="newOemPassword"
                            id="newOemPassword"
                            [(ngModel)]="newOemPassword"
                            required />
                    </div>
                    <div>
                        <label for="newOemPasswordConfirm">Confirm Password</label>
                        <input
                            type="password"
                            name="newOemPasswordConfirm"
                            id="newOemPasswordConfirm"
                            [(ngModel)]="newOemPasswordConfirm"
                            required />
                    </div>

                    <button
                        class="itc fluid button large"
                        name="submitNewOemPasswordButton"
                        [ngClass]="{ loading: loading }"
                        type="submit">
                        Submit
                    </button>
                </div>
            </form>
        </div>

        <pwd-reset-form *ngIf="showResetPasswordForm"></pwd-reset-form>
    </div>
    <p class="login_copyright">
        <a href="https://helpdesk.kaseya.com/" target="_blank">Help &amp; Support</a>
        | &copy; Kaseya {{ currentYear }}
    </p>

    <!--    <div class='login_footer_copy' style="position: fixed; right: 15px; bottom: 0;">
        <p class='footer_version' style="margin-left: 20px;" *ngIf="version != 'Dev'">v.{{ version }}</p>
    </div> -->
</div>

<sm-modal class="xs" title="Unable to Associate Local Account" #itcUserMapFailureModal>
    <modal-content>
        <itc-banner type="error">{{ itcError }}</itc-banner>
        <!-- <div class="ui warning icon message">
            <i class="warning sign icon"></i>
            <div class="content">
                <div class="header">Error</div>
                <p>{{itcError}}
                </p>
            </div>
        </div> -->
    </modal-content>
    <modal-actions>
        <form (submit)="itcUserMapFailure()">
            <button
                class="ui fluid primary button"
                style="padding: 20px; line-height: 0"
                name="itcUserMapFailureButton"
                [ngClass]="{ loading: loading }"
                type="submit">
                Continue
            </button>
        </form>
    </modal-actions>
</sm-modal>

<sm-modal class="md" title="End-User License Agreement" id="eulaModal" #eulaModal>
    <modal-content>
        <p>
            <strong>
                To continue to use this application, you must agree to the current end-user license
                agreement.
            </strong>
        </p>
        <div class="eulaContainer" [innerHTML]="eula"></div>
    </modal-content>
    <modal-actions>
        <a class="ui primary button approve" (click)="acceptEula(true)">I Accept</a>
        <a class="ui primary button deny" (click)="acceptEula(false)">I Decline</a>
    </modal-actions>
</sm-modal>

<sm-modal class="md" title="Setup MFA" id="setupMfaModal" #setupMfaModal>
    <modal-content>
        <sds-mfa-enable [(isTwoFactorEnabled)]="isTwoFactorSetupComplete"></sds-mfa-enable>
    </modal-content>
    <modal-actions>
        <itc-button
            label="Cancel"
            *ngIf="!isTwoFactorSetupComplete"
            type="secondary"
            class="cancel"
            (onclick)="cancelSetupMfaModal()"></itc-button>
        <itc-button
            label="OK"
            *ngIf="isTwoFactorSetupComplete"
            (onclick)="closeSetupMfaModal()"></itc-button>
    </modal-actions>
</sm-modal>
