import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    HostListener,
    inject,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms';
import { ItcButtonComponent } from '../itc';
import { NotBlankValidator } from '../not-blank.validator';

@Component({
    selector: 'inline-edit',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        ItcButtonComponent,
        NotBlankValidator,
    ],
    template: `
        <ng-container *ngIf="!isEditing; else editContent">
            <div class="textContainer">
                {{ value }}
                <itc-button type="icon" icon="fa-edit" (onclick)="updateText()"></itc-button>
            </div>
        </ng-container>

        <ng-template #editContent>
            <form class="inline-edit" #editContentForm="ngForm" (ngSubmit)="updateText(true)">
                <input
                    type="text"
                    [(ngModel)]="value"
                    name="text"
                    notBlank
                    #editTextInput="ngModel" />
                <itc-button type="icon" icon="fa-times" (onclick)="updateText(false)"></itc-button>
                <itc-button
                    type="icon"
                    icon="fa-check"
                    [disabled]="editTextInput.invalid"
                    (onclick)="updateText(true)"></itc-button>
            </form>
        </ng-template>
    `,
    styles: [
        `
            :host {
                .textContainer {
                    border-left: 1px solid transparent;
                }
                form {
                    input {
                        padding: 0;
                        font-weight: inherit;
                        margin-right: 8px;
                    }
                }
                .inline-edit itc-button::ng-deep {
                    .button.icon {
                        box-shadow: var(--elevation-xs);
                        border-radius: 4px;
                        border: 1px solid var(--button-secondary-border);
                    }
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InlineEditComponent),
            multi: true,
        },
    ],
})
export class InlineEditComponent implements ControlValueAccessor {
    private cdr = inject(ChangeDetectorRef);

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (!this.isEditing) return;
        if (event.key === 'Escape') {
            this.updateText(false);
        }
    }

    @Input() set text(text: string) {
        this.originalText = text;
        this.value = text;
    }
    @Input() required = true;
    @Output() onupdate = new EventEmitter<string>();
    @ViewChild('editContentForm') editContentForm: HTMLFormElement;

    originalText: string;
    _text: string;
    isEditing = false;

    updateText(result?: boolean): void {
        if (this.editContentForm?.invalid) return;

        if (typeof result === 'undefined') {
            this.isEditing = !this.isEditing;
            return;
        }

        if (result) {
            this.onupdate.emit(this.value);
            this.onChange(this.value);
            this.originalText = this.value;
        } else {
            this.value = this.originalText;
        }
        this.isEditing = false;
        this.cdr.markForCheck();
    }

    onChange = (_) => {};
    onTouched = (_) => {};

    disabled = false;
    value = '';

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: string): void {
        if (value) {
            this.value = value;
            this.originalText = value;
        }
    }
}
