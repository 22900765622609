import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable, of } from 'rxjs';
import { ItcFileUploaderService } from 'app/shared/itc';
import { Breadcrumb } from '../../shared/breadcrumbs/breadcrumb.model';
import { GrcAssessmentArchiveModel } from './GrcAssessmentArchiveModel';
import { Router } from '@angular/router';
import { Site } from './site.model';
import { SiteEncodePipe } from 'app/shared/siteEncode.pipe';
import { CISTemplate } from '../site/compliance-manager/policies-procedures/cis-templates/cis-templates.component';

import { GrcTicketData, GrcTicketDuplicateStatus, POAMTicketData } from './grc/grc-data';
import { RFT_URL } from '../../core/constants';

@Injectable({ providedIn: 'root' })
export class GrcService {
    private checkArchivisationInterval: any;
    private apiUrl: string;
    private breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>(null);
    private archiveAssessments$ = new BehaviorSubject<Breadcrumb[]>(null);
    private removedArchivedAssessment$ = new BehaviorSubject<boolean>(false);
    constructor(
        private http: AuthHttp,
        private fileUploader: ItcFileUploaderService,
        private router: Router,
        private siteEncode: SiteEncodePipe
    ) {
        this.apiUrl = environment.apiUrl;
    }

    getRemovedArchivedAssessment(): Observable<boolean> {
        return this.removedArchivedAssessment$.asObservable();
    }

    setRemovedArchivedAssessment(removed: boolean): void {
        setTimeout(() => {
            this.removedArchivedAssessment$.next(removed);
        }, 0);
    }
    getBreadcrumbs(): Observable<Breadcrumb[]> {
        return this.breadcrumbs$.asObservable();
    }

    setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
        setTimeout(() => {
            this.breadcrumbs$.next(breadcrumbs);
        }, 0);
    }

    getAssessments(): Observable<Breadcrumb[]> {
        return this.archiveAssessments$.asObservable();
    }

    setAssessments(breadcrumbs: any): void {
        setTimeout(() => {
            this.archiveAssessments$.next(breadcrumbs);
        }, 0);
    }

    getReportSets(siteId: number) {
        const url: string = this.apiUrl + `GrcReportSets/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getWorksheetSets(siteId: number) {
        const url: string = this.apiUrl + `GrcWorksheetSets/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getModuleSets(siteId: number, moduleName: string) {
        const url: string = this.apiUrl + `GrcModuleSets/${siteId}/${moduleName}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    startGrcPrescan(siteId: number) {
        const url: string = this.apiUrl + `StartGrcPrescan/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    startGrcLanscan(siteId: number) {
        const url: string = this.apiUrl + `StartGrcLanscan/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    startGrcCloudscan(siteId: number) {
        const url: string = this.apiUrl + `StartGrcCloudscan/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    getGrcScanFiles(siteId: number) {
        const url: string = this.apiUrl + `GetGrcScanFiles/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    getGrcScanFilesMetadata(
        siteId: number,
        skipMeta: boolean = false,
        skipScanType: boolean = false
    ) {
        const url: string =
            this.apiUrl +
            `GetGrcScanFilesMetadata/${siteId}?skipMeta=${skipMeta}&skipScanType=${skipScanType}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    getGrcScanTypes(siteId: number, fileNames: string[]) {
        const url: string = this.apiUrl + `GetGrcScanTypes/${siteId}`;
        let req = { fileNames: fileNames };
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res as any);
    }
    deleteGrcScanFile(siteId: number, fileName: string | string[]) {
        let filesName = Array.isArray(fileName) ? fileName : [fileName];
        const url: string = this.apiUrl + `DeleteGrcScanFile/${siteId}`;
        return lastValueFrom(this.http.post(url, filesName)).then((res) => res);
    }
    getGrcPrescanResults(siteId: number) {
        const url: string = this.apiUrl + `GetGrcPrescanResults/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    getGrcLanscanResults(siteId: number) {
        const url: string = this.apiUrl + `GetGrcLanscanResults/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    getGrcCloudscanResults(siteId: number) {
        const url: string = this.apiUrl + `GetGrcCloudscanResults/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    getGrcScanStatus(siteId: number) {
        const url: string = this.apiUrl + `GetGrcScanStatus/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    cancelGrcLanScans(siteId: number) {
        const url: string = this.apiUrl + `CancelGrcLanScans/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    cancelGrcCloudScans(siteId: number) {
        const url: string = this.apiUrl + `CancelGrcCloudScans/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    getGrcScanRegistry(siteId: number) {
        const url: string = this.apiUrl + `GetGrcScanRegistry/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    uploadGrcCdf(formdata: FormData): Observable<any> {
        return this.fileUploader.uploadFile('Sites/UploadGrcCdf', formdata);
    }

    setGrcAzureEnterpriseSettings(
        siteId: number,
        clientId: string,
        tenantId: string,
        secret: string,
        gcchigh: boolean
    ): Promise<any> {
        const url: string = this.apiUrl + `SetGrcAzureEnterpriseSettings/${siteId}`;
        let postbody = { clientid: clientId, tenantid: tenantId, secret: secret, gcchigh: gcchigh };
        return this.http
            .post(url, postbody)
            .toPromise()
            .then((res) => res);
    }

    getGrcAzureEnterpriseSettings(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetGrcAzureEnterpriseSettings/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    hasCcw(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `HasCcw/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    generateWorksheets(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GenerateWorksheets/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getStandardWorksheetsForSite(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetStandardWorksheetsForSite/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getWorksheetGenerationStatus(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetWorksheetGenerationStatus/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
    initWorksheetGenerationStatus(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `InitWorksheetGenerationStatus/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getExternalVulnerabilityScans(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetExternalVulnerabilityScans/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
    importExternalVulnerabilityScans(
        siteId: number,
        importList: any[],
        overwrite: boolean
    ): Promise<any> {
        const url: string = this.apiUrl + `ImportExternalVulnerabilityScans/${siteId}`;
        let req = { imports: importList, overwrite: overwrite };
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }

    getInternalVulnerabilityScans(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetInternalVulnerabilityScans/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
    importInternalVulnerabilityScans(
        siteId: number,
        importList: any[],
        overwrite: boolean
    ): Promise<any> {
        const url: string = this.apiUrl + `ImportInternalVulnerabilityScans/${siteId}`;
        let req = { imports: importList, overwrite: overwrite };
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }

    getModuleState(
        siteId: number,
        moduleName: string,
        assessmentName: string = '__current'
    ): Promise<any> {
        const url: string =
            this.apiUrl + `GetModuleState/${siteId}/${moduleName}?assessmentName=${assessmentName}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    setModuleState(siteId: number, moduleName: string, moduleState: any): Promise<any> {
        const url: string = this.apiUrl + `SetModuleState/${siteId}`;
        let req = { modulename: moduleName, modulestate: moduleState };
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }
    deleteModuleState(
        siteId: number,
        moduleName: string,
        assessmentName: string = '__current'
    ): Promise<any> {
        const url: string =
            this.apiUrl +
            `DeleteModuleState/${siteId}/${moduleName}?assessmentName=${assessmentName}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    getModuleLock(
        siteId: number,
        moduleName: string,
        assessmentName: string = '__current'
    ): Promise<any> {
        const url: string = this.apiUrl + `GetModuleLock/${siteId}/${moduleName}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    setModuleLock(siteId: number, moduleName: string, guid: string = '') {
        const url: string = this.apiUrl + `SetModuleLock/${siteId}/${moduleName}?guid=${guid}`;
        let req = {};
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }
    updateModuleLock(siteId: number, moduleName: string, guid: string = '') {
        const url: string = this.apiUrl + `UpdateModuleLock/${siteId}/${moduleName}?guid=${guid}`;
        let req = {};
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }
    insertModuleLock(siteId: number, moduleName: string, guid: string = '') {
        const url: string = this.apiUrl + `InsertModuleLock/${siteId}/${moduleName}?guid=${guid}`;
        let req = {};
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }

    deleteModuleLock(siteId: number, moduleName: string, guid: string = '') {
        const url: string = this.apiUrl + `deleteModuleLock/${siteId}/${moduleName}?guid=${guid}`;

        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    getLockedModules(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetLockedModules/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    archiveReportsWorksheetsAndFiles(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `ArchiveGrcReportsWorksheetsAndFiles/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    exportReportsToItGlue(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `ExportGrcReportsToItGlue/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getReportExportStatus(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetReportExportStatus/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    generateReports(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GenerateGrcReports/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getIsReportGenerationComplete(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetGrcIsReportGenerationComplete/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    generatePoam(siteId: number, iso: string): Promise<any> {
        const url: string = this.apiUrl + `Poam/${siteId}/${iso}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getIsTechnicalReviewComplete(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetIsTechnicalReviewComplete/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
    getMappedTechnicalIssues(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetMappedTechnicalIssues/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
    importDiscoveryAgentScans(siteId: number) {
        const url: string = this.apiUrl + `ImportDiscoveryAgentScans/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    mergeControlEvidence(siteId: number, guid: string = '') {
        const url: string = this.apiUrl + `MergeControlEvidence/${siteId}?guid=${guid}`;
        let req = {};
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }
    getStandardControlChanges(
        siteId: number,
        pageNumber: number = 0,
        pageSize: number = 100
    ): Promise<any> {
        const url: string =
            this.apiUrl +
            `GetStandardControlChanges/${siteId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
    exportStandardControlChanges(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `ExportStandardControlChanges/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
    getAllStandardControlChanges(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetAllStandardControlChanges/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
    insertStandardControlChange(siteId: number, message: string, detail: string): Promise<any> {
        const url: string = this.apiUrl + `PostStandardControlChange/${siteId}`;
        let req = { message: message, detail: detail };
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }

    attachEvidence(siteId: number, guid: string = '') {
        const url: string = this.apiUrl + `AttachEvidence/${siteId}?guid=${guid}`;
        let req = {};
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }
    archiveAssessment(siteId: number, assessmentName: string): Promise<any> {
        const url: string =
            this.apiUrl + `ArchiveAssessment/${siteId}?assessmentName=${assessmentName}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }
    getIsArchivisationComplete(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetGrcIsArchivisationComplete/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }
    getArchivedAssessments(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `GetArchivedAssessments/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getArchivedWorkSheets(siteId: number, guid: string): Promise<any> {
        const url: string = this.apiUrl + `GetArchivedWorkSheets/${siteId}/${guid}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getArchivedPoam(siteId: number, assessmentGuid: string): Promise<any> {
        const url: string = this.apiUrl + `GetArchivedPoam/${siteId}/${assessmentGuid}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    addArchivisationInterval(site: Site, url: string) {
        this.checkArchivisationInterval = setInterval(() => {
            if (site) {
                this.getIsArchivisationComplete(site.Id).then((response) => {
                    if (!response) {
                        const linkToredirect = this.siteEncode.transform(btoa(url));
                        this.router.navigate([
                            'site',
                            site.Name,
                            'compliance-manager-grc',
                            'archive-in-progress',
                            linkToredirect,
                        ]);
                    }
                });
            }
        }, 30000);
    }

    removeArchivisationInterval() {
        if (this.checkArchivisationInterval) {
            clearInterval(this.checkArchivisationInterval);
        }
    }
    deleteArchivedAssessment(assessment: GrcAssessmentArchiveModel): Promise<any> {
        const url: string = this.apiUrl + `DeleteArchivedAssessment`;
        return lastValueFrom(this.http.post(url, assessment)).then((res) => res);
    }

    isPoamTicketDuplicate(
        siteId: number,
        ticketsTitles: string[]
    ): Observable<GrcTicketDuplicateStatus[]> {
        const url: string = this.apiUrl + `Connections/IsPoamTicketDuplicate/${siteId}`;
        return this.http.post(url, ticketsTitles);
    }

    createTickets(siteId: number, ticketsData: POAMTicketData): Observable<GrcTicketData[]> {
        const url: string = this.apiUrl + `Connections/CreatePoamTickets/${siteId}`;
        return this.http.post(url, ticketsData);
    }

    async getCISTemplates(): Promise<CISTemplate[]> {
        // not sure why this gives me 405 even though CORS is allowed
        // const url = RFT_URL + '/data/cistemplates.json';
        // return this.http.get(url);
        const response = await fetch(RFT_URL + '/data/cistemplates.json');
        const data = await response.json();
        return data.templateLinks;
    }

    getStandardIcon(standard: string): string {
        let iconSource = '/assets/img/compliance-monitor/';
        let standardsIcon = {
            'CIS': 'cis-icon.svg',
            'CMMC': 'cmmc-icon.svg',
            'Essential 8': 'essential8-icon.svg',
            'HIPAA': 'hipaa-icon.svg',
            'NIST': 'nist-icon.svg',
            'NYS DFS': 'nysdfs-icon.svg',
            'PCI': 'pci-icon.svg',
            'SOC': 'soc-icon.svg',
        };

        for (const [key, value] of Object.entries(standardsIcon)) {
            if (standard.indexOf(key) === 0) {
                return iconSource + value;
            }
        }
        return iconSource + 'no-icon.svg';
    }
}
