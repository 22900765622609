import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, Roles } from 'app/core/auth/auth.service';
import { OrganizationsService } from './organizations.service';
import { Organization } from './organization.model';
import { NavService } from '../core/nav/nav.service';
import { SiteService } from '../sites/shared/site.service';
import { Site } from '../sites/shared/site.model';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { FilesService } from 'app/sites/site/assessments/files/files.service';
import { Connection, ConnectionService } from 'app/connections';
import { ITGlueOrganization } from './itglueorganization.model';
import { SemanticSelectComponent } from 'app/semantic-legacy/semantic-legacy.module';
import {
    debounceTime,
    distinctUntilChanged,
    from,
    map,
    Observable,
    Subject,
    take,
    takeUntil,
} from 'rxjs';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { NotificationService } from '../shared/itc/notification/notification.service';
import { NotesService } from '../sites/shared/notes';

@Component({
    templateUrl: './organizations.component.html',
    styleUrls: ['./organizations.component.scss'],
})
export class OrganizationsComponent implements OnInit {
    @ViewChild('itgorgsselect') itgorgsselect: SemanticSelectComponent;

    products: any;
    itgOrganizations: any[];
    selectionError: boolean;
    existingSelection = [];
    iTGConnection: Connection;
    loadingITGComplete: boolean = false;

    constructor(
        private notificationService: NotificationService,
        private authService: AuthService,
        private organizationsService: OrganizationsService,
        private navService: NavService,
        private siteService: SiteService,
        private filesService: FilesService,
        private connectionsService: ConnectionService,
        private notesService: NotesService
    ) { }

    @ViewChild('addOrganizationModal', { static: true }) addOrganizationModal: any;
    @ViewChild('addFromITGlueModal', { static: true }) addFromITGlueModal: any;
    @ViewChild('privacymodal', { static: true }) privacyModal: any;
    @ViewChild('privacycompletemodal', { static: true }) privacyCompleteModal: any;
    @ViewChild('privacyerrormodal', { static: true }) privacyErrorModal: any;

    itGlueOrgSelected;
    viewMode: string = 'grid';
    viewModeUpdating: boolean = false;
    canAdd: boolean;
    // sortedOrganizationsChunked: any = {};
    newOrganizationName: string;
    isNewOrganizationNameValid: boolean;
    fakeOrg = { Id: -1, AccountId: null, Name: 'Unassigned', Sites: [], FilteredSites: [] };
    sites: Site[];
    orgsFiltered = false;
    isCatchAllRedirect = false;
    filterCtrl = new UntypedFormControl('all');
    searchKey = new UntypedFormControl('');
    itGlueOrgControl: UntypedFormControl = new UntypedFormControl();
    itGlueOrgs = [];
    hasIndoc = false;
    password1: string;
    privacyError: string = '';

    organizations$: Observable<Organization[]>;
    filteredOrganizations$: Observable<Organization[]>;
    orgListError$: Observable<Error>;

    ngUnsubscribe: Subject<any> = new Subject();

    ngOnInit() {
        // this.organizationsService.getLatest();
        this.organizations$ = this.organizationsService.getOrganizationsObs();
        this.filteredOrganizations$ = from(this.organizations$);
        this.orgListError$ = this.organizationsService.orgListError$;
        this.organizationsService.setSelectedOrganization('all-orgs');
        this.navService.setHeaderText('');

        // handle filter text input
        this.searchKey.valueChanges
            .pipe(debounceTime(400), takeUntil(this.ngUnsubscribe))
            .subscribe((searchKey) => {
                this.filterOrgsBySites(searchKey, 'name');
            });

        // handle filter dropdown
        this.filterCtrl.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
            .subscribe((searchKey) => {
                this.filterOrgsBySites(searchKey, 'type');
            });

        this.loadingITGComplete = false;
        this.navService.setHeaderText(''); // clear any header if there is one

        this.checkITGlueConnection();
        this.loadSites();
        this.checkCircleOfTrust();

        this.canAdd =
            this.authService.userIsRole(Roles.Admin) || this.authService.userIsRole(Roles.Master);
        this.products = this.authService.getProducts();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
    }

    checkCircleOfTrust() {
        let _sFromUrl: string = this.navService.loadReturnState();
        console.log('fromUrl : ' + _sFromUrl);
        if (_sFromUrl && _sFromUrl == 'login') {
            this.navService.saveReturnState('organizations');
            this.ensureCheckCircleOfTrustConfigured();
        }
    }

    ensureCheckCircleOfTrustConfigured() {
        let isAllowedToEstablishCircleOfTrush: boolean = this.authService.userIsRole(Roles.Master);

        if (isAllowedToEstablishCircleOfTrush) {
            this.notesService.getPrivacyStatus().then((res) => {
                let _sHasPublicKey = res.headers.get('X-HasPublicKey');
                let _sHasPrivacyKey = res.headers.get('X-HasPrivacyKey');
                let _sHasPrivacyHash = res.headers.get('X-HasPrivacyHash');
                let _sIsMaster = res.headers.get('X-IsMaster');

                let hasPublicKey: boolean = _sHasPublicKey === 'True';
                let hasPrivacyKey: boolean = _sHasPrivacyKey === 'True';
                let hasPrivacyHash: boolean = _sHasPrivacyHash === 'True';

                if (isAllowedToEstablishCircleOfTrush && !hasPublicKey) {
                    this.privacyModal.show();
                }
            });
        }
    }

    closePrivacyModal(isOk: boolean) {
        if (isOk) {
            // if no public key, create public key, save it
            // save privacy key and privacy hash

            let formData: FormData = new FormData();
            formData.append('pwd', this.password1);

            this.notesService
                .postPrivacyPassword(formData)
                .then((s) => {
                    this.privacyModal.hide();
                    this.privacyCompleteModal.show();
                })
                .catch((e) => {
                    if (e.status == 417) {
                        this.privacyError = e.error;
                        this.privacyErrorModal.show();
                    }
                });
        } else {
            this.privacyModal.hide();
        }
    }

    checkITGlueConnection() {
        this.connectionsService.getConnections().then((conns: Connection[]) => {
            conns.forEach((conn) => {
                if (conn.Type == 'ITG') this.iTGConnection = conn;
            });
        });
    }

    filterOrgsBySites(searchKey, searchType) {
        if (
            (searchKey === '' && searchType === 'name') ||
            (searchKey === 'all' && searchType === 'type')
        ) {
            this.filteredOrganizations$ = from(this.organizations$);
            return;
        }

        if (searchKey) {
            let filteredSitesArray;
            if (searchType === 'name') {
                // get sites that match searchKey
                this.filterCtrl.setValue('all', { emitEvent: true });
                filteredSitesArray = this.sites?.filter(
                    (site) =>
                        site.Name.toLowerCase().indexOf(this.searchKey.value.toLowerCase()) > -1
                );
            } else {
                this.searchKey.setValue('', { emitEvent: false });
                filteredSitesArray = this.sites?.filter((site) => {
                    return this.checkFilter(site);
                });
            }

            let filteredSiteGroups = { fakeOrg: [] };
            filteredSitesArray.forEach((s) => {
                if (s.Organization !== '') {
                    if (!filteredSiteGroups[s.Organization]) {
                        filteredSiteGroups[s.Organization] = [];
                    }
                    filteredSiteGroups[s.Organization].push(s);
                } else {
                    filteredSiteGroups.fakeOrg.push(s);
                }
            });

            this.filteredOrganizations$ = this.organizations$?.pipe(
                map((allOrgs) => {
                    let filteredOrgs = _cloneDeep(allOrgs) || [];
                    filteredOrgs.forEach((o) => {
                        o.FilteredSites = [];
                    });
                    filteredOrgs.forEach((o) => {
                        o.FilteredSites = filteredSiteGroups[o.Name];
                    });
                    if (filteredSiteGroups.fakeOrg.length) {
                        this.fakeOrg.FilteredSites = filteredSiteGroups.fakeOrg;
                        filteredOrgs.push(this.fakeOrg);
                    }
                    return filteredOrgs.filter((o) => o.FilteredSites);
                })
            );
        }
    }

    checkFilter(site: Site): boolean {
        return (
            this.filterCtrl.value == 'all' ||
            (this.filterCtrl.value == 'cyberhawk' && this.siteService.isCyberHawk(site)) ||
            (this.filterCtrl.value == 'auditguru' &&
                (this.siteService.isComplianceManager(site) ||
                    this.siteService.isComplianceManagerGRC(site))) ||
            (this.filterCtrl.value == 'cspro' && site.IsCSPro) ||
            (this.filterCtrl.value == 'indoc' &&
                this.siteService.isIndoc(site) &&
                !this.siteService.isNDPro(site)) ||
            (this.filterCtrl.value == 'ndpro' && this.siteService.isNDPro(site)) ||
            (this.filterCtrl.value == 'kvs' && this.siteService.isKVS(site))
        );
    }

    loadSites() {
        this.siteService.getSites().then((sites) => {
            this.sites = sites;
            if (sites.some((s) => s.IsIndoc)) this.hasIndoc = true;
        });
    }

    showAddOrganizationModal(addOrgForm: NgForm) {
        if (this.iTGConnection) {
            this.getITGlueOrganizations();
        }
        this.newOrganizationName = '';
        addOrgForm.resetForm();
        this.addOrganizationModal.show({ closable: false });
    }

    addOrganization() {
        if (this.newOrganizationName && this.newOrganizationName.length > 0) {
            let newOrganization: Organization = new Organization();
            newOrganization.Name = this.newOrganizationName.trim();
            this.organizationsService
                .addOrganizationObs(newOrganization)
                .then((res) => {
                    this.notificationService.toast.success('Success', 'Organization Added');
                    this.addOrganizationModal.hide();
                })
                .catch((err) => {
                    this.notificationService.toast.error('Unable to add organization', err.error);
                });
        }
    }

    organizationDeleted(organizationName: string) {
        console.log('organization deleted in component');
    }

    getITGlueOrganizations() {
        this.filesService
            .getITGlueOrganizations(null, '', '', null, null, this.iTGConnection.Id)
            .then((res) => {
                this.itGlueOrgs = this.filterByConfig(res).sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
                this.loadingITGComplete = true;
            });
    }

    addITGlue() {
        this.addFromITGlueModal.show({ closable: false });
    }

    closeITGlueModal() {
        //Clear ITGOrgs Dropdown on hide.
        this.itgorgsselect?.reset(true);

        // this.addFromITGlueModal.hide();
        this.addOrganizationModal.show({ closable: false });
    }

    addITGlueOrganization() {
        if (this.existingSelection.length > 0) {
            let neworgs: Array<Organization> = [];
            this.existingSelection.forEach((org) => {
                let newOrganization: Organization = new Organization();
                newOrganization.Name = org;
                neworgs.push(newOrganization);
            });
            this.organizationsService
                .addOrganizationObs(neworgs)
                .then((res) => {
                    this.notificationService.toast.success('Success', 'Organizations Added');
                    this.addOrganizationModal.hide();
                    //Clear ITGOrgs Dropdown on hide.
                    this.itgorgsselect?.reset(true);

                    // this.loadOrganizations();
                })
                .catch((err) => {
                    console.log('err', err);
                    this.notificationService.toast.error('Unable to add organizations', err.error);
                });
        }

        this.addFromITGlueModal.hide();
    }

    multiSelect(ev: any) {
        this.selectionError = false;
        this.existingSelection = ev;
    }

    filterByConfig(res: ITGlueOrganization[]): Array<ITGlueOrganization> {
        let config = this.filesService.deserializeITGlueConfig(this.iTGConnection.TicketMetadata);

        let selectedTypes = config.Types.filter((type) => type.Value == true);
        let selectedStatuses = config.Statuses.filter((status) => status.Value == true);

        //first filter by types
        let typeFiltered = [];
        typeFiltered = res.filter((el) => {
            return selectedTypes.some((f) => {
                return Number(f.Id) == el['organization-type-id'];
            });
        });
        //first filter by status
        let statusFiltered = [];
        statusFiltered = typeFiltered.filter((el) => {
            return selectedStatuses.some((f) => {
                return Number(f.Id) == el['organization-status-id'];
            });
        });

        //now filter out all already selected items
        let pairedFiltered = [];
        this.organizations$?.pipe(take(1)).subscribe((orgs) => {
            if (orgs) {
                pairedFiltered = statusFiltered.filter((ad) =>
                    orgs.every((fd) => fd.ItGlueOrganizationId != Number(ad.id))
                );
                return pairedFiltered;
            }
        });
        return pairedFiltered;
    }
}
