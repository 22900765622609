import { Injectable, ViewContainerRef, ComponentRef, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { ItcNotificationToastComponent } from './notification-toast.component';
import { Notification, NotificationType, NotificationMethod } from './notification.model';
import { ANIMATE_DURATION } from './toast.config';

declare var jQuery: any;

@Injectable({ providedIn: 'root' })
export class NotificationService {
    private subject = new Subject<Notification>();
    private toasts: ComponentRef<ItcNotificationToastComponent>[] = [];
    private defaultId = 'default-notification';

    constructor() {}

    // enable subscribing to notifications observable
    onNotification(id = this.defaultId): Observable<Notification> {
        return this.subject.asObservable().pipe(filter((x) => x && x.id === id));
    }

    options = {
        autoClose: true,
        keepAfterRouteChange: true, // not used right now
    };

    toast = {
        success: (title: string, message: string, options?: any) => {
            options = { ...this.options, ...options };
            this.notification(
                new Notification({
                    ...options,
                    type: NotificationType.Success,
                    method: NotificationMethod.Toast,
                    message,
                    title,
                })
            );
        },
        error: (title: string, message: string, options?: any) => {
            options = { ...this.options, ...options };
            this.notification(
                new Notification({
                    ...options,
                    type: NotificationType.Error,
                    method: NotificationMethod.Toast,
                    message,
                    title,
                })
            );
        },
        info: (title: string, message: string, options?: any) => {
            options = { ...this.options, ...options };
            console.log('options?', options);
            this.notification(
                new Notification({
                    ...options,
                    type: NotificationType.Info,
                    method: NotificationMethod.Toast,
                    message,
                    title,
                })
            );
        },
        warning: (title: string, message: string, options?: any) => {
            options = { ...this.options, ...options };
            this.notification(
                new Notification({
                    ...options,
                    type: NotificationType.Warning,
                    method: NotificationMethod.Toast,
                    message,
                    title,
                })
            );
        },
        download: (title: string, message: string, options?: any) => {
            options = { ...this.options, ...options };
            this.notification(
                new Notification({
                    ...options,
                    type: NotificationType.Download,
                    method: NotificationMethod.Toast,
                    message,
                    title,
                })
            );
        },
        clear: (id = this.defaultId) => {
            this.subject.next(new Notification({ method: NotificationMethod.Toast, id }));
        },
    };

    banner = {
        success: (title: string, message: string, id?: string, options?: any) => {
            options = { ...this.options, ...options };
            this.notification(
                new Notification({
                    ...options,
                    type: NotificationType.Success,
                    method: NotificationMethod.Banner,
                    message,
                    id,
                    title,
                })
            );
        },
        error: (title: string, message: string, id?: string, options?: any) => {
            options = { ...this.options, ...options };
            this.notification(
                new Notification({
                    ...options,
                    type: NotificationType.Error,
                    method: NotificationMethod.Banner,
                    message,
                    id,
                    title,
                })
            );
        },
        info: (title: string, message: string, id?: string, options?: any) => {
            options = { ...this.options, ...options };
            this.notification(
                new Notification({
                    ...options,
                    type: NotificationType.Info,
                    method: NotificationMethod.Banner,
                    message,
                    id,
                    title,
                })
            );
        },
        warning: (title: string, message: string, id?: string, options?: any) => {
            options = { ...this.options, ...options };
            this.notification(
                new Notification({
                    ...options,
                    type: NotificationType.Warning,
                    method: NotificationMethod.Banner,
                    message,
                    id,
                    title,
                })
            );
        },
        clear: (id = this.defaultId) => {
            this.subject.next(new Notification({ method: NotificationMethod.Banner, id }));
        },
    };

    // main notification method
    notification(notification: Notification) {
        notification.id = notification.id || this.defaultId;
        this.subject.next(notification);
    }

    // clear notification
    clear(id = this.defaultId) {
        this.subject.next(new Notification({ id }));
    }

    getObservable(): Observable<Notification> {
        return this.subject.asObservable();
    }

    makeToast(container: ViewContainerRef, notification: Notification) {
        let paused = false;

        notification.timer = 0;
        // provide the injected parameter
        let providers = [{ provide: Notification, useValue: notification }];

        // get the injector
        let injector = Injector.create({ providers: providers, parent: container.injector });

        // create the component
        let component = container.createComponent(ItcNotificationToastComponent, {
            injector: injector,
        });

        this.toasts.push(component);
        let _t = this.toasts;

        // remove toast when timer is complete
        component.instance.toastState.pipe(take(1)).subscribe(() => {
            component.instance.hide();
            window.setTimeout(function () {
                component.destroy();
                _t.splice(_t.indexOf(component), 1); // remove from this.toasts
            }, ANIMATE_DURATION);
        });
    }
}
