<div
    class="kaseya-ui-banner banner--{{ type }} banner--alert {{ className }}"
    title=""
    [@bannerState]="state">
    <div class="banner__inner-container">
        <fa-icon icon="circle-check" *ngIf="type == 'success'" class="banner__icon"></fa-icon>
        <fa-icon icon="exclamation-triangle" *ngIf="type == 'error'" class="banner__icon"></fa-icon>
        <fa-icon icon="info-square" *ngIf="type == 'info'" class="banner__icon"></fa-icon>
        <fa-icon icon="exclamation-circle" *ngIf="type == 'warning'" class="banner__icon"></fa-icon>
        <div class="banner__text">
            <span class="banner__title" *ngIf="title">
                <strong>{{ title }}</strong>
            </span>
            <span class="banner__children">
                <ng-content></ng-content>
            </span>
        </div>
        <span class="banner__close-button" title="Close" (click)="closeBanner()" *ngIf="closable">
            <fa-icon icon="times" [fixedWidth]="true" class="banner__icon"></fa-icon>
        </span>
    </div>
</div>
